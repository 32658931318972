import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoRefresh, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FecthPedido, FetchAce, FetchMochi, FetchMost } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class InfoTroca extends Component {



    render(){

        return (
          <div className="App">
      
            <Header/>

      
            <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white", marginBottom:40}}>
              <p className='IPTitle'>Troca e Devolução</p>
              <p className='IPText'>Nossa política de trocas dos produtos e reembolso visa proporcionar ao cliente total segurança em relação aos produtos adquiridos em nossa loja. Todos nossos produtos possuem garantia contra defeitos de fabricação. Também realizamos trocas de produtos que vieram diferente daquele encomendado pelo cliente.
              </p>
              <p className='IPText'>Caso você receba um produto nosso com algum defeito de fabricação, diferente do que você encomendou ou desistência siga os seguintes passos para realizar a troca:</p>
              <p className='IPText'>1. Informe nossa equipe sobre o problema através de nosso e-mail (contato@quickshopbr.com.br), enviando fotos claras do produto com defeito ou do produto errado que você recebeu, no prazo de 7 dias após o recebimento.
              Atenção, não realizaremos trocas ou ressarcimentos após este prazo.

              </p>
              <p className='IPText'>2. Sua reclamação será analisada em no máximo e constatado o problema, entraremos em contato via e-mail e você poderá escolher uma das seguintes alternativas:</p>
              <p className='IPText'>• Receber o reembolso total do valor pago.</p>
              <p className='IPText'>• Receber um novo produto igual o encomendado.</p>
              <p className='IPText'>• Receber um produto de valor semelhante.</p>
              <p className='IPText'>• Receber um cupom com o valor do produto para utilizar em compras futuras.</p>
              <p className='IPText'>Todos os itens dos termos de reembolso e devolução abaixo devem ser considerados.</p>
              <p className='IPText' style={{fontWeight:"bold"}}>Reembolsos e devoluções:</p>
              <p className='IPText'> 7.1. A Empresa fornece detalhadamente todas as especificações (informações) sobre os tamanhos, cores e demais características dos produtos.

<br/><br/>7.2. O visitante somente poderá pedir reembolso ou desistir da compra (direito de arrependimento) até o prazo de 7 (sete) dias a partir da data do recebimento do produto (que será conferido por meio da data de envio aos correios), ultrapassado este período a EMPRESA não fará qualquer espécie de reembolso.

<br/><br/>7.3. O pedido deverá ser realizado por meio de qualquer canal da central de atendimento da Empresa, seja e-mail telefone ou outro mecanismo de comunicação válido com exceção das redes sociais da Empresa.

<br/><br/>7.4. O produto deve estar no estado de conservação original em que foi enviado e fechado. A Empresa devolverá o valor correspondente ao preço de aquisição.

<br/><br/>7.5. Na hipótese de devolução de algum produto, os custos do transporte serão suportados pela Empresa.

<br/><br/>7.6. Em caso de reembolso, o visitante deverá enviar o produto por meio do material adequado indicado pela Empresa durante a comunicação, a exemplo mas não se limitando a plástico bolha ou demais materiais.

<br/><br/>7.7. A autenticidade dos produtos comercializados é controlada pela Empresa por meio do nosso controle de qualidade, o qual será verificado na ocasião da devolução a fim de garantir que o produto retornado é o mesmo e com as mesmas condições enviadas pela Empresa.

<br/><br/>7.8 A Empresa informa que dispõe de 15 (quinze) dias úteis para cadastrar a devolução dos produtos, por meio do formulário a ser preenchido pelo visitante e processar o devido reembolso.

<br/><br/>7.9. O reembolso somente será realizado após o produto retornar a sede da Empresa e ser submetido a nova avaliação.

<br/><br/>7.10. Caso seja constatado que o produto retornado não condiz com as especificações técnicas e estado de conservação que saiu da EMPRESA, fica esta última desobrigada de qualquer tipo/espécie de reembolso.

<br/><br/>7.11 Caso aconteça o previsto no item 7.10 supramencionado o produto ficará disponibilizado na sede da EMPRESA para que o visitante o retire, desde que todos os custos referentes ao transporte sejam arcados por ele.

</p>
<p className='IPText' style={{fontWeight:"bold"}}>Troca:</p>
<p className='IPText'>8.1. A Empresa fornece detalhadamente todas as especificações (informações) sobre os tamanhos, cores e demais características dos produtos.

<br/><br/>8.2. Para que seja realizada a troca é necessário que o visitante envie à Empresa imagem e, ou, vídeo mostrando o defeito do produto.

<br/><br/>8.3. Caso o visitante venha a produzir vídeo e, ou, imagem adulterado e seja constatado quando do recebimento do produto na sede da Empresa que o mesmo se apresenta em perfeitas condições de estado e uso, a Empresa não realizará o retorno do mesmo ao visitante, sendo de responsabilidade deste o custo com o envio do produto.

<br/><br/>8.4. Caso o visitante queria realizar a troca do produto e nem realizar o reembolso poderá reverte o valor do crédito em vale-compra, conforme elencado no item 5.

<br/><br/>8.5. Caso o visitante não queira trocar o produto por outro modelo, poderá reverter o pagamento em vale-compra, no qual poderá em outra oportunidade efetuar compra.

<br/><br/>8.6. O direito de troca somente poderá ser exercido dentro do prazo de 7 (sete) dias após o recebimento do produto na residência do visitante.

<br/><br/>8.7. Em caso do visitante solicitar a contestação da compra junto ao cartão de crédito, deverá, obrigatoriamente comunicar a Empresa o motivo de tal ato, sob pena de responder extra e judicialmente por perdas e danos e lucros cessantes além de honorários advocatícios.

<br/><br/>8.8. Caso o visitante não queira nenhuma das hipóteses de troca acima transcritas, poderá receber o estorno desde que envie o produto à Empresa e, após, o produto ser submetido a avaliação nos moldes do item 7 e subitens do presente termo.

<br/><br/>8.9. Ressalta-se que o período de troca corresponde aquele descrito no item 7.2 do presente instrumento.

<br/><br/>8.10. O prazo para envio do produto trocado é aquele estabelecido no item 4 do presente termo de uso a contar da avaliação feita quando do retorno do produto a sede da Empresa.

</p>
<p className='IPText'>    E assim, por estarem justas e acertadas, as partes (Visitante e Empresa) reconhecem a validade do presente termos de uso, nada podendo reclamar seja extra ou judicialmente a qualquer título. Reconhecendo como foro a comarca de São Paulo, Estado de São Paulo para ser dirimido qualquer conflito judicial.</p>

            </div>
      
            <Foot/>
          </div>
        );
    }
    }


export default InfoTroca;