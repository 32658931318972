import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import { IoAddCircleOutline, IoBarcode, IoCopyOutline, IoDownload, IoRemoveCircleOutline } from 'react-icons/io5';
import {  Link, Navigate } from 'react-router-dom';
import CHECKOUT from '../assets/img/CHECKOUT.png'
import pix from '../assets/img/icone-pix.svg'
import boleto from '../assets/img/bar-code-svgrepo-com.svg'
import card from '../assets/img/credit-card-svgrepo-com.svg'
import InputMask from 'react-input-mask/lib/react-input-mask.development';
import { Pay, SaveBoleto, VerifyPayment } from '../SQL';
import QRCode from 'react-qr-code';
import fileDownload from 'js-file-download';
import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}




class Checkout extends Component {

  state = {
    cart:JSON.parse(window.localStorage.getItem("checkoutCart")) != '' ? JSON.parse(window.localStorage.getItem("checkoutCart"))[0] : '',
    frete:JSON.parse(window.localStorage.getItem("checkoutCart"))[1],
    freteETA:JSON.parse(window.localStorage.getItem("checkoutCart"))[2],
    FP:'boleto',
    nome:'Henry Matheus Nascimento Froio',
    email:'henry_mnf@hotmail.com',
    cpf:'138.799.454-97',
    celular:'(62)99179-3303',
    cep:'71919-180',
    bairro:'Norte (Águas Claras)',
    cidade:'Brasília',
    estado:'DF',
    endereco:'Rua 36',
    numero:'7',
    complemento:'Em frente a drogasil',
    destinatario:'Henry',
    numeroCartao:'4000000000000010',
    validade:'08/28',
    codigoSeguranca:'333',
    nomeTitular:'Henry Froio',
    parcelas:'À vista',
    disabled:false,
    payRes:'',
    redirect:false,
    subtotal:0,
    qrMode:false,
    qrCode:'',
    pixOID:'',
    copyIconColor:'gray',
    pixStatus:'',
    boletoMode:false,
    boletoCode:'',
    dlIconColor:'gray',
    pBlock:false
  }

  componentDidMount = () => {
    if(JSON.parse(window.localStorage.getItem("checkoutCart")) === ''){
      this.setState({redirect:true})
    }

    let precos = this.state.cart.precos

    let subtotal = 0

    let qtds = this.state.cart.qtds

    if(JSON.parse(window.localStorage.getItem("checkoutCart")) != '') {
      precos.forEach((i,idx) => {
        let n = parseFloat(i.replace('R$','').replace(',','.'))
        let qtd = qtds
        subtotal = subtotal + (n * qtd[idx])

        subtotal = subtotal + this.state.frete
      });
    }

    this.setState({subtotal:subtotal})


  }

  
  render(){


    let complete1 = this.state.nome != '' && this.state.email.includes('@') && this.state.email.includes('.') &&  /\d/.test(this.state.cpf[13]) === true && /\d/.test(this.state.celular[13]) === true ? true : false
    let complete2 = /\d/.test(this.state.cep[8]) === true && this.state.bairro != '' && this.state.endereco != '' && this.state.numero != '' && this.state.complemento != '' && this.state.destinatario != '' ? true : false
    let complete3 = false;

    if(this.state.FP === 'pix' || this.state.FP === 'boleto') complete3 = true
    if(this.state.FP === 'cdc' && this.state.numeroCartao.length > 15 && /\d/.test(this.state.validade[4])  === true && this.state.codigoSeguranca.length >= 3 && this.state.nomeTitular != '' ) complete3 = true
  
    let cart = this.state.cart

      let prods = this.state.cart.prods

      let qtds = this.state.cart.qtds

      let precos = this.state.cart.precos

      let prodList;

      if(this.state.payRes === '' && JSON.parse(window.localStorage.getItem("checkoutCart")) != '' ){

        prodList = prods.map((i,idx)=>{

          let preco = parseFloat(precos[idx].replace('R$','').replace(',','.')) * qtds[idx]

          return(
            <div key={idx} style={{display:"flex"}}>
              <p style={{textAlign:'left  ', marginRight:5, color:'gray'}}>{qtds[idx]}x</p>
              <p style={{textAlign:'left  ', marginRight:5, color:'gray', fontWeight:'bold'}}>{i}:</p>
              <p style={{textAlign:'left  ', marginRight:5, color:'green'}}>R${preco.toFixed(2).replace('.',',')}</p>
            </div>
          )
        })

      } else if(this.state.payRes === 'f') {
        prodList = (
          <div>
            <p style={{color:'gray'}}>Houve um erro no processo de pagamento, verifique as informações da sua forma de pagamento e tente novamente!</p>
          </div>
        )
      }    else if(this.state.payRes === 's') {
        prodList = (
          <div style={{display:"flex", flexDirection:'column', justifyContent:'space-between', height:"100%"}}>
            <p style={{color:'gray'}}>Parabéns, sua compra foi aprovada! todas as informações sobre o seu pedido e a entrega foram enviados para o seu email (<b>{this.state.email}</b>).</p>
            <p style={{color:'gray'}}>Código: <b> {this.state.pedidoId} </b> </p>
            <p style={{color:'gray',fontSize:'small', marginBottom:20}}>Vá na área <b> "Pedidos" </b> do nosso site e coloque o código do seu pedido, para verificar o andamento!.</p>
          </div>
        )
      } 
      
      if(this.state.qrMode){
        prodList = (
          <div>
            <QRCode value={this.state.qrCode} />
            <p onClick={()=>{

              navigator.clipboard.writeText(this.state.qrCode)
              this.setState({copyIconColor:'green'})

              }} style={{fontSize:'small', cursor:'pointer'}}>{this.state.qrCode.substring(0,50)}...<IoCopyOutline color={this.state.copyIconColor}/></p>
            {
              this.state.pixStatus != 'pending' ? <p style={{color:"gray", fontSize:"small", marginTop:30}}>Pague com o QR Code, ou clique no link de pagamento para copia-lo. <br/><br/> <b>Após fazer o pagamento em seu banco, aperte em 'Verificar pagamento' para dar andamento em seu pedido!</b></p> 
              : <p style={{color:"gray", fontSize:"small", marginTop:30}}>Pague com o QR Code, ou clique no link de pagamento para copia-lo. <br/><br/><b> O pagamento ainda não foi processado, espere alguns segundos e verifique novamente!</b></p>
            }
          </div>
        )
      }

      if(this.state.boletoMode){
        prodList = (
          <div>
            <IoBarcode size={100}/>
            <a style={{fontSize:'small',display:'block', textDecoration:'none'}} href={this.state.boletoCode}>Clique para baixar o seu boleto. <IoDownload color={this.state.dlIconColor}/></a>
            <p style={{color:"gray", fontSize:"small", marginTop:30}}>O pagamento será processado em até 2 dias úteis. <br/><br/> <b>Após a confirmação, o pedido será enviado, e você receberá um email com os dados da sua compra!</b></p> 
          </div>
        )
      }

      let prodListMobile;

      if(this.state.payRes === '' && JSON.parse(window.localStorage.getItem("checkoutCart")) != '' ){

        prodListMobile = prods.map((i,idx)=>{

          let preco = parseFloat(precos[idx].replace('R$','').replace(',','.')) * qtds[idx]

          return(
            <div key={idx} style={{display:"flex"}}>
              <p style={{textAlign:'left  ', marginRight:5, color:'gray'}}>{qtds[idx]}x</p>
              <p style={{textAlign:'left  ', marginRight:5, color:'gray', fontWeight:'bold'}}>{i}:</p>
              <p style={{textAlign:'left  ', marginRight:5, color:'green'}}>R${preco.toFixed(2).replace('.',',')}</p>
            </div>
          )
        })

      } else if(this.state.payRes === 'f') {
        prodListMobile = (
          <div>
            <p style={{color:'gray'}}>Houve um erro no processo de pagamento, verifique as informações da sua forma de pagamento e tente novamente!</p>
          </div>
        )
      }    else if(this.state.payRes === 's') {
        prodListMobile = (
          <div style={{display:"flex", flexDirection:'column', justifyContent:'space-between', height:"100%"}}>
            <p style={{color:'gray'}}>Parabéns, sua compra foi aprovada! todas as informações sobre o seu pedido e a entrega foram enviados para o seu email (<b>{this.state.email}</b>).</p>
            <p style={{color:'gray'}}>Código: <b> {this.state.pedidoId} </b> </p>
            <p style={{color:'gray',fontSize:'small', marginBottom:20}}>Vá na área <b> "Pedidos" </b> do nosso site e coloque o código do seu pedido, para verificar o andamento!.</p>
          </div>
        )
      } 
      
      if(this.state.qrMode){
        prodListMobile = (
          <div>
            <QRCode value={this.state.qrCode} />
            <p onClick={()=>{

              navigator.clipboard.writeText(this.state.qrCode)
              this.setState({copyIconColor:'green'})

              }} style={{fontSize:'small', cursor:'pointer'}}>{this.state.qrCode.substring(0,40)}...<IoCopyOutline color={this.state.copyIconColor} /></p>
            {
              this.state.pixStatus != 'pending' ? <p style={{color:"gray", fontSize:"small", marginTop:30}}>Pague com o QR Code, ou clique no link de pagamento para copia-lo. <br/><br/> <b>Após fazer o pagamento em seu banco, aperte em 'Verificar pagamento' para dar andamento em seu pedido!</b></p> 
              : <p style={{color:"gray", fontSize:"small", marginTop:30}}>Pague com o QR Code, ou clique no link de pagamento para copia-lo. <br/><br/><b> O pagamento ainda não foi processado, espere alguns segundos e verifique novamente!</b></p>
            }
          </div>
        )
      }

      if(this.state.boletoMode){
        prodListMobile = (
          <div>
            <IoBarcode size={100}/>
            <a style={{fontSize:'small',display:'block', textDecoration:'none'}} href={this.state.boletoCode}>Clique para baixar o seu boleto. <IoDownload color={this.state.dlIconColor}/></a>
            <p style={{color:"gray", fontSize:"small", marginTop:30}}>O pagamento será processado em até 2 dias úteis. <br/><br/> <b>Após a confirmação, o pedido será enviado, e você receberá um email com os dados da sua compra!</b></p> 
          </div>
        )
      }

      let subtotal = 0

      if(JSON.parse(window.localStorage.getItem("checkoutCart")) != '') {
        precos.forEach((i,idx) => {
          let n = parseFloat(i.replace('R$','').replace(',','.'))
          let qtd = qtds
          subtotal = subtotal + (n * qtd[idx])

          if(subtotal < 100) subtotal = subtotal + this.state.frete
        });
      }

      let paymentForm;

      if(this.state.FP === 'cdc') {
        paymentForm = (
          <div>
                  <div className='CKInputDiv3' >
                    <p className='CKInputTitle'>Número do cartão</p>
                    <input value={this.state.numeroCartao} onChange={i=> this.state.payRes === '' ? this.setState({numeroCartao:i.target.value}) : ''} className='CKInput' placeholder='1234 1234 1234 1234'/>
                  </div>
                  <div className='CKInputDiv3'>
                    <p className='CKInputTitle'>Validade</p>
                    <InputMask mask={'99/99'} value={this.state.validade} onChange={i=> this.state.payRes === '' ? this.setState({validade:i.target.value}) : ''} className='CKInput' placeholder='MM/AA'/>
                  </div>
                  <div className='CKInputDiv3'>
                    <p className='CKInputTitle'>Código de segurança</p>
                    <input  value={this.state.codigoSeguranca} onChange={i=> this.state.payRes === '' ? this.setState({codigoSeguranca:i.target.value}) : ''} className='CKInput' placeholder='123 ou 1234'/>
                  </div>
                  <div className='CKInputDiv3'>
                    <p className='CKInputTitle'>Nome de Titular</p>
                    <input value={this.state.nomeTitular} onChange={i=> this.state.payRes === '' ? this.setState({nomeTitular:i.target.value}) : ''} className='CKInput' placeholder='Fulado D Silva'/>
                  </div>
                  <div className='CKInputDiv3'>
                    <p className='CKInputTitle'>Parcelas</p>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <button className='QtdBtn' onClick={()=>{
                          if(this.state.parcelas > 2) {
                            this.setState({parcelas:this.state.parcelas - 1})
                          } else {
                            if(this.state.parcelas === 2) {
                              this.setState({parcelas:'À vista'})
                            }
                          }
                          }}>
                          <IoRemoveCircleOutline className='QtdIconP' size={20} />
                        </button>
                        <p style={{color:'gray', marginRight:5, fontWeight:'bold'}}>{this.state.parcelas}</p>
                        <button className='QtdBtn' onClick={()=>{
                          if(this.state.parcelas === 'À vista'){
                            this.setState({parcelas:2})
                          } else {
                            if(this.state.parcelas < 6) this.setState({parcelas:this.state.parcelas + 1})
                          }
                          }}>
                          <IoAddCircleOutline  className='QtdIconP' size={20}  />
                        </button>
                      </div>
                  </div>

          </div>
        )
      } else if (this.state.FP === 'pix') {
        paymentForm = (
          <div>
                  <div >
                    <p className='CKInfoP'>A confirmação de pagamento é realizada em poucos minutos. Utilize o aplicativo do seu banco para pagar.</p>
                  </div>

          </div>
        )

      } else if (this.state.FP === 'boleto') {
        paymentForm = (
          <div>
                  <div >
                    <p className='CKInfoP'>A confirmação de pagamento pode levar até 2 dias. O envio é feito após a confirmação do pagamento.</p>
                  </div>

          </div>
        )
      }

        return (
          <div className="App" >
            <Desktop>

            <Link  to={'/'}>
              <img style={{cursor:"pointer", maxWidth:"100%"}} src={CHECKOUT}/>
            </Link>
              <div className={this.state.payRes === 's' ? 'ContainerPS' : this.state.payRes === 'f' ? 'ContainerPF' :'ContainerP'}>
                
                <div className='FormsDiv' >

                  <div className={'FormDiv1'}>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                      <p className='CKNumber' style={{backgroundColor: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete1 ? '#a8c9ef' : 'gray'}}>1</p>
                      <p className='CKTitle' style={{color: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete1 ? '#a8c9ef' : 'gray'}}>Dados Pessoais</p>
                    </div>
                    <p className='CKInfo'>Solicitamos apenas as informações essenciais para você fazer sua compra.</p>
                    <div className='CKInputDiv1' >
                      <p className='CKInputTitle'>Nome Completo</p>
                      <input value={this.state.nome} onChange={i=> this.state.payRes === '' ? this.setState({nome:i.target.value}) : ''} className='CKInput' placeholder='Ex: Fulado da Silva'/>
                    </div>
                    <div className='CKInputDiv1'>
                      <p className='CKInputTitle'>Email</p>
                      <input value={this.state.email} onChange={i=> this.state.payRes === '' ? this.setState({email:i.target.value}) : ''} className='CKInput' placeholder='Ex: fulano@gmail.com'/>
                    </div>
                    <div className='CKInputDiv1'>
                      <p className='CKInputTitle'>CPF (Emissão de nota fiscal)</p>
                      <InputMask mask={'999.999.999-99'}  value={this.state.cpf} onChange={i=> this.state.payRes === '' ? this.setState({cpf:i.target.value}) : ''} className='CKInput' placeholder='Ex: 000.000.000-00'/>
                    </div>
                    <div className='CKInputDiv1'>
                      <p className='CKInputTitle'>Celular</p>
                      <InputMask mask={'(99)99999-9999'}  value={this.state.celular} onChange={i=> this.state.payRes === '' ? this.setState({celular:i.target.value}) : ''} className='CKInput' placeholder='Ex: (00) 0 0000-0000'/>
                    </div>
                  </div>

                  <div className={'FormDiv2'}>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                      <p className='CKNumber' style={{backgroundColor: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete2 ? '#a8c9ef' : 'gray'}}>2</p>
                      <p className='CKTitle' style={{color: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete2 ? '#a8c9ef' : 'gray'}}>Entrega</p>
                    </div>
                    <p className='CKInfo'>Insira o endereço de entregado do seu pedido.</p>
                    <div className='CKInputDiv2' >
                      <p className='CKInputTitle'>CEP</p>
                      <InputMask mask={'99999-999'} onBlur={()=>{
                        if(/\d/.test(this.state.cep[8]) === true){
                          fetch(`https://viacep.com.br/ws/${this.state.cep.replace('-','')}/json/`)
                          .then(res=>res.json())
                          // .then(res=>console.log(res.uf))
                          .then(res=>{this.setState({
                              estado:res.uf,
                              cidade:res.localidade,
                              bairro:res.bairro,
                              endereco:res.logradouro
                            })
                          })
                        }
                      }}  value={this.state.cep} onChange={i=> this.state.payRes === '' ? this.setState({cep:i.target.value}) : ''} className='CKInput' placeholder='12345-678'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Bairro</p>
                      <input value={this.state.bairro} onChange={i=> this.state.payRes === '' ? this.setState({bairro:i.target.value}) : ''} className='CKInput' placeholder='Ex: Asa Sul'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Endereço</p>
                      <input value={this.state.endereco} onChange={i=> this.state.payRes === '' ? this.setState({endereco:i.target.value}) : ''} className='CKInput' placeholder='Ex: Rua Alameda da Silva'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Número</p>
                      <input value={this.state.numero} onChange={i=> this.state.payRes === '' ? this.setState({numero:i.target.value}) : ''} className='CKInput' placeholder='Ex: 7'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Complemento (Opcional)</p>
                      <input value={this.state.complemento} onChange={i=> this.state.payRes === '' ? this.setState({complemento:i.target.value}) : ''} className='CKInput' placeholder='Ex: Em frente a drogaria'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Destinatário</p>
                      <input value={this.state.destinatario} onChange={i=> this.state.payRes === '' ? this.setState({destinatario:i.target.value}) : ''} className='CKInput' placeholder='Ex: Fulano da Silva'/>
                    </div>
                  </div>

                  <div className={'FormDiv3'}>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                      <p className='CKNumber' style={{backgroundColor: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete3 ? '#a8c9ef' : 'gray'}}>3</p>
                      <p className='CKTitle' style={{color: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete3 ? '#a8c9ef' : 'gray'}}>Pagamento</p>
                    </div>
                    <p className='CKInfo'>Selecione uma forma de pagamento.</p>
                    <div className='CKBtnsDiv'>
                      <button onClick={()=>{
                        this.setState({
                          FP:'cdc',
                          parcelas:'À vista'
                        })
                      }} className='PaymentBTN'>
                        <img src={card} className={this.state.FP === 'cdc' ? 'PaymentIconS': 'PaymentIcon'} />
                        <p>Cartão de crédito</p>
                      </button>
                      <button onClick={()=>{
                        this.setState({
                          FP:'pix',
                          parcelas:'À vista'
                        })
                      }} className='PaymentBTN'>
                        <img src={pix} className={this.state.FP === 'pix' ? 'PaymentIconS': 'PaymentIcon'} />
                        <p>Pix</p>
                      </button>
                      <button onClick={()=>{
                        this.setState({
                          FP:'boleto',
                          parcelas:'À vista'
                        })
                      }} className='PaymentBTN'>
                        <img src={boleto} className={this.state.FP === 'boleto' ? 'PaymentIconS': 'PaymentIcon'} />
                        <p>Boleto</p>
                      </button>
                    </div>
                    {paymentForm}
                  </div>


                </div>

                  <div className='FormDivR'>
                    <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                      <p className='CKTitleR'>{this.state.payRes === 'f' ? 'COMPRA NEGADA' : this.state.payRes ? 'COMPRA APROVADA!' : "RESUMO"}</p>

                      <div style={{display:"flex", justifyContent:'space-around', width:"100%"}}>
                        <div style={{width:'50%'}}>
                          <p style={{color:this.state.payRes === 'f' ? '#990000' : 'gray', marginTop:0}}>{this.state.payRes === 'f' ? 'ERRO' : this.state.payRes === 's' ? 'INFORMAÇÕES' : this.state.qrMode ? 'QR CODE' : this.state.boletoMode ? 'BOLETO' : 'PRODUTOS'}</p>
                          {prodList}
                        </div>

                        <div style={{display:'flex', alignItems:"center"}}>
                          <div style={{borderRadius:'100%', height:250,width:250, display:"flex", flexDirection:'column', alignItems:'center', justifyContent:'center', backgroundColor:this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : '#a8c9ef'}}>
                            <div style={{ borderStyle:'solid', borderWidth:0, borderBottomWidth:1, borderColor:'white', paddingBottom:15}}>
                              <p style={{fontWeight:'bold', color:'white', fontSize:'larger', margin:0}}>Valor total: R${this.state.subtotal.toFixed(2).replace('.',',')}</p>
                              {/* <p style={{fontWeight:'bold', color:'white', fontSize:10, margin:0, marginTop:5, textAlign:'right '}}>5x: R${(subtotal/5).toFixed(2).replace('.',',')}</p> */}
                            </div>
                        <p style={{marginLeft:10, color:'white', fontSize:'large'}}>{
                          this.state.parcelas === 'À vista' ? 'À VISTA' : `${this.state.parcelas}x de R$${(this.state.subtotal/this.state.parcelas).toFixed(2).replace('.',',')}`
                        }</p>
                          </div>
                        <p style={{color:this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : '#a8c9ef', marginLeft:10,}}>{this.state.frete != 0 ? `Frete: R$${parseFloat(this.state.frete).toFixed(2).replace('.',',')}` : 'Frete grátis!'}</p>
                        </div>
                      </div>

                      <button onClick={()=>{
                        if(complete1 && complete2 && complete3 && this.state.payRes === ''){

                          if(this.state.FP === 'cdc'){

                              Pay(this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, this.state.numeroCartao, this.state.validade, this.state.codigoSeguranca, this.state.nomeTitular, this.state.parcelas, this.state.cart, this.state.frete != 0 ? this.state.frete*100 : 0, subtotal*100, this.state.freteETA)
                              // .then(res=>console.log(res.status))
                              .then(res=>{
                                this.setState({
                                  payRes:res.status,
                                  pedidoId:res.id
                                })
                                if(res.status === 's'){
                                  window.localStorage.setItem('checkoutCart',JSON.stringify(''))
                                }
                                
                              })

                            } else if(this.state.FP === 'pix') {
                              if(!this.state.qrMode) {
                                Pay(this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, this.state.numeroCartao, this.state.validade, this.state.codigoSeguranca, this.state.nomeTitular, this.state.parcelas, this.state.cart, this.state.frete != 0 ? Math.ceil(this.state.frete*100) : 0, subtotal*100, this.state.freteETA)
                                .then(res=>{
                                  this.setState({
                                    qrMode:res.qrMode,
                                    qrCode:res.qrCode,
                                    pixOID:res.id
                                  })
                                })

                              } else {

                                VerifyPayment(this.state.pixOID, this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, this.state.numeroCartao, this.state.validade, this.state.codigoSeguranca, this.state.nomeTitular, this.state.parcelas, this.state.cart, this.state.frete != 0 ? this.state.frete*100 : 0, subtotal*100, this.state.freteETA)
                                .then(res=>{

                                  if(res.status==='pending'){

                                    this.setState({pixStatus:'pending'})

                                  } else if(res.status==='s'){
                                    this.setState({
                                      payRes:res.status,
                                      pedidoId:res.id,
                                      qrMode:'',
                                      qrCode:'',
                                      pixOID:''
    
                                    })
      
                                    window.localStorage.setItem('checkoutCart',JSON.stringify(''))
                                  }
                                  
                                  
                                })
                                
                              }
                              
                            } else if(this.state.FP === 'boleto'){

                              Pay(this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, this.state.numeroCartao, this.state.validade, this.state.codigoSeguranca, this.state.nomeTitular, this.state.parcelas, this.state.cart, this.state.frete != 0 ? Math.ceil(this.state.frete*100) : 0, subtotal*100, this.state.freteETA)
                              .then(res=>{
                                SaveBoleto(this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, res.boletoID, res.boletoCode, this.state.cart, this.state.frete != 0 ? Math.ceil(this.state.frete*100) : 0, subtotal*100, this.state.freteETA)
                                this.setState({
                                  boletoMode:res.boletoMode,
                                  boletoCode:res.boletoCode,
                                })
                              })

                            }

                          } else  {
                              
                            if(this.state.payRes === 'f'){
                              this.setState({payRes:''})
                            } else if (this.state.payRes === 's') {
                              this.setState({redirect:true})
                            }
                            
                            window.scrollTo({behavior:'smooth',top:0})
                          } 

                      }} className={this.state.payRes === 's' ? 'ConfirmarPedidoS' :this.state.payRes === 'f' ? 'ConfirmarPedidoF' :'ConfirmarPedido'} style={{borderColor:complete1 && complete2 && complete3 && this.state.payRes==='' ? '#a8c9ef' : 'transparent'}}>
                        <p>{complete1 && complete2 && complete3 && this.state.payRes==='' ? this.state.qrMode ? 'Verificar pagamento' : 'Confirmar pedido'  : this.state.payRes === 'f' ? 'Tentar Novamente' : this.state.payRes === 's' ? 'Voltar ao início' : this.state.boletoMode ? 'Voltar ao início' : 'Preencha os formulários'}</p>
                      </button>

                    </div>
                  </div>

              </div>
              { 
                this.state.redirect && <Navigate to='/' replace={true}/>
              }
            </Desktop>
            <Mobile>

                <Link  to={'/'}>
              <img style={{cursor:"pointer", maxWidth:"100%"}} src={CHECKOUT}/>
                </Link>
              <div className={this.state.payRes === 's' ? 'ContainerPS' : this.state.payRes === 'f' ? 'ContainerPF' :'ContainerP'}>
                
                <div className='FormsDiv' style={{flexDirection:"column"}}>

                  <div className={'FormDiv1'} style={{width:"90%", marginBottom:20, padding:15}}>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                      <p className='CKNumber' style={{backgroundColor: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete1 ? '#a8c9ef' : 'gray'}}>1</p>
                      <p className='CKTitle' style={{color: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete1 ? '#a8c9ef' : 'gray'}}>Dados Pessoais</p>
                    </div>
                    <p className='CKInfo'>Solicitamos apenas as informações essenciais para você fazer sua compra.</p>
                    <div className='CKInputDiv1' >
                      <p className='CKInputTitle'>Nome Completo</p>
                      <input value={this.state.nome} onChange={i=> this.state.payRes === '' ? this.setState({nome:i.target.value}) : ''} className='CKInput' placeholder='Ex: Fulado da Silva'/>
                    </div>
                    <div className='CKInputDiv1'>
                      <p className='CKInputTitle'>Email</p>
                      <input value={this.state.email} onChange={i=> this.state.payRes === '' ? this.setState({email:i.target.value}) : ''} className='CKInput' placeholder='Ex: fulano@gmail.com'/>
                    </div>
                    <div className='CKInputDiv1'>
                      <p className='CKInputTitle'>CPF (Emissão de nota fiscal)</p>
                      <InputMask mask={'999.999.999-99'}  value={this.state.cpf} onChange={i=> this.state.payRes === '' ? this.setState({cpf:i.target.value}) : ''} className='CKInput' placeholder='Ex: 000.000.000-00'/>
                    </div>
                    <div className='CKInputDiv1'>
                      <p className='CKInputTitle'>Celular</p>
                      <InputMask mask={'(99)99999-9999'}  value={this.state.celular} onChange={i=> this.state.payRes === '' ? this.setState({celular:i.target.value}) : ''} className='CKInput' placeholder='Ex: (00) 0 0000-0000'/>
                    </div>
                  </div>

                  <div className={'FormDiv2'} style={{width:"90%", marginBottom:20, padding:15}}>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                      <p className='CKNumber' style={{backgroundColor: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete2 ? '#a8c9ef' : 'gray'}}>2</p>
                      <p className='CKTitle' style={{color: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete2 ? '#a8c9ef' : 'gray'}}>Entrega</p>
                    </div>
                    <p className='CKInfo'>Insira o endereço de entregado do seu pedido.</p>
                    <div className='CKInputDiv2' >
                      <p className='CKInputTitle'>CEP</p>
                      <InputMask mask={'99999-999'} onBlur={()=>{
                        if(/\d/.test(this.state.cep[8]) === true){
                          fetch(`https://viacep.com.br/ws/${this.state.cep.replace('-','')}/json/`)
                          .then(res=>res.json())
                          // .then(res=>console.log(res.uf))
                          .then(res=>{this.setState({
                              estado:res.uf,
                              cidade:res.localidade,
                              bairro:res.bairro,
                              endereco:res.logradouro
                            })
                          })
                        }
                      }}  value={this.state.cep} onChange={i=> this.state.payRes === '' ? this.setState({cep:i.target.value}) : ''} className='CKInput' placeholder='12345-678'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Bairro</p>
                      <input value={this.state.bairro} onChange={i=> this.state.payRes === '' ? this.setState({bairro:i.target.value}) : ''} className='CKInput' placeholder='Ex: Asa Sul'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Endereço</p>
                      <input value={this.state.endereco} onChange={i=> this.state.payRes === '' ? this.setState({endereco:i.target.value}) : ''} className='CKInput' placeholder='Ex: Rua Alameda da Silva'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Número</p>
                      <input value={this.state.numero} onChange={i=> this.state.payRes === '' ? this.setState({numero:i.target.value}) : ''} className='CKInput' placeholder='Ex: 7'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Complemento (Opcional)</p>
                      <input value={this.state.complemento} onChange={i=> this.state.payRes === '' ? this.setState({complemento:i.target.value}) : ''} className='CKInput' placeholder='Ex: Em frente a drogaria'/>
                    </div>
                    <div className='CKInputDiv2'>
                      <p className='CKInputTitle'>Destinatário</p>
                      <input value={this.state.destinatario} onChange={i=> this.state.payRes === '' ? this.setState({destinatario:i.target.value}) : ''} className='CKInput' placeholder='Ex: Fulano da Silva'/>
                    </div>
                  </div>

                  <div className={'FormDiv3'} style={{width:"90%", padding:15}}>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                      <p className='CKNumber' style={{backgroundColor: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete3 ? '#a8c9ef' : 'gray'}}>3</p>
                      <p className='CKTitle' style={{color: this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : complete3 ? '#a8c9ef' : 'gray'}}>Pagamento</p>
                    </div>
                    <p className='CKInfo'>Selecione uma forma de pagamento.</p>
                    <div className='CKBtnsDiv'>
                      <button onClick={()=>{
                        if(!this.state.pBlock){
                          this.setState({
                            FP:'cdc',
                            parcelas:'À vista'
                          })
                        }
                      }} className='PaymentBTN'>
                        <img src={card} className={this.state.FP === 'cdc' ? 'PaymentIconS': 'PaymentIcon'} />
                        <p>Cartão de crédito</p>
                      </button>
                      <button onClick={()=>{
                        if(!this.state.pBlock){
                          this.setState({
                            FP:'pix',
                            parcelas:'À vista'
                          })
                        }
                      }} className='PaymentBTN'>
                        <img src={pix} className={this.state.FP === 'pix' ? 'PaymentIconS': 'PaymentIcon'} />
                        <p>Pix</p>
                      </button>
                      <button onClick={()=>{
                        if(!this.state.pBlock){
                          this.setState({
                            FP:'boleto',
                            parcelas:'À vista'
                          })
                        }
                      }} className='PaymentBTN'>
                        <img src={boleto} className={this.state.FP === 'boleto' ? 'PaymentIconS': 'PaymentIcon'} />
                        <p>Boleto</p>
                      </button>
                    </div>
                    {paymentForm}
                  </div>


                </div>

                  <div className='FormDivR' style={{width:"100%"}}>
                    <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                      <p className='CKTitleR'>{this.state.payRes === 'f' ? 'COMPRA NEGADA' : this.state.payRes ? 'COMPRA APROVADA!' : "RESUMO"}</p>

                      <div >
                        <div style={{marginBottom:20, display:"flex", flexDirection:"column", alignItems:'center'}}>
                          <p style={{color:this.state.payRes === 'f' ? '#990000' : 'gray', marginTop:0}}>{this.state.payRes === 'f' ? 'ERRO' : this.state.payRes === 's' ? 'INFORMAÇÕES' : this.state.qrMode ? 'QR CODE' : this.state.boletoMode ? 'BOLETO' : 'PRODUTOS'}</p>
                          {prodListMobile}
                        </div>

                        <div style={{display:"flex", flexDirection:'column', alignItems:'center'}} >
                          <div style={{borderRadius:'100%', height:250,width:250, display:"flex", flexDirection:'column', alignItems:'center', justifyContent:'center', backgroundColor:this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : '#a8c9ef'}}>
                            <div style={{ borderStyle:'solid', borderWidth:0, borderBottomWidth:1, borderColor:'white', paddingBottom:15}}>
                              <p style={{fontWeight:'bold', color:'white', fontSize:'larger', margin:0}}>Valor total: R${this.state.subtotal.toFixed(2).replace('.',',')}</p>
                              {/* <p style={{fontWeight:'bold', color:'white', fontSize:10, margin:0, marginTop:5, textAlign:'right '}}>5x: R${(subtotal/5).toFixed(2).replace('.',',')}</p> */}
                            </div>
                        <p style={{marginLeft:10, color:'white', fontSize:'large'}}>{
                          this.state.parcelas === 'À vista' ? 'À VISTA' : `${this.state.parcelas}x de R$${(this.state.subtotal/this.state.parcelas).toFixed(2).replace('.',',')}`
                        }</p>
                          </div>
                        <p style={{color:this.state.payRes === 's' ? 'green' : this.state.payRes === 'f' ? '#990000' : '#a8c9ef', marginLeft:10,}}>{this.state.frete != 0 ? `Frete: R$${parseFloat(this.state.frete).toFixed(2).replace('.',',')}` : 'Frete grátis!'}</p>
                        </div>
                      </div>

                      <button onClick={()=>{
                        if(complete1 && complete2 && complete3 && this.state.payRes === ''){

                          this.setState({pBlock:true})

                          if(this.state.FP === 'cdc'){

                              Pay(this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, this.state.numeroCartao, this.state.validade, this.state.codigoSeguranca, this.state.nomeTitular, this.state.parcelas, this.state.cart, this.state.frete != 0 ? this.state.frete*100 : 0, subtotal*100, this.state.freteETA)
                              // .then(res=>console.log(res.status))
                              .then(res=>{
                                this.setState({
                                  payRes:res.status,
                                  pedidoId:res.id
                                })
                                if(res.status === 's'){
                                  window.localStorage.setItem('checkoutCart',JSON.stringify(''))
                                }
                                
                              })

                            } else if(this.state.FP === 'pix') {
                              if(!this.state.qrMode) {
                                Pay(this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, this.state.numeroCartao, this.state.validade, this.state.codigoSeguranca, this.state.nomeTitular, this.state.parcelas, this.state.cart, this.state.frete != 0 ? Math.ceil(this.state.frete*100) : 0, subtotal*100, this.state.freteETA)
                                .then(res=>{
                                  this.setState({
                                    qrMode:res.qrMode,
                                    qrCode:res.qrCode,
                                    pixOID:res.id
                                  })
                                })

                              } else {

                                VerifyPayment(this.state.pixOID, this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, this.state.numeroCartao, this.state.validade, this.state.codigoSeguranca, this.state.nomeTitular, this.state.parcelas, this.state.cart, this.state.frete != 0 ? this.state.frete*100 : 0, subtotal*100, this.state.freteETA)
                                .then(res=>{

                                  if(res.status==='pending'){

                                    this.setState({pixStatus:'pending'})

                                  } else if(res.status==='s'){
                                    this.setState({
                                      payRes:res.status,
                                      pedidoId:res.id,
                                      qrMode:'',
                                      qrCode:'',
                                      pixOID:''
    
                                    })
      
                                    window.localStorage.setItem('checkoutCart',JSON.stringify(''))
                                  }
                                  
                                  
                                })
                                
                              }
                              
                            } else if(this.state.FP === 'boleto'){

                              Pay(this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, this.state.numeroCartao, this.state.validade, this.state.codigoSeguranca, this.state.nomeTitular, this.state.parcelas, this.state.cart, this.state.frete != 0 ? Math.ceil(this.state.frete*100) : 0, subtotal*100, this.state.freteETA)
                              .then(res=>{
                                SaveBoleto(this.state.nome, this.state.email, this.state.cpf, this.state.celular, this.state.cep, this.state.bairro, this.state.cidade, this.state.estado, this.state.endereco, this.state.numero, this.state.complemento, this.state.destinatario, this.state.FP, res.boletoID, res.boletoCode, this.state.cart, this.state.frete != 0 ? Math.ceil(this.state.frete*100) : 0, subtotal*100, this.state.freteETA)
                                this.setState({
                                  boletoMode:res.boletoMode,
                                  boletoCode:res.boletoCode,
                                })
                              })

                            }

                          } else  {
                              
                            if(this.state.payRes === 'f'){
                              this.setState({payRes:''})
                            } else if (this.state.payRes === 's') {
                              this.setState({redirect:true})
                            }
                            
                            window.scrollTo({behavior:'smooth',top:0})
                          } 

                      }} className={this.state.payRes === 's' ? 'ConfirmarPedidoS' :this.state.payRes === 'f' ? 'ConfirmarPedidoF' :'ConfirmarPedido'} style={{borderColor:complete1 && complete2 && complete3 && this.state.payRes==='' ? '#a8c9ef' : 'transparent', width:'100%'}}>
                        <p>{complete1 && complete2 && complete3 && this.state.payRes==='' ? this.state.qrMode ? 'Verificar pagamento' : 'Confirmar pedido'  : this.state.payRes === 'f' ? 'Tentar Novamente' : this.state.payRes === 's' ? 'Voltar ao início' : this.state.boletoMode ? 'Voltar ao início' : 'Preencha os formulários'}</p>
                      </button>

                    </div>
                  </div>

              </div>
              { 
                this.state.redirect && <Navigate to='/' replace={true}/>
              }

            </Mobile>
          </div>
        );
    }
    }


export default Checkout;