import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import QSLogo from '../assets/img/QSLogo.png'
import {IoLockClosed, IoMail} from 'react-icons/io5'
import { Link } from 'react-router-dom';
import bandeiras from '../assets/img/bandeiras.png'
import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}


class Foot extends Component {

    render () {
        
        return (
          <div className="App">
            <Desktop>

        <div style={{backgroundColor:'white', paddingTop:50, borderStyle:"solid", borderWidth:0, borderTopWidth:1, borderColor:'gainsboro'}}>
                <div style={{ display:'flex', justifyContent:"space-between ", width:'80%', margin:"auto"}}>
                  <div style={{width:"30%"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:'flex-start'}}>
                      <img style={{maxWidth:'50%'}} src={QSLogo}/>
                      <p style={{textAlign:"left", color:'gray'}}>A Quickshop se consolidou no mercado brasileiro após apresentar produtos inéditos para o dia a dia da família moderna. Ao todo, acumulamos mais de 55 mil vendas realizadas para todo Brasil com mais de 98% de satisfação.</p>
                    </div>
                    <div style={{marginTop:15}}>
                      <p style={{textAlign:"left", fontWeight:"bold", color:"gray"}}>ATENDIMENTO</p>
                      <p style={{display:'flex', color:"gray", margin:0}}> <IoMail color='gray' size={25} /> Contato@Quickshop.com</p>
                      <p style={{textAlign:"left", color:"gray", marginTop :5, padding:0}}>Segunda à sábado (09h às 18h)</p>
                    </div>
                  </div>
                  <div style={{width:"20%"}}>
                    <p style={{textAlign:'left', color:'gray', fontWeight:'bold'}}>Institucional</p>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoEnv'}>
                      <p className='FootLink'>Envios e Prazos</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoTroca'}>
                      <p className='FootLink'>Troca e Devolução</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoPP'}>
                      <p className='FootLink'>Política de Privacidade</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoTS'}>
                      <p className='FootLink'>Termos de Serviço</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoPR'}>
                      <p className='FootLink'>Política de Reembolso</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoPF'}>
                      <p className='FootLink'>Perguntas Frequentes</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoFC'}>
                      <p className='FootLink'>Fale Conosco</p>
                    </Link>
                  </div>
                  <div style={{width:"20%"}}>
                    <p style={{textAlign:'left', color:'gray', fontWeight:'bold'}}>Novidades</p>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Casa'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Casa</p>
                    </Link>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Ele'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Ele</p>
                    </Link>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Ela'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Ela</p>
                    </Link>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Pets'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Pets</p>
                    </Link>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Promo'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Ofertas</p>
                    </Link>
                  </div>
                  <div style={{width:"25%"}}>
                    <p style={{textAlign:'center', color:'gray', fontWeight:'bold'}}>Receba promos e dicas por email, e fique por dentro das novidades, sem spam!</p>
                    <input className='FootEmail' placeholder='email' />
                  </div>
                </div>
                <div style={{alignItems:'flex-start', width:'100%', display:'flex', flexDirection:'column', width:'80%', margin:'auto'}}>
                  <p style={{textAlign:'left', color:'gray', margin:0}}>SEGURANÇA & QUALIDADE</p>
                  <div style={{display:'flex',marginTop:5, marginBottom:10}}>
                    <IoLockClosed size={30} color={'gray'}/>
                    <IoLockClosed size={30} color={'gray'}/>
                  </div>
                </div>
                <div style={{display:"flex", justifyContent:'space-between', width:'80%', margin:'auto', paddingTop:5, borderStyle:'solid', borderWidth:0, borderTopWidth:1, borderColor:'gainsboro'}}>
                  <div>
                    <p style={{textAlign:"left", color:'gray', fontSize:'small'}}>©️ 2021 BMM NEGOCIOS DIGITAIS - CNPJ: CPJJJJJJAJJAJA | Alameda Santos, 18 - Cerqueira César, São Paulo - SP | Todos os direitos reservados</p>
                  </div>
                  <div style={{ justifyContent:'center', alignItems:'flex-end', display:'flex', flexDirection:'column'}}>
                    <img style={{maxWidth:'40%'}} src={bandeiras  }/>
                  </div>
                </div>
              </div>
            </Desktop>
            <Mobile>
        <div style={{backgroundColor:'white', paddingTop:50, borderStyle:"solid", borderWidth:0, borderTopWidth:1, borderColor:'gainsboro'}}>
                <div style={{ display:'flex', flexDirection:"column", justifyContent:"space-between ", width:'80%', margin:"auto"}}>
                  <div style={{width:"100%"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:'flex-start'}}>
                      <img style={{maxWidth:'50%'}} src={QSLogo}/>
                      <p style={{textAlign:"left", color:'gray'}}>A Quickshop se consolidou no mercado brasileiro após apresentar produtos inéditos para o dia a dia da família moderna. Ao todo, acumulamos mais de 55 mil vendas realizadas para todo Brasil com mais de 98% de satisfação.</p>
                    </div>
                    <div style={{marginTop:15}}>
                      <p style={{textAlign:"left", fontWeight:"bold", color:"gray"}}>ATENDIMENTO</p>
                      <p style={{display:'flex', color:"gray", margin:0}}> <IoMail color='gray' size={25} /> Contato@Quickshop.com</p>
                      <p style={{textAlign:"left", color:"gray", marginTop :5, padding:0}}>Segunda à sábado (09h às 18h)</p>
                    </div>
                  </div>
                  <div style={{width:"100%"}}>
                    <p style={{textAlign:'left', color:'gray', fontWeight:'bold'}}>Institucional</p>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoEnv'}>
                      <p className='FootLink'>Envios e Prazos</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoTroca'}>
                      <p className='FootLink'>Troca e Devolução</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoPP'}>
                      <p className='FootLink'>Política de Privacidade</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoTS'}>
                      <p className='FootLink'>Termos de Serviço</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoPR'}>
                      <p className='FootLink'>Política de Reembolso</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoPF'}>
                      <p className='FootLink'>Perguntas Frequentes</p>
                    </Link>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/InfoFC'}>
                      <p className='FootLink'>Fale Conosco</p>
                    </Link>
                  </div>
                  <div style={{width:"100%"}}>
                    <p style={{textAlign:'left', color:'gray', fontWeight:'bold'}}>Novidades</p>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Casa'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Casa</p>
                    </Link>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Ele'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Ele</p>
                    </Link>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Ela'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Ela</p>
                    </Link>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Pets'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Pets</p>
                    </Link>
                  <Link onClick={()=>{
                    window.localStorage.setItem('categoria', JSON.stringify('Promo'))
                    window.scrollTo({behavior:'smooth',top:0})
                    window.location.reload()
                  }} style={{textDecoration:'none'}} to={'/Catalog'}>
                      <p className='FootLink'>Ofertas</p>
                    </Link>
                  </div>
                  <div style={{width:"100%", marginBottom:20}}>
                    <p style={{textAlign:'center', color:'gray', fontWeight:'bold'}}>Receba promos e dicas por email, e fique por dentro das novidades, sem spam!</p>
                    <input className='FootEmail' placeholder='email' />
                  </div>
                </div>
                <div style={{alignItems:'flex-start', width:'100%', display:'flex', flexDirection:'column', width:'80%', margin:'auto'}}>
                  <p style={{textAlign:'center', color:'gray', margin:0, width:'100%'}}>SEGURANÇA & QUALIDADE</p>
                  <div style={{display:'flex',marginTop:5, marginBottom:10, justifyContent:'center', width:'100%'}}>
                    <IoLockClosed size={30} color={'gray'}/>
                    <IoLockClosed size={30} color={'gray'}/>
                  </div>
                </div>
                <div style={{width:'80%', margin:'auto', paddingTop:5, borderStyle:'solid', borderWidth:0, borderTopWidth:1, borderColor:'gainsboro'}}>
                  <div>
                    <p style={{textAlign:"center", color:'gray', fontSize:'small'}}>©️ 2021 BMM NEGOCIOS DIGITAIS - CNPJ: CPJJJJJJAJJAJA | Alameda Santos, 18 - Cerqueira César, São Paulo - SP | Todos os direitos reservados</p>
                  </div>
                  <div style={{ justifyContent:'center', alignItems:'flex-end', display:'flex', flexDirection:'column', paddingBottom:30}}>
                    <img style={{maxWidth:'100%'}} src={bandeiras  }/>
                  </div>
                </div>
              </div>

            </Mobile>
      
      
          </div>
        );
      }
    }

  export default Foot;