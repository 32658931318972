import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoLogoWhatsapp, IoMail, IoRefresh, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FecthPedido, FetchAce, FetchMochi, FetchMost } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class InfoFC extends Component {



    render(){

        return (
          <div className="App">
      
            <Header/>

      
            <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white", marginBottom:40}}>
              <p className='IPTitle'>Fale conosco</p>

              <div style={{display:"flex", flexDirection:"column", width:'80%', justifyContent:'space-between', alignItems:'center' ,marginRight:'auto', marginLeft:'auto'}}>
                <div style={{display:"flex", width:'50%', alignItems:"center", borderStyle:'solid', borderWidth:1, borderColor:'gray', borderRadius:10, marginBottom:10, padding:10}}>
                  <IoLogoWhatsapp color='gray' size={30} style={{marginRight:5}}/>
                  <p style={{color:'gray', textTransform:'uppercase'}}>WhatsApp: (61)99280-0690</p>
                </div>
                <div style={{display:"flex", width:'50%', alignItems:"center", borderStyle:'solid', borderWidth:1, borderColor:'gray', borderRadius:10, marginBottom:10, padding:10}}>
                  <IoMail color='gray' size={30} style={{marginRight:5}}/>
                  <p style={{color:'gray', textTransform:'uppercase'}}>Email: contato@quickshopbr.com.br</p>
                </div>
              </div>
              <p style={{fontWeight:'bold', color:'gray', marginTop:40}}>Estamos disponíveis para tirar suas dúvidas e te auxiliar, de 8h às 22h todos os dias!</p>

            </div>
      
            <Foot/>
          </div>
        );
    }
    }


export default InfoFC;