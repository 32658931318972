import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Carousel from 'react-multi-carousel';
import {IoAddCircle, IoCart, IoCartOutline, IoChevronForward, IoClose, IoRefresh, IoRemoveCircle, IoRocket, IoStar} from 'react-icons/io5'
import { Link } from 'react-router-dom';
import aval from '../components/Rating';
import parcela from '../components/Parcela';
import porcentagem from '../components/Porcentagem';
import { FetchFrete, FetchRel, MostAlgo, RemoveProd } from '../SQL';
import ModalProd from '../components/ModalProd';



import Header from '../components/Header';
import Foot from '../components/Foot';
import { ChangeQtd, FetchCart } from '../SQL';
import InputMask from 'react-input-mask/lib/react-input-mask.development';
import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};



class Cart extends Component {

    state = {
        cart:{},
        prods:[],
        precos:[],
        qtds:[],
        imgs:[],
        RelProds:[],
        frete:0,
        ids:[],
        MiniModal:false,
        cepI:'71919-180',
        freteList:[],
        freteETA:0


    }

    componentDidMount = async() =>{
      let cart = await FetchCart().then(async res=>{
        this.setState({
        cart:res,
        prods:res.prods,
        precos:res.precos,
        qtds:res.qtds,
        imgs:res.imgs,
        ids:res.ids
      })
      let rel = await FetchRel(res.tags, 0).then(res => {
        this.setState({RelProds:res})
        console.log(res)
      })
      // let frete = await FetchFrete(res)
  
    })
      
    }

    render(){

        let cart = this.state.prods

        let i = this.state.cart

        let cartList = cart.map((item,idx)=>{

        let total = (parseFloat(i.precos[idx].replace('R$','').replace(',','.')) * i.qtds[idx]).toFixed(2)

        let qtd = i.qtds[idx]

        let ids = this.state.ids

            return(
                <div key={idx} style={{display:'flex', alignItems:'center' , justifyContent:"space-between", borderStyle:'solid', borderWidth:0, borderBottomWidth:1, paddingBottom:10, borderColor:'gainsboro', marginBottom:30}}>
                    <div style={{display:'flex', alignItems:'center', width:'30%', justifyContent:'left  '}}>
                        <img className='CartIMG' src={URL=this.state.imgs[idx]}/>
                       <Link onClick={()=> {
                         window.localStorage.setItem('prodId',JSON.stringify(ids[idx]))
                       }} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                          <p style={{marginLeft:10, fontWeight:"bold", color:"gray", fontSize:'medium'}}>{i.prods[idx]}</p>
                        </Link>
                    </div>
                    <div style={{width:"25%", justifyContent:'center', display:"flex"}}>
                        <p style={{color:'gray'}}>{i.variacoes[idx]}</p>
                    </div>
                    <div style={{width:"25%", justifyContent:'center', display:"flex"}}>
                        <p style={{color:'green'}}>{i.precos[idx]}</p>
                    </div>
                    <div style={{display:'flex', alignItems:"center", width:'25%', justifyContent:'center'}}>
                      <button onClick={()=>{
                        let val = this.state.qtds
                        if(val[idx]>1){
                          val[idx] = val[idx] - 1
                          ChangeQtd(val)
                          this.setState({
                            qtds:val
                          })
                        }
                      }} className='AddRemDiv'>
                        <IoRemoveCircle className='AddRem' size={20}/>
                      </button>
                        <p style={{color:'#a8c9ef'}}>{this.state.qtds[idx]}</p>
                      <button onClick={()=>{
                        let val = this.state.qtds
                        val[idx] = val[idx] + 1
                        ChangeQtd(val)
                        this.setState({
                          qtds:val
                        })
                      }} className='AddRemDiv'>
                        <IoAddCircle className='AddRem' size={20}/>
                      </button>
                    </div>
                    <div style={{display:'flex', alignItems:"center", width:'10%', justifyContent:'flex-end'}}>
                        <p style={{color:'gray', fontWeight:"bold"}}>{'R$'+total.replace('.',',')}</p>
                    </div>
                    <div className='RemProdDiv' style={{position:'absolute', right:70}}>
                      <button onClick={()=>{RemoveProd(this.state.cart,idx).then(async()=>{
                              let cart = await FetchCart().then(async res=>{
                                this.setState({
                                cart:res,
                                prods:res.prods,
                                precos:res.precos,
                                qtds:res.qtds,
                                imgs:res.imgs,
                                ids:res.ids
                              })
                              let rel = await FetchRel(res.tags).then(res => {
                                this.setState({RelProds:res})
                                console.log(res)
                              })
                          
                            })
                        
                      })}} style={{backgroundColor:'transparent', borderColor:'transparent', cursor:'pointer'}}>
                        <IoClose className='RemProd' size={25}/>
                      </button>
                    </div>
                </div>
            )
        })

        let cartListMobile = cart.map((item,idx)=>{

        let total = (parseFloat(i.precos[idx].replace('R$','').replace(',','.')) * i.qtds[idx]).toFixed(2)

        let qtd = i.qtds[idx]

        let ids = this.state.ids

            return(
                <div key={idx} style={{display:'flex', alignItems:'center' , justifyContent:"space-between", borderStyle:'solid', borderWidth:0, borderBottomWidth:1, paddingBottom:10, borderColor:'gainsboro', marginBottom:30}}>
                    <div style={{display:'flex', alignItems:'center', width:'30%', justifyContent:'left  '}}>
                        <img className='CartIMGMobile' src={URL=this.state.imgs[idx]}/>
                       <Link onClick={()=> {
                         window.localStorage.setItem('prodId',JSON.stringify(ids[idx]))
                       }} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                          <p style={{marginLeft:10, fontWeight:"bold", color:"gray", fontSize:10}}>{i.prods[idx]}</p>
                          <p style={{color:'gray', fontSize:10}}>{i.variacoes[idx]}</p>
                        </Link>
                    </div>
                    <div style={{width:"25%", justifyContent:'center', display:"flex"}}>
                        <p style={{color:'green', fontSize:10}}>{i.precos[idx]}</p>
                    </div>
                    <div style={{display:'flex', alignItems:"center", width:'15%', justifyContent:'center'}}>
                      <button onClick={()=>{
                        let val = this.state.qtds
                        if(val[idx]>1){
                          val[idx] = val[idx] - 1
                          ChangeQtd(val)
                          this.setState({
                            qtds:val
                          })
                        }
                      }} className='AddRemDiv'>
                        <IoRemoveCircle className='AddRem' size={10}/>
                      </button>
                        <p style={{color:'#a8c9ef', fontSize:10}}>{this.state.qtds[idx]}</p>
                      <button onClick={()=>{
                        let val = this.state.qtds
                        val[idx] = val[idx] + 1
                        ChangeQtd(val)
                        this.setState({
                          qtds:val
                        })
                      }} className='AddRemDiv'>
                        <IoAddCircle className='AddRem' size={10}/>
                      </button>
                    </div>
                    <div style={{display:'flex', alignItems:"center", width:'15%', justifyContent:'flex-end'}}>
                        <p style={{color:'gray', fontWeight:"bold", fontSize:10}}>{'R$'+total.replace('.',',')}</p>
                    </div>
                    <div className='RemProdDiv' style={{position:'absolute', right:'2.5%'}}>
                      <button onClick={()=>{RemoveProd(this.state.cart,idx).then(async()=>{
                              let cart = await FetchCart().then(async res=>{
                                this.setState({
                                cart:res,
                                prods:res.prods,
                                precos:res.precos,
                                qtds:res.qtds,
                                imgs:res.imgs,
                                ids:res.ids
                              })
                              let rel = await FetchRel(res.tags).then(res => {
                                this.setState({RelProds:res})
                                console.log(res)
                              })
                          
                            })
                        
                      })}} style={{backgroundColor:'transparent', borderColor:'transparent', cursor:'pointer'}}>
                        <IoClose className='RemProd' size={10}/>
                      </button>
                    </div>
                </div>
            )
        })

        let subtotal = 0

        this.state.precos.forEach((i,idx) => {
          let n = parseFloat(i.replace('R$','').replace(',','.'))
          let qtd = this.state.qtds
          subtotal = subtotal + (n * qtd[idx])

          subtotal = subtotal + this.state.frete
        });

        let freteList;

        if(this.state.freteList.length > 0) {
          freteList = this.state.freteList.map((i,idx)=>{
            if(i.price != undefined){

              return (
                <p onClick={()=>{
                  this.setState({frete:parseFloat(i.price),freteETA:i.custom_delivery_time})
                }} className={this.state.frete === parseFloat(i.price) ? 'FreteListS' :'FreteList'}><b style={{textTransform:"uppercase"}}>{i.company.name}</b> - {i.custom_delivery_range.min} a {i.custom_delivery_range.max} dias | <b>R${i.price.replace('.',',')}</b></p>
              )

            }
          })
        }

        let freteListMobile;

        if(this.state.freteList.length > 0) {
          freteListMobile = this.state.freteList.map((i,idx)=>{
            if(i.price != undefined){

              return (
                <p onClick={()=>{
                  this.setState({frete:parseFloat(i.price),freteETA:i.custom_delivery_time})
                }} style={{fontSize:8, width:'50%'}} className={this.state.frete === parseFloat(i.price) ? 'FreteListS' :'FreteList'}><b style={{textTransform:"uppercase"}}>{i.company.name}</b> - {i.custom_delivery_range.min} a {i.custom_delivery_range.max} dias | <b>R${i.price.replace('.',',')}</b></p>
              )

            }
          })
        }

        let subtotalSVG;

          subtotalSVG = (
            <div style={{alignItems:'flex-end', display:'flex', flexDirection:'column'}}>
              <div style={{display:'flex', alignItems:"center", marginBottom:10}}>
                <span style={{color:'gray', marginRight:5, fontSize:"small"}}>Calcular frete:</span>
                <InputMask mask={'99999-999'} placeholder='Insira o seu CEP' value={this.state.cepI} onChange={i=>this.setState({cepI:i.target.value})} style={{outline:'none', padding:5, borderColor:'gainsboro', borderStyle:"solid", borderWidth:1, borderRadius:5, color:'gray'}} />
                <IoChevronForward onClick={()=>{

                  FetchFrete(this.state.cart,this.state.cepI).then(res=>{
                    this.setState({freteList:res})
                  })

                }} style={{cursor:"pointer"}} color='#a8c9ef' size={20}/>
              </div>
              {freteList}
              <div style={{display:"flex", marginTop:20}}>
                <p style={{marginRight:10, fontWeight:'bold', color:'gray'}}>SUBTOTAL</p>
                <p style={{color:'green', fontWeight:"bold"}}>{`R$${subtotal.toFixed(2).replace('.',',')}`}</p>
              </div>
              <div style={{display:'flex', alignItems:"center"}}>
                <IoRocket color='a8c9ef' size={30} style={{marginRight:5}}/>
                <p style={{color:'#a8c9ef ', fontWeight:'bold'}}>Entrega extemamente rápida!</p>
              </div>
              <div style={{display:'flex', alignItems:"center"}}>
                <IoRefresh color='a8c9ef' size={30} style={{marginRight:5}}/>
                <p style={{color:'#a8c9ef '}}>Devolução gratis</p>
              </div>
              <div>
                <button onClick={()=>{
                  window.localStorage.setItem('checkoutCart',JSON.stringify([this.state.cart,this.state.frete, this.state.freteETA]))
                }} className={this.state.frete === 0 ? 'CartFinishButtonD' : 'CartFinishButton'}>
                 <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className={this.state.frete === 0 ? 'FinishLinkD' :'FinishLink'} to={'/Checkout'}>
                    <p>Finalizar Compra</p>
                  </Link>
                </button>
              </div>
                <p style={{fontSize:"small", color:'gainsboro'}}>{this.state.frete === 0 ? 'Calcule o frete antes de finalizar a compra' : ''}</p>
            </div>
          )
          
        let subtotalSVGMobile;

          subtotalSVGMobile = (
            <div style={{alignItems:'flex-end', display:'flex', flexDirection:'column'}}>
              <div style={{display:'flex', alignItems:"center", marginBottom:10, justifyContent:'flex-end'}}>
                <span style={{color:'gray', marginRight:5, fontSize:"small"}}>Calcular frete:</span>
                <InputMask mask={'99999-999'} placeholder='Insira o seu CEP' value={this.state.cepI} onChange={i=>this.setState({cepI:i.target.value})} style={{outline:'none', padding:5, borderColor:'gainsboro', borderStyle:"solid", borderWidth:1, borderRadius:5, color:'gray',fontSize:10, width:'30%'}} />
                <IoChevronForward onClick={()=>{

                  FetchFrete(this.state.cart,this.state.cepI).then(res=>{
                    this.setState({freteList:res})
                  })

                }} style={{cursor:"pointer"}} color='#a8c9ef' size={20}/>
              </div>
              {freteListMobile}
              <div style={{display:"flex", marginTop:20}}>
                <p style={{marginRight:10, fontWeight:'bold',fontSize:13, color:'gray'}}>SUBTOTAL</p>
                <p style={{color:'green', fontWeight:"bold",fontSize:13}}>{`R$${subtotal.toFixed(2).replace('.',',')}`}</p>
              </div>
              <div style={{display:'flex', alignItems:"center"}}>
                <IoRocket color='a8c9ef' size={30} style={{marginRight:5}}/>
                <p style={{color:'#a8c9ef ', fontWeight:'bold',fontSize:13,}}>Entrega extemamente rápida!</p>
              </div>
              <div style={{display:'flex', alignItems:"center"}}>
                <IoRefresh color='a8c9ef' size={30} style={{marginRight:5}}/>
                <p style={{color:'#a8c9ef ',fontSize:13}}>Devolução gratis</p>
              </div>
              <div>
                <button onClick={()=>{
                  window.localStorage.setItem('checkoutCart',JSON.stringify([this.state.cart,this.state.frete, this.state.freteETA]))
                }} className={this.state.frete === 0 ? 'CartFinishButtonD' : 'CartFinishButton'}>
                 <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className={this.state.frete === 0 ? 'FinishLinkD' :'FinishLink'} to={'/Checkout'}>
                    <p style={{fontSize:12}}>Finalizar Compra</p>
                  </Link>
                </button>
              </div>
                <p style={{fontSize:10, color:'gainsboro'}}>{this.state.frete === 0 ? 'Calcule o frete antes de finalizar a compra' : ''}</p>
            </div>
          )

        let CartSVG;
        let CartSVGMobile;

        if(this.state.prods.length > 0) {
            CartSVG = (
                <div style={{width:'80%'}}>
                    <div style={{display:"flex", justifyContent:'space-between', borderStyle:'solid', borderWidth:0, borderBottomWidth:1, marginBottom:10, borderColor:'gainsboro'}}>
                        <p className='CartTitle1'>PRODUTO</p>
                        <p className='CartTitle2'>VARIAÇÕES</p>
                        <p className='CartTitle2'>PREÇO</p>
                        <p className='CartTitle3'>QUANTIDADE</p>
                        <p className='CartTitle4'>TOTAL</p>
                    </div>
                    {cartList}
                    {subtotalSVG}
                </div>
            )
            CartSVGMobile = (
                <div style={{width:'80%'}}>
                    <div style={{display:"flex", justifyContent:'space-between', borderStyle:'solid', borderWidth:0, borderBottomWidth:1, marginBottom:10, borderColor:'gainsboro'}}>
                        <p className='CartTitle1' style={{fontSize:9}}>PRODUTO</p>
                        <p className='CartTitle2' style={{fontSize:9}}>PREÇO</p>
                        <p className='CartTitle3' style={{fontSize:9}}>QTD  </p>
                        <p className='CartTitle4' style={{fontSize:9}}>TOTAL</p>
                    </div>
                    {cartListMobile}
                    {subtotalSVGMobile}
                </div>
            )
        } else {
            CartSVG = (
                <div style={{margin:50}}>
                  <IoCartOutline   color='a8c9ef' size={150}/>
                    <p style={{fontSize:'larger', fontWeight:'bolder', color:'#a8c9ef'}}>VAZIO</p>
                    <p style={{color:"gray"}}>Seu carrinho está vazio. Econtre algum produto de seu interesse na loja e adicione aqui!</p>
                   <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'} className='VoltarInicio'>
                      <p>Voltar ao início</p>
                    </Link>
                </div>
            )
            CartSVGMobile = (
                <div style={{margin:50}}>
                  <IoCartOutline   color='a8c9ef' size={150}/>
                    <p style={{fontSize:'larger', fontWeight:'bolder', color:'#a8c9ef'}}>VAZIO</p>
                    <p style={{color:"gray"}}>Seu carrinho está vazio. Econtre algum produto de seu interesse na loja e adicione aqui!</p>
                   <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'} className='VoltarInicio'>
                      <p>Voltar ao início</p>
                    </Link>
                </div>
            )
        }

        let RelList = this.state.RelProds.map((item,index)=>{
        
          let ratings=[];
  
          Object.values(item.avaliacoes).forEach(i => {
            let nota = i.nota
            ratings.push(nota)
          })
  
          let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length
  
          let preco;

          if(item.isDisponivel){

            if(item.isPromo){
              preco = (
                  <div style={{display:'flex'}}>
                    <p className='ProdPrecoPromo  ' > {item.preco}</p>
                    <p className='ProdPromo' > {item.precoPromo}</p>
                  </div>  
              )
            } else {
              preco = (
                  <div style={{display:'flex'}}>
                    <p className='ProdPreco  ' > {item.preco}</p>
                  </div>  
              )
            }
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > INDISPONÍVEL</p>
                </div>  
            )

          }
  
          let precoCart = item.isPromo ? item.precoPromo : item.preco
  
          let precoTotal = parseFloat(precoCart.replace('R$','').replace(',','.')).toFixed(2)*this.state.qtd
  
          return (
            <div key={index} className='ProdCont'>
              <div>
                <img className='ProdIMG' src={URL=item.imgs[0]}/>
                {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
              </div>
              <div style={{marginBottom:25}}>
                <p className='ProdName'  >{item.nome}</p>
                <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                  {aval(rating)}
                </div>
                {preco}
                {parcela(item.preco)}
              </div>
              <div className='ProdBtnDiv'>
                <button className='ProdBtn' onClick={()=> {
                  window.localStorage.setItem('prodId',JSON.stringify(item.id))
                  MostAlgo(item.id)
                }}>
                 <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                    <p>Ver produto</p>
                  </Link>
                </button>
                <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' >
                    <p className='ProdLink'>Adicionar ao carrinho</p>
                </button>
              </div>
            </div>
  
          )
        }) 
        
        let RelListMobile = this.state.RelProds.map((item,index)=>{
        
          let ratings=[];

          Object.values(item.avaliacoes).forEach(i => {
            let nota = i.nota
            ratings.push(nota)
          })
  
          let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length
  
          let preco;
  
                  if(item.isDisponivel){
  
            if(item.isPromo){
              preco = (
                  <div style={{display:'flex'}}>
                    <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                    <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                  </div>  
              )
            } else {
              preco = (
                  <div style={{display:'flex'}}>
                    <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                  </div>  
              )
            }
          } else {
            
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
                </div>  
            )
          }
  
  
          return (
            <div key={index} className='ProdContMobile'>
              <div>
                <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
                {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
              </div>
              <div style={{marginBottom:25}}>
                <p className='ProdName' style={{fontSize:13}}  >{item.nome.length > 15 ? item.nome.substring(0,15)+'...' : item.nome}</p>
                <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                  {aval(rating,1)}
                </div>
                {preco}
                {parcela(item.preco)}
              </div>
              <div className='ProdBtnDiv'>
                <button className='ProdBtn' style={{width:'100%'}} onClick={()=> {
                  window.localStorage.setItem('prodId',JSON.stringify(item.id))
                  MostAlgo(item.id)
                }}>
                 <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                    <p>Ver produto</p>
                  </Link>
                </button>
                <button onClick={()=> {
                  if(this.state.MiniModal){
                    this.setState({MiniModal:false})
                    setTimeout(() => {
                      this.setState({MiniModal:true,ModalItem:item})
                    }, 100);
                  } else {
                    this.setState({
                      MiniModal:true,
                      ModalItem:item
                      })
                  }
                }} className='ProdBtn' style={{width:'100%'}}>
                    <p className='ProdLink'>Adicionar ao carrinho</p>
                </button>
              </div>
            </div>
  
          )
          }) 
        
        let miniModal = this.state.MiniModal ? (
          <div className="MiniModal">
            <div style={{display:'flex', justifyContent:'flex-end'}}>
              <button onClick={()=>{this.setState({MiniModal:false})}} style={{backgroundColor:"transparent", borderStyle:'none', cursor:'pointer'}}>
                <IoClose color='gray' size={25}/>
              </button>
            </div>
            <ModalProd nome={this.state.ModalItem.nome} variacoes={this.state.ModalItem.variacoes} prod={this.state.ModalItem}/>
          </div>
        ) : ''
  
  

        return (
          <div className="App">
            <Desktop>
              {miniModal}
        
              <Header/>
        
              <div style={{backgroundColor:'white', paddingTop:50, justifyContent:'center', alignItems:"center", display:'flex', marginBottom:50}}>
                {CartSVG}
              </div>
        
              <div style={{backgroundColor:'white', borderStyle:'solid', borderWidth:0, borderBottomWidth:1, borderColor:'gainsboro'}}>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', paddingRight:60, alignItems:"center"}}>
                  <p className='verMaisL'>Ver mais</p>
                  <p className='Title'>Compre também</p>
                <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/'}>
                    <p className='verMaisR'>Ver mais</p>
                  </Link> 
                </div>

                <div className='CarouselCont'>
                  <Carousel slidesToSlide={4} showDots={true} arrows={false} responsive={responsive}>
                    {RelList}
                  </Carousel>
                </div>


              </div>
        
              <Foot/>
            </Desktop>
            <Mobile>
              {miniModal}
        
              <Header/>
        
              <div style={{backgroundColor:'white', paddingTop:50, justifyContent:'center', alignItems:"center", display:'flex', marginBottom:50}}>
                {CartSVGMobile}
              </div>
        
              <div style={{backgroundColor:'white', borderStyle:'solid', borderWidth:0, borderBottomWidth:1, borderColor:'gainsboro'}}>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', paddingRight:60, alignItems:"center"}}>
                  <p className='verMaisL'>Ver mais</p>
                  <p className='Title' style={{fontSize:20}}>Compre também</p>
                <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/'}>
                    <p className='verMaisR'>Ver mais</p>
                  </Link> 
                </div>

                <div className='CarouselCont'>
                  <Carousel slidesToSlide={2} showDots={true} arrows={false} responsive={responsive}>
                    {RelListMobile}
                  </Carousel>
                </div>


              </div>
        
              <Foot/>

            </Mobile>

        </div>
        );
    }
    }


export default Cart;