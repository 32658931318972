import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoRefresh, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FecthPedido, FetchAce, FetchMochi, FetchMost } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class InfoTS extends Component {



    render(){

        return (
          <div className="App">
      
            <Header/>

      
            <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white", marginBottom:40}}>
              <p className='IPTitle'> Termos de serviço</p>

              <p className='IPText'>A Empresa apresenta aqui os Termos de Uso e Condi&ccedil;&otilde;es de Navega&ccedil;&atilde;o de seu Portal, que deve ser respeitado pelos seus visitantes, sob pena de todas as implica&ccedil;&otilde;es da legisla&ccedil;&atilde;o em vigor.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Para os fins destes Termos de Uso, devem-se considerar as seguintes defini&ccedil;&otilde;es e descri&ccedil;&otilde;es para seu melhor entendimento:</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong><em>Antispam:&nbsp;</em></strong>Sistema que evita correspond&ecirc;ncias n&atilde;o desejadas, como publicidade em massa, pelo bloqueio de mensagens ou movendo-as para pasta espec&iacute;fica.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Aplicativo&nbsp;<em>spider:</em>&nbsp;</strong>Programa desenvolvido para obter informa&ccedil;&otilde;es de modo automatizado na Internet, navegando pela&nbsp;<em>web&nbsp;</em>(teia) como se fosse uma&nbsp;<em>spider</em> (aranha).</p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Dados para checkout:&nbsp;</strong>Conjunto de informa&ccedil;&otilde;es pessoais de um internauta de modo que o identifique, diferenciando-o dos demais, a exemplo mas n&atilde;o se limitando ao n&uacute;mero do documento de identidade (RG ou RNE), cadastro de pessoa f&iacute;sica (CPF) ou jur&iacute;dica (CNPJ), endere&ccedil;o residencial ou comercial, nome completo, entre outros.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Empresa: BMM NEGOCIOS DIGITAIS LTDA</strong>, inscrita no CNPJ sob o n&deg;38.624.355/0001-24, Endere&ccedil;o: SANTOS, 18 -&nbsp;CONJ 26 - CERQUEIRA CESAR, S&atilde;o Paulo - SP, 01418000 - Brasil.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong><em>Layout:</em></strong> Conjunto compreendido entre apar&ecirc;ncia,&nbsp;<em>design&nbsp;</em>e fluxos do&nbsp;<em>site.</em></p>
<p className='IPText'><em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Link:&nbsp;</strong></em>Terminologia para endere&ccedil;o de Internet.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Link Externo:&nbsp;</strong>endere&ccedil;o de site da internet que n&atilde;o seja proveniente do portal da Empresa. Sendo assim, links externos s&atilde;o aqueles que embora referenciados no site da Empresa, direcionam para outros Portais, a exemplo mas n&atilde;o se limitando aos sites dos processadores de pagamento dos produtos vendidos pela Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong><em>Logs:</em></strong> Registros de atividades dos visitantes efetuadas no Portal.</p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Portal:&nbsp;</strong>Designa o endere&ccedil;o eletr&ocirc;nico&nbsp;<strong>quickshopbr.com.br</strong> e seus subdom&iacute;nios.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Senha:&nbsp;</strong>Conjunto de caracteres que serve como prova de identidade digital do usu&aacute;rio, cujo conhecimento deve ser exclusivo e &uacute;nico.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong><em>Site:</em></strong> Denomina&ccedil;&atilde;o para p&aacute;gina de Internet.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Visitante:</strong> Qualquer pessoa que navegar pelo Portal.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong><em>Webmastering:&nbsp;</em></strong>Compreende cria&ccedil;&atilde;o, programa&ccedil;&atilde;o, desenvolvimento, controle e disponibilidade de p&aacute;ginas de Internet.</p>
<p className='IPText'><strong><em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Checkout:&nbsp;</em></strong>P&aacute;gina da web da intermediadora de pagamento com o objetivo de finaliza&ccedil;&atilde;o de compra para se realizar o pagamento do produto adquirido.</p>
<p className='IPText'><strong>&nbsp;</strong></p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1 &ndash; Acesso e restri&ccedil;&otilde;es de funcionalidade</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.1. Este Portal &eacute; dedicado &agrave; atividade de intermedia&ccedil;&atilde;o de comercializa&ccedil;&atilde;o/venda de produtos eletr&ocirc;nicos e de vestu&aacute;rio, dentre outros. Para efetuar uma compra ou utilizar funcionalidades exclusivas, o visitante dever&aacute; cadastrar seu e-mail e preencher com dados pessoais e de endere&ccedil;o na plataforma de checkout.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.2. N&atilde;o &eacute; permitido aos visitantes acessar &aacute;reas de programa&ccedil;&atilde;o do Portal, seu banco de dados ou qualquer outro conjunto de informa&ccedil;&otilde;es que fa&ccedil;a parte da atividade de&nbsp;<strong><em>webmastering</em></strong><em>,&nbsp;</em>ficando sujeito quem o fizer &agrave; legisla&ccedil;&atilde;o penal brasileira e obrigado a reparar os danos que causar.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.3. Tamb&eacute;m n&atilde;o &eacute; autorizado realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor das ferramentas de consulta deste Portal e de suas funcionalidades.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.4. Neste Portal &eacute; proibida a utiliza&ccedil;&atilde;o, de aplicativos&nbsp;<em>spider&nbsp;</em>ou de minera&ccedil;&atilde;o de dados de qualquer tipo ou esp&eacute;cie, al&eacute;m de outro aqui n&atilde;o tipificado, mas que atue como um rob&ocirc;, tanto para realizar opera&ccedil;&otilde;es massificadas ou para quaisquer outras finalidades, sob aplica&ccedil;&atilde;o de legisla&ccedil;&atilde;o penal brasileira e de reparar os danos que decorrem desta utiliza&ccedil;&atilde;o.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.5. &Eacute; &ocirc;nus dos visitantes arcar com os meios necess&aacute;rios para navegar de forma segura neste Portal, incluindo o acesso &agrave; Internet, n&atilde;o sendo a Empresa responsabilizada por quaisquer danos e, ou, infort&uacute;nios causados, nada podendo ser reclamada a qualquer t&iacute;tulo seja extra ou judicialmente.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>2 &ndash; Informa&ccedil;&otilde;es gerais sobre o&nbsp;<em>site&nbsp;</em>e seu funcionamento</strong></p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>2.1. Este Portal &eacute; apresentado ao p&uacute;blico da&nbsp;<em>web&nbsp;</em>na maneira como est&aacute; dispon&iacute;vel, podendo passar por constantes aprimoramentos e atualiza&ccedil;&otilde;es, n&atilde;o ficando obrigada a Empresa a manter uma estrutura ou&nbsp;<em>layout</em>, se n&atilde;o por sua pr&oacute;pria conveni&ecirc;ncia.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.2. O acesso ao Site e todas as opera&ccedil;&otilde;es nele realizadas s&atilde;o regidos por estes Termos de Uso, que s&atilde;o aplic&aacute;veis a toda e qualquer pessoa que acessar o Site.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.3. Ao acessar o Site, o visitante concorda com todas as condi&ccedil;&otilde;es previstas nos Termos de Uso, responsabilizando-se, exclusivamente, por todos e quaisquer atos praticados no Site ou a ele relacionados.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.4. A Empresa envida seus esfor&ccedil;os para a disponibilidade continua e permanente do Portal, ficando sujeita, todavia, a eventos extraordin&aacute;rios, como desastres naturais, falhas ou colapsos nos sistemas centrais de comunica&ccedil;&atilde;o e acesso &agrave; Internet ou fatos de terceiros que fogem de sua esfera de vigil&acirc;ncia e responsabilidade.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.5. A Empresa n&atilde;o se responsabiliza por qualquer dano, preju&iacute;zo ou perda no equipamento dos visitantes causados por falhas no sistema, no servidor ou na conex&atilde;o &agrave; Internet, ainda que decorrentes de condutas de terceiros, inclusive por a&ccedil;&otilde;es de&nbsp;<em>softwares&nbsp;</em>maliciosos como v&iacute;rus, cavalos de troia, e outros que possam, de algum modo, danificar o equipamento ou a conex&atilde;o destes em decorr&ecirc;ncia de acesso, utiliza&ccedil;&atilde;o ou navega&ccedil;&atilde;o neste Portal, bem como a transfer&ecirc;ncia de dados, arquivos, imagens, textos, &aacute;udios ou v&iacute;deos contidos no mesmo.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.6. Os visitantes n&atilde;o possuem qualquer direito para exigir a disponibilidade do Portal conforme melhor lhes conv&eacute;m, tampouco poder&atilde;o pleitear indeniza&ccedil;&atilde;o ou repara&ccedil;&atilde;o de danos no caso de este Portal permanecer fora do ar, independentemente do motivo, seja extra ou judicialmente.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.7. A Empresa n&atilde;o det&eacute;m qualquer responsabilidade pela navega&ccedil;&atilde;o dos visitantes nos&nbsp;<em>links&nbsp;</em>externos contidos no Portal, sendo dever deles a leitura dos Termos de Uso e Pol&iacute;tica de Privacidade do&nbsp;<em>site&nbsp;</em>acessado e agir conforme o determinado. Caso sobrevier algum dano ou preju&iacute;zo, os visitantes ser&atilde;o os principais respons&aacute;veis, uma vez que a eles cabe a navega&ccedil;&atilde;o segura, j&aacute; que a Empresa apenas apontou o&nbsp;<em>link</em>, cabendo ao visitante a visita, ou n&atilde;o, ao&nbsp;<em>site</em>.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.8. A Empresa n&atilde;o se responsabiliza pelos atos praticados por seus visitantes no ambiente oferecido pelo Portal, devendo cada um ser imputado de acordo com a qualidade de uso da plataforma.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.9. A Empresa poder&aacute;, a seu exclusivo crit&eacute;rio e conveni&ecirc;ncia, excluir ou suspender o acesso do visitante de seu Portal, em virtude de suspeita de inexatid&atilde;o de informa&ccedil;&otilde;es ou pr&aacute;tica criminosa de algum ato. Essa faculdade n&atilde;o exime os visitantes de fornecer sempre os dados verdadeiros e praticar condutas que coadunem com o prop&oacute;sito de exist&ecirc;ncia do Portal.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>3 &ndash; Cadastro dos internautas no&nbsp;<em>website</em></strong></p>
<p className='IPText'><strong><em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</em></strong>3.1. Para utilizar as se&ccedil;&otilde;es do site da Empresa, a saber: compra on line ou outras se&ccedil;&otilde;es do site e ter acesso aos modelos dos produtos anunciados, estado de conserva&ccedil;&atilde;o, pre&ccedil;o, cor, marcas, manuais e certificados, entre outras informa&ccedil;&otilde;es, n&atilde;o &eacute; necess&aacute;rio a realiza&ccedil;&atilde;o de qualquer cadastro pelo visitante.</p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>3.2. Se desejar utilizar suas funcionalidades exclusivas, a exemplo de finaliza&ccedil;&atilde;o de compras, informa&ccedil;&otilde;es sobre meios de pagamento dos produtos anunciados, o visitante dever&aacute; preencher na plataforma de checkout vinculada ao presente Portal, fornecendo seus dados pessoais e email conforme requisitado.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.2.1. Quando o visitante preenche seus informa&ccedil;&otilde;es no checkout do Portal deve fornecer somente informa&ccedil;&otilde;es fi&eacute;is e verdadeiras, sob pena de responder civil e criminalmente, al&eacute;m de arcar com danos ocasionados pela utiliza&ccedil;&atilde;o de dados incorretos, ou de terceiros, independente da exist&ecirc;ncia de culpa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.3. Ao navegar neste Portal, o visitante declara ter pleno conhecimento deste documento e, de igual forma, da Pol&iacute;tica de Privacidade, dispon&iacute;veis em todos os&nbsp;<em>links&nbsp;</em>do Portal, pois a alega&ccedil;&atilde;o de desconhecimento n&atilde;o o eximir&aacute; de eventuais responsabilidades ou despistes de utiliza&ccedil;&atilde;o do Portal.</p>
<p className='IPText'>3.4. Os visitantes poder&atilde;o ainda incluir no site depoimentos, coment&aacute;rios e/ou opini&otilde;es sobre os servi&ccedil;os e produtos anunciados. Tais informa&ccedil;&otilde;es ser&atilde;o armazenadas em conformidade com a Pol&iacute;tica de Privacidade da Empresa. O visitante que realizar seu depoimento pessoal, coment&aacute;rio e/ou exposi&ccedil;&atilde;o de sua opini&atilde;o a respeito dos servi&ccedil;os e produtos anunciados no site, automaticamente autoriza a Empresa a utiliza&ccedil;&atilde;o deste material, tanto no pr&oacute;prio site, bem como em outros meios e ve&iacute;culos de comunica&ccedil;&atilde;o visando a publicidade dos neg&oacute;cios da Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.5 Ao preencher seus dados, o visitante receber&aacute; por e-mail a newsletter e oferta de produtos da Empresa que conter&aacute; material informativo e comercial vinculado ao Site. Caso n&atilde;o queira mais receber a newsletter basta que o visitante clique no link identificado no e-mail e solicite a exclus&atilde;o do seu cadastro do recebimento de newsletter.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4 &ndash; Comunica&ccedil;&atilde;o da Empresa com seus visitantes</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.1. A Empresa utilizar&aacute; como principais canais de comunica&ccedil;&atilde;o com seus visitantes o&nbsp;<em>e-mail&nbsp;</em>e formul&aacute;rio de comunica&ccedil;&atilde;o informado durante a intera&ccedil;&atilde;o com o&nbsp;<em>site</em>, nas suas mais diversas modalidades disponibilizado no rodap&eacute; do Site.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.1.1. A responsabilidade pelo recebimento de comunicados &eacute; exclusiva dos visitantes, por isso &eacute; indispens&aacute;vel que sempre forne&ccedil;am os dados corretos e precisos &agrave; Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.1.2. &Eacute; igualmente de sua al&ccedil;ada deixar seus sistemas de&nbsp;<em>antispam</em> configurados de modo que n&atilde;o interfiram no recebimento dos comunicados e materiais da Empresa, n&atilde;o sendo aceit&aacute;vel nenhuma escusa caso n&atilde;o tenha tido acesso a algum&nbsp;<em>e-mail&nbsp;</em>em virtude desse bloqueio ou filtro similar.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>5 &ndash; Privacidade dos visitantes no Portal da Empresa</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.1. A empresa possui documento pr&oacute;prio, denominado de Pol&iacute;tica de Privacidade, que regula o tratamento dado &agrave;s informa&ccedil;&otilde;es de cadastro e demais informa&ccedil;&otilde;es e dados coletados neste Portal.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.2. A Pol&iacute;tica de Privacidade &eacute; parte integrante e insepar&aacute;vel dos Termos de Uso e Condi&ccedil;&otilde;es de Navega&ccedil;&atilde;o deste Portal, e pode ser acessada no&nbsp;<em>link</em> &hellip;. [<em>endere&ccedil;o para a Pol&iacute;tica de Privacidade]</em>, encontrado em seu rodap&eacute; e se&ccedil;&atilde;o do site denominada pol&iacute;tica de privacidade.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.3. Caso alguma disposi&ccedil;&atilde;o da Pol&iacute;tica de Privacidade conflitar com qualquer outra presente no documento, dever&aacute; prevalecer o descrito na norma mais espec&iacute;fica.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6 &ndash; Obriga&ccedil;&otilde;es do Portal</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.1. A Empresa se obriga com os visitantes a:</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.1.1. Manter o ambiente virtual seguro, salvo por ato destrutivo de terceiro que v&aacute; al&eacute;m dos esfor&ccedil;os empenhados, hip&oacute;tese que n&atilde;o se responsabilizar&aacute; por danos oriundos dessa pr&aacute;tica danosa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.1.2. Preservar a funcionalidade do site, com&nbsp;<em>links&nbsp;</em>n&atilde;o quebrados, utilizando&nbsp;<em>layout</em> que respeita a usabilidade e navegabilidade, facilitando a navega&ccedil;&atilde;o sempre que poss&iacute;vel.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.1.3. Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percep&ccedil;&atilde;o das opera&ccedil;&otilde;es realizadas.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7 &ndash; Obriga&ccedil;&otilde;es dos visitantes</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.1. Os visitantes se obrigam a realizar uma navega&ccedil;&atilde;o com retid&atilde;o &eacute;tica, sempre respeitando as condi&ccedil;&otilde;es que regem a utiliza&ccedil;&atilde;o do Portal.</p>
<p className='IPText'>&nbsp;praticados em seus nomes, ainda que seja por terceiro que tenha conhecimento desses dados.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.2. Ao fornecer dados e informa&ccedil;&otilde;es &agrave; Empresa, os visitantes se obrigam a faz&ecirc;-lo sempre com compromisso de veracidade e autenticidade, sob pena de aplica&ccedil;&atilde;o das penas da lei, de indenizar a quem causar dano e de ter a conta de acesso do presente Portal exclu&iacute;da ou suspensa.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>8 &ndash; Direitos autorais e propriedade intelectual do Portal</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.1. O uso comercial das express&otilde;es Empresa, como marca, nome empresarial ou nome de dom&iacute;nio, al&eacute;m dos conte&uacute;dos das telas do Portal, assim como os programas, banco de dados, redes, arquivos que permitem que o visitante acesse sua conta s&atilde;o de propriedade da Empresa e est&atilde;o protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.2. Ao acessar este Portal, os visitantes declaram que respeitar&atilde;o todos os direitos de propriedade intelectual e os decorrentes da prote&ccedil;&atilde;o de marcas, patentes e/ou desenhos industriais, depositados ou registrados em nome da Empresa, bem como de todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma, dispon&iacute;veis no Portal. O simples acesso ao Portal n&atilde;o confere a estes qualquer direito ao uso dos nomes, t&iacute;tulos, palavras, frases, marcas, patentes, obras liter&aacute;rias, art&iacute;sticas, literomusicais, imagens, dados e informa&ccedil;&otilde;es, dentre outras, que nele estejam ou estiveram dispon&iacute;veis.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.3. A reprodu&ccedil;&atilde;o de conte&uacute;dos descritos anteriormente est&aacute; proibida, salvo com pr&eacute;via autoriza&ccedil;&atilde;o por escrito da Empresa ou caso se destinem ao uso exclusivamente pessoal e sem que em nenhuma circunst&acirc;ncia os visitantes e usu&aacute;rios adquiram qualquer direito sobre os mesmos.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.4. &Eacute; permitido fazer somente o arquivo tempor&aacute;rio deste Portal, sendo vedada sua utiliza&ccedil;&atilde;o para finalidades comerciais, publicit&aacute;rias ou qualquer outra que contrarie a realidade para o qual foi concebido, conforme definido neste documento. Restam igualmente proibidas a reprodu&ccedil;&atilde;o, a distribui&ccedil;&atilde;o e a divulga&ccedil;&atilde;o, total ou parcial dos textos, figuras, gr&aacute;ficos, que comp&otilde;em o presente Portal, sem pr&eacute;via e expressa autoriza&ccedil;&atilde;o da Empresa, sendo permitida somente a impress&atilde;o de c&oacute;pias para uso e arquivo pessoal, sem que sejam separadas as partes que permitam dar o fiel e real entendimento de seu conte&uacute;do e objetivo.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.5. Os visitantes assumem toda e qualquer responsabilidade, de car&aacute;ter civil e/ou criminal, pela utiliza&ccedil;&atilde;o indevida das informa&ccedil;&otilde;es, textos, gr&aacute;ficos, marcas, obras, imagens, enfim, de todo e qualquer direito de propriedade intelectual ou industrial deste Portal.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.6. Qualquer outro tipo de utiliza&ccedil;&atilde;o de material autorizado, inclusive para fins editoriais, comerciais ou publicit&aacute;rios, s&oacute; poder&aacute; ser feito mediante pr&eacute;vio e expresso consentimento da Empresa. Os visitantes est&atilde;o cientes por meio deste item que o uso comercial n&atilde;o autorizado poder&aacute; incorrer em infra&ccedil;&otilde;es c&iacute;veis e criminais por est&aacute; infringindo a Lei dos Direitos Autorais.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.7. Qualquer reutiliza&ccedil;&atilde;o do material autorizado dever&aacute; ser objeto de uma nova autoriza&ccedil;&atilde;o da Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.8. A autoriza&ccedil;&atilde;o para utiliza&ccedil;&atilde;o do material solicitado n&atilde;o poder&aacute; ser transferida a terceiros, mesmo que vinculados ao sujeito autorizado por alguma raz&atilde;o.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.9. A utiliza&ccedil;&atilde;o do material n&atilde;o autoriza os visitantes a expor terceiros ao rid&iacute;culo, criar uma obra de car&aacute;ter ilegal, difamat&oacute;ria, obscena ou imoral, que possa violar a moral e os bons costumes, sob pena de arcar com as penalidades aplic&aacute;veis pela legisla&ccedil;&atilde;o vigente.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.10 Qualquer utiliza&ccedil;&atilde;o n&atilde;o contemplada na mencionada autoriza&ccedil;&atilde;o ser&aacute; considerada como uma viola&ccedil;&atilde;o dos direitos de autor e sujeita &agrave;s san&ccedil;&otilde;es cab&iacute;veis na Lei n. 9610, de 19 de fevereiro de 1998, que protege os direitos autorais no Brasil.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.11. A eventual retirada deste Portal, em decorr&ecirc;ncia de alguma reclama&ccedil;&atilde;o de qualquer an&uacute;ncio, artigo, v&iacute;deo, produto, servi&ccedil;o, not&iacute;cia ou fotografia aqui reproduzidos, dever&aacute; ser sempre compreendida como uma demostra&ccedil;&atilde;o de nossa inten&ccedil;&atilde;o de evitar dissabores com rela&ccedil;&atilde;o a este assunto e, jamais, como reconhecimento de qualquer infra&ccedil;&atilde;o pela Empresa a direito de terceiro.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.12. As fotos e imagens utilizadas neste Portal podem n&atilde;o refletir seu tamanho original ou situa&ccedil;&atilde;o atual do cen&aacute;rio reproduzido, sendo meramente ilustrativas.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.13. Salvo disposi&ccedil;&atilde;o espec&iacute;fica em contrato existente entre visitantes e a Empresa, os visitantes ret&ecirc;m os direitos autorais dos conte&uacute;dos que criarem ou que j&aacute; possu&iacute;rem; contudo, quando os enviam ao Portal, concedem uma licen&ccedil;a irrevog&aacute;vel, perp&eacute;tua, mundial, irrestrita, isenta de&nbsp;<em>royalties</em> e n&atilde;o exclusiva de reprodu&ccedil;&atilde;o, adapta&ccedil;&atilde;o, modifica&ccedil;&atilde;o, tradu&ccedil;&atilde;o, publica&ccedil;&atilde;o, distribui&ccedil;&atilde;o ou exibi&ccedil;&atilde;o no pr&oacute;prio Portal, possibilitando a melhor utiliza&ccedil;&atilde;o da ferramenta ou ilustra&ccedil;&atilde;o de algum produto ou servi&ccedil;o por ele oferecido.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>9 &ndash; Da loja virtual da Empresa</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.1 A Empresa disponibiliza em seu Portal um ambiente que permite a compra de produtos eletr&ocirc;nicos e de vestu&aacute;rio, dentre outros e intera&ccedil;&atilde;o de seus visitantes com poss&iacute;vel envio de conte&uacute;dos e realizando coment&aacute;rios de forma geral.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.2. A Empresa disponibiliza em seu&nbsp;<em>site&nbsp;</em>sua Pol&iacute;tica de Troca, Entrega e Devolu&ccedil;&atilde;o de Produtos, que estabelece as regras, hip&oacute;teses e condi&ccedil;&otilde;es para que essas opera&ccedil;&otilde;es sejam realizadas com sucesso e suas instru&ccedil;&otilde;es.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.2.1. Ao efetuar alguma compra no Portal da Empresa, o visitante declara ter pleno conhecimento da referida Pol&iacute;tica e manifesta sua concord&acirc;ncia com seus termos, comprometendo-se a cumprir com os ditames l&aacute; estabelecidos para a conclus&atilde;o do neg&oacute;cio e requisi&ccedil;&atilde;o de algum procedimento l&aacute; estabelecido.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.3. No ambiente do Portal, ser&aacute; considerado abuso e est&aacute; proibido o envio de conte&uacute;dos que configurem, a exemplo mas n&atilde;o se limitando:</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - ofensa &agrave; honra, imagem, reputa&ccedil;&atilde;o e dignidade de terceiros;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - pornografia, pedofilia e outras modalidades de satisfa&ccedil;&atilde;o sexual;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - &oacute;dio, racismo ou discrimina&ccedil;&atilde;o de qualquer natureza;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; -&nbsp;<em>bullying, stalking&nbsp;</em>ou qualquer outra esp&eacute;cie de constrangimento ilegal;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - manifesta viola&ccedil;&atilde;o a direito autoral ou direito de imagem;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - utiliza&ccedil;&atilde;o de marcas, s&iacute;mbolos, logomarcas ou emblemas de terceiros;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - instiga&ccedil;&atilde;o ou apologia &agrave; pr&aacute;tica de crimes, como tr&aacute;fico ou uso de entorpecentes, estupro, homic&iacute;dio, estelionato, dentre outros.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - atividade comercial, promocional ou que sugira o exerc&iacute;cio atividade econ&ocirc;mica;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - atos il&iacute;citos em geral;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - condutas contr&aacute;rias &agrave; Ordem P&uacute;blica e aos Bons Costumes.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.3.1. Se detectadas quaisquer dessas pr&aacute;ticas, a Empresa pode, a seu exclusivo crit&eacute;rio excluir os conte&uacute;dos, n&atilde;o public&aacute;-los ou modific&aacute;-los de forma a cessar a exibi&ccedil;&atilde;o do conte&uacute;do vedado, a qualquer tempo, sem a necessidade de aviso pr&eacute;vio e sem o dever de indeniza&ccedil;&atilde;o pela conduta eleita como mais adequada.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.4 Todas as vendas s&atilde;o realizadas exclusivamente por meio do nosso site.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>10 &ndash; Atendimento do Portal Empresa</strong></p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>10.1 A Empresa disponibiliza os canais para comunica&ccedil;&atilde;o direta com seus visitantes &nbsp;em seu Portal, pelos&nbsp;<em>links&nbsp;</em>de contato, al&eacute;m dos seguintes meios:</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; - pelo telefone&nbsp;<strong><em>+55 (11) 995996359</em></strong>, que atende de segunda a sexta, das 08:00 &agrave;s 18:00 horas</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- por endere&ccedil;o do&nbsp;<em>e-mail:</em> <em>contato@quickshopbr.com.br</em></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>11 &ndash; Modifica&ccedil;&otilde;es destes Termos e Condi&ccedil;&otilde;es</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 11.1 O presente Termos de Uso e Condi&ccedil;&otilde;es de Navega&ccedil;&atilde;o est&atilde;o sujeitos a constante melhoria e aprimoramento. Assim, a Empresa se reserva ao direito de modificar a qualquer momento, de forma unilateral, o presente documento e sua Pol&iacute;tica de Privacidade, informando via e-mail do visitante na Plataforma as modifica&ccedil;&otilde;es realizadas.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 11.2 Ao navegar por este Portal, o visitante aceita guiar-se pelos Termos e Condi&ccedil;&otilde;es de Uso do Portal da Empresa e pela Pol&iacute;tica de Privacidade que se encontram vigentes na data e, portanto, deve verificar os mesmos cada vez que visitar este Portal.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 11.3 A Empresa recomenda que o visitante leia com aten&ccedil;&atilde;o e mantenha consigo uma c&oacute;pia destes Termos de Uso para uma refer&ecirc;ncia futura. Qualquer pergunta ou coment&aacute;rio com rela&ccedil;&atilde;o a estes Termos de Uso podem ser enviados para a Empresa atrav&eacute;s dos canais da nossa Central de Atendimento.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>12 &ndash; Disposi&ccedil;&otilde;es gerais</strong></p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 12.1 A toler&acirc;ncia do eventual descumprimento de quaisquer das cl&aacute;usulas e condi&ccedil;&otilde;es do presente instrumento n&atilde;o constituir&aacute; nova&ccedil;&atilde;o das obriga&ccedil;&otilde;es aqui estipuladas e tampouco impedir&aacute; ou inibir&aacute; a exigibilidade das mesmas a qualquer tempo.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 12.2. Este Portal tem como base o hor&aacute;rio oficial de Bras&iacute;lia.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>13 &ndash; Lei aplic&aacute;vel e jurisdi&ccedil;&atilde;o</strong></p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>13.1 Os termos e Condi&ccedil;&otilde;es de uso aqui descritos s&atilde;o interpretados segundo a legisla&ccedil;&atilde;o brasileira, no idioma portugu&ecirc;s, sendo eleito o Foro da Comarca de S&atilde;o Paulo, Estado de S&atilde;o Paulo para dirimir qualquer lit&iacute;gio, quest&atilde;o ou d&uacute;vida superveniente, com expressa ren&uacute;ncia de qualquer outro, por mais privilegiado que seja.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Atualiza&ccedil;&atilde;o: 18 de outubro de 2021</strong>.</p>
<p className='IPText'><em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>quickshopbr.</strong></em></p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'><strong>Pol&iacute;tica de compra dos produtos.</strong></p>
<p className='IPText'><strong>&nbsp;</strong></p>
<p className='IPText'><strong>1 &ndash; Procedimento</strong>.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.1. O visitante pode comprar produtos eletr&ocirc;nicos e de vestu&aacute;rio, dentre outros da Empresa ap&oacute;s realizar e preencher com informa&ccedil;&otilde;es verdadeiras, atualizadas e precisas os campos de dados e e-mail do checkout referente a sua pr&oacute;pria pessoa no Site, bem como cumprir com todas as disposi&ccedil;&otilde;es dos Termos de Uso e realizar transa&ccedil;&otilde;es em nome pr&oacute;prio e nunca em nome de terceiros, e, por fim, ter a capacidade jur&iacute;dica para celebrar acordos e transmitir t&iacute;tulos e interesses de qualquer produto que possa ser comprado da Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.2. O visitante ap&oacute;s escolher qual ser&aacute;(&atilde;o) o(s) produtos adquirido(s) e o meio de pagamento utilizado, far&aacute; o preenchimento de seus dados e email no Site da Empresa conforme ser&aacute; analisado em se&ccedil;&atilde;o pr&oacute;pria abaixo descrita.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.3. Ap&oacute;s escolhido o(s) produtos e, ato cont&iacute;nuo, ser transferido o valor do produto &agrave; conta da Empresa, a confirma&ccedil;&atilde;o do pedido ser&aacute; realizada.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.4. Nenhum produto ser&aacute; enviado ao visitante antes da confirma&ccedil;&atilde;o do pedido que se dar&aacute; por meio da constata&ccedil;&atilde;o de que o valor do produto foi de fato debitado na conta da Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.5. Em caso de pagamento &agrave; vista, a confirma&ccedil;&atilde;o do pedido ocorre ap&oacute;s debitado o pagamento na conta da Empresa. Em caso de pagamento via cart&atilde;o de cr&eacute;dito, a confirma&ccedil;&atilde;o ocorre ap&oacute;s a autoriza&ccedil;&atilde;o da processadora de pagamentos de que o valor se encontra dispon&iacute;vel. Em ambos os casos, a Empresa enviar&aacute; um e-mail com base nos dados fornecidos pelo visitante confirmando o envio do produto.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.6. A confirma&ccedil;&atilde;o do pedido efetuado formaliza a exist&ecirc;ncia de um contrato entre as duas partes (Empresa e visitante), consoante estes Termos de Uso.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.7. N&atilde;o ser&aacute; admitido outras estipula&ccedil;&otilde;es contratuais por parte do visitante, servindo este presente termo de uso como o &uacute;nico contrato v&aacute;lido entre as partes.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.8. A Empresa tem o direito de rescindir o contrato nas hip&oacute;teses da ocorr&ecirc;ncia de erros ou imprecis&otilde;es claramente identificados em rela&ccedil;&atilde;o aos produtos que constam no Site.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.9. A Empresa se compromete a entregar o produto no endere&ccedil;o de entrega preenchido pelo visitante. Em caso deste fornecer dado ou informa&ccedil;&atilde;o incorreta ou imprecisa acerca de seu endere&ccedil;o, qualquer custo decorrente do envio do produto a lugar distinto do preenchido no checkout da Empresa ser&aacute; de responsabilidade do visitante.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.10. Se for constatado que houve erro ou imprecis&atilde;o no que se refere ao pre&ccedil;o dos produtos anunciados que tenham sido encomendados pelo visitante, a Empresa entrar&aacute; em contato com aquele por meio do e-mail informado, a fim de esclarecer ao visitante acerca do pre&ccedil;o correto do produto, bem como para confirmar a continuidade do pedido com o pre&ccedil;o alterado ou o cancelamento do pedido.</p>
<p className='IPText'><strong>&nbsp;</strong></p>
<ol>
    <li><strong>Transporte</strong></li>
</ol>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.1 A Empresa n&atilde;o se responsabiliza por nenhum problema como roubo, dano, extravio ao produto comprado pelo visitante durante o transporte do mesmo, eis que tal responsabilidade &eacute; inerente &agrave; transportadora, n&atilde;o se configurando o transporte como risco inerente &agrave; atividade empresarial.</p>
<p className='IPText'>&nbsp;</p>
<ol>
    <li><strong>Pagamento</strong></li>
</ol>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.1 Os pagamentos ser&atilde;o realizados por Pix, Boleto Banc&aacute;rio e Cart&atilde;o de Cr&eacute;dito com parcelamento em at&eacute; 6 (seis) vezes sem juros e demais parcelas com juros a ser definido pela operadora do processamento de pagamento.</p>
<p className='IPText'><strong>&nbsp;</strong></p>
<ol>
    <li><strong>Tempo de entrega</strong></li>
</ol>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.1 O tempo de entrega ap&oacute;s a confirma&ccedil;&atilde;o do pedido &eacute; de no m&iacute;nimo 35 (trinta e cinco dias) &uacute;teis a contar do envio do c&oacute;digo de rastreio do produto pela Empresa ao visitante, podendo tal per&iacute;odo ser prorrogado por motivos alheios a vontade da Empresa, tais como mas n&atilde;o se limitando: greve dos correios, ou problemas durante o transporte que n&atilde;o tenham como ser atribu&iacute;dos a Empresa, ou ainda localiza&ccedil;&atilde;o geogr&aacute;fica de dif&iacute;cil acesso.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.2. Al&eacute;m do tempo de entrega exposto no item 4.1, a Empresa tamb&eacute;m possui o pedido priorit&aacute;rio, no qual o visitante paga um adicional de R$ 9,90 (nove reais e noventa centavos) sobre o produto e, como consequ&ecirc;ncia disso, o recebe com at&eacute; 4 (quatro) dias de anteced&ecirc;ncia do prazo normal descrito no item 4.1.</p>
<p className='IPText'>&nbsp;</p>
<ol>
    <li><strong>Estoque</strong></li>
</ol>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.1. A Empresa se compromete a informar se possui ou n&atilde;o em estoque a mercadoria escolhida pelo visitante para compra. Caso n&atilde;o tenha a mercadoria dispon&iacute;vel para entrega no momento da compra, a Empresa se compromete a reembolsar ao visitante o mesmo valor que tenha sido debitado em seu cart&atilde;o de cr&eacute;dito, boleto ou pix e enviar&aacute; um e-mail para o endere&ccedil;o de correio eletr&ocirc;nico fornecido pelo visitante no formul&aacute;rio do pedido.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.2. A Empresa n&atilde;o ser&aacute; obrigada a oferecer qualquer compensa&ccedil;&atilde;o adicional em raz&atilde;o da indisponibilidade do produto buscado.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.4. A Empresa procura apresentar aos visitantes o melhor pre&ccedil;o poss&iacute;vel por meio da realiza&ccedil;&atilde;o de ofertas especiais, sujeitas a estoques limitados.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.5. O Site &eacute; atualizado constantemente a fim de reduzir eventual aquisi&ccedil;&atilde;o de produtos indispon&iacute;veis em estoque na ocasi&atilde;o da compra, no entanto, durante o per&iacute;odo de ofertas especiais, pode haver eventual demora no processo de atualiza&ccedil;&atilde;o do Site em raz&atilde;o do intenso fluxo de opera&ccedil;&otilde;es.</p>
<p className='IPText'>&nbsp;</p>
<ol>
    <li><strong>Garantia</strong></li>
</ol>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.1 Todos os produtos comercializados pela Empresa s&atilde;o testados por nossos profissionais capacitados e vendidos em plenas condi&ccedil;&otilde;es de funcionamento.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.2 Todos os produtos s&atilde;o catalogados e o respectivo estado de conserva&ccedil;&atilde;o &eacute; especificado na listagem, de modo que cada produto est&aacute; relacionado com a sua descri&ccedil;&atilde;o.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.3 A Empresa fornece uma garantia contra defeitos para os produtos pelo prazo de 90 (noventa) dias, a contar da data de recebimento para os produtos dur&aacute;veis e 30 (trinta dias) para os produtos n&atilde;o dur&aacute;veis conforme disposto no C&oacute;digo de Defesa do Consumidor.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.4 A garantia tamb&eacute;m somente cobrir&aacute; defeitos de fabrica&ccedil;&atilde;o. N&atilde;o ser&aacute; poss&iacute;vel a troca caso o produto apresente problema ou defeito ap&oacute;s decorridos os 90 (noventa) ou 30 (trinta) dias a depender da natureza do produto, se dur&aacute;vel ou n&atilde;o dur&aacute;vel. A Empresa recomenda a conserva&ccedil;&atilde;o da nota fiscal &nbsp;eletr&ocirc;nica da compra do produto.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.6 Para efeitos de troca n&atilde;o s&atilde;o considerados defeitos de fabrica&ccedil;&atilde;o:</p>
<ol>
    <li>a) Defeitos ou danos resultantes do mau uso, entre outros problemas similares;</li>
    <li>b) Defeitos ou danos decorrentes de testes, instala&ccedil;&atilde;o, altera&ccedil;&atilde;o, modifica&ccedil;&atilde;o de qualquer esp&eacute;cie em nossos produtos, bem como o conserto realizado por outra Empresa/prestador de servi&ccedil;o dentro de 90 (noventa) ou 30 (trinta) dias a depender da natureza do produto;</li>
    <li>c) Quebra, danos ou irregularidades (v&iacute;cios) aparentes, de f&aacute;cil e imediata observa&ccedil;&atilde;o no produto que n&atilde;o foram constatados dentro do prazo de 7 (sete) dias ap&oacute;s o recebimento do produto;</li>
    <li>d) Produtos que tenham tido o lacre de seguran&ccedil;a rompido, n&uacute;mero de s&eacute;rie removido, adulterado ou tornado ileg&iacute;vel;</li>
    <li>e) Danos por fluidos, a exemplo mas n&atilde;o se limitando a danos causados por contato com &aacute;gua, fogo e subst&acirc;ncias qu&iacute;micas que possam danificar o produto;</li>
    <li>g) Roubo ou furto do produto.</li>
    <li>h) O uso de acess&oacute;rios n&atilde;o originais.</li>
    <li>i) Descargas el&eacute;tricas.</li>
</ol>
<p className='IPText'>&nbsp;</p>
<ol>
    <li><strong>Reembolsos e devolu&ccedil;&otilde;es</strong></li>
</ol>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>7.1. A Empresa fornece detalhadamente todas as especifica&ccedil;&otilde;es (informa&ccedil;&otilde;es) sobre os tamanhos, cores e demais caracter&iacute;sticas dos produtos.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.2. O visitante somente poder&aacute; pedir reembolso ou desistir da compra (direito de arrependimento) at&eacute; o prazo de 7 (sete) dias a partir da data do recebimento do produto (que ser&aacute; conferido por meio da data de envio aos correios), ultrapassado este per&iacute;odo a EMPRESA n&atilde;o far&aacute; qualquer esp&eacute;cie de reembolso.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.3. O pedido dever&aacute; ser realizado por meio de qualquer canal da central de atendimento da Empresa, seja e-mail telefone ou outro mecanismo de comunica&ccedil;&atilde;o v&aacute;lido com exce&ccedil;&atilde;o das redes sociais da Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.4. O produto deve estar no estado de conserva&ccedil;&atilde;o original em que foi enviado e fechado. A Empresa devolver&aacute; o valor correspondente ao pre&ccedil;o de aquisi&ccedil;&atilde;o.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.5. Na hip&oacute;tese de devolu&ccedil;&atilde;o de algum produto, os custos do transporte ser&atilde;o suportados pela Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.6. Em caso de reembolso, o visitante dever&aacute; enviar o produto por meio do material adequado indicado pela Empresa durante a comunica&ccedil;&atilde;o, a exemplo mas n&atilde;o se limitando a pl&aacute;stico bolha ou demais materiais.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.7. A autenticidade dos produtos comercializados &eacute; controlada pela Empresa por meio do nosso controle de qualidade, o qual ser&aacute; verificado na ocasi&atilde;o da devolu&ccedil;&atilde;o a fim de garantir que o produto retornado &eacute; o mesmo e com as mesmas condi&ccedil;&otilde;es enviadas pela Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.8 A Empresa informa que disp&otilde;e de 15 (quinze) dias &uacute;teis para cadastrar a devolu&ccedil;&atilde;o dos produtos, por meio do formul&aacute;rio a ser preenchido pelo visitante e processar o devido reembolso.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.9. O reembolso somente ser&aacute; realizado ap&oacute;s o produto retornar a sede da Empresa e ser submetido a nova avalia&ccedil;&atilde;o.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.10. Caso seja constatado que o produto retornado n&atilde;o condiz com as especifica&ccedil;&otilde;es t&eacute;cnicas e estado de conserva&ccedil;&atilde;o que saiu da EMPRESA, fica esta &uacute;ltima desobrigada de qualquer tipo/esp&eacute;cie de reembolso.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.11 Caso aconte&ccedil;a o previsto no item 7.10 supramencionado o produto ficar&aacute; disponibilizado na sede da EMPRESA para que o visitante o retire, desde que todos os custos referentes ao transporte sejam arcados por ele.</p>
<p className='IPText'>&nbsp;</p>
<ol>
    <li><strong>Troca</strong></li>
</ol>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>8.1. A Empresa fornece detalhadamente todas as especifica&ccedil;&otilde;es (informa&ccedil;&otilde;es) sobre os tamanhos, cores e demais caracter&iacute;sticas dos produtos.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.2. Para que seja realizada a troca &eacute; necess&aacute;rio que o visitante envie &agrave; Empresa imagem e, ou, v&iacute;deo mostrando o defeito do produto.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.3. Caso o visitante venha a produzir v&iacute;deo e, ou, imagem adulterado e seja constatado quando do recebimento do produto na sede da Empresa que o mesmo se apresenta em perfeitas condi&ccedil;&otilde;es de estado e uso, a Empresa n&atilde;o realizar&aacute; o retorno do mesmo ao visitante, sendo de responsabilidade deste o custo com o envio do produto.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.4. Caso o visitante queria realizar a troca do produto e nem realizar o reembolso poder&aacute; reverte o valor do cr&eacute;dito em vale-compra, conforme elencado no item 8.5.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.5. Caso o visitante n&atilde;o queira trocar o produto por outro modelo, poder&aacute; reverter o pagamento em vale-compra, no qual poder&aacute; em outra oportunidade efetuar compra.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.6. O direito de troca somente poder&aacute; ser exercido dentro do prazo de 7 (sete) dias ap&oacute;s o recebimento do produto na resid&ecirc;ncia do visitante.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.7. Em caso do visitante solicitar a contesta&ccedil;&atilde;o da compra junto ao cart&atilde;o de cr&eacute;dito, dever&aacute;, obrigatoriamente comunicar a Empresa o motivo de tal ato, sob pena de responder extra e judicialmente por perdas e danos e lucros cessantes al&eacute;m de honor&aacute;rios advocat&iacute;cios.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.8. Caso o visitante n&atilde;o queira nenhuma das hip&oacute;teses de troca acima transcritas, poder&aacute; receber o estorno desde que envie o produto &agrave; Empresa e, ap&oacute;s, o produto ser submetido a avalia&ccedil;&atilde;o nos moldes do item 7 e subitens do presente termo.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.9. Ressalta-se que o per&iacute;odo de troca corresponde aquele descrito no item 7.2 do presente instrumento.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.10. O prazo para envio do produto trocado &eacute; aquele estabelecido no item 4 do presente termo de uso a contar da avalia&ccedil;&atilde;o feita quando do retorno do produto a sede da Empresa.</p>
<p className='IPText'>&nbsp;</p>
<p >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; E assim, por estarem justas e acertadas, as partes (Visitante e Empresa) reconhecem a validade do presente termos de uso, nada podendo reclamar seja extra ou judicialmente a qualquer t&iacute;tulo. Reconhecendo como foro a comarca de S&atilde;o Paulo, Estado de S&atilde;o Paulo para ser dirimido qualquer conflito judicial.</p>

            </div>
      
            <Foot/>
          </div>
        );
    }
    }


export default InfoTS;