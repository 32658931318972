import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoAdd, IoCheckmarkCircle, IoChevronBack, IoCheckmark} from 'react-icons/io5'
import { Debug, DeleteOrder, FetchAce, FetchMochi, FetchMost, FetchOrders, Login, logout, saveProd, UpdateRastreio, UpdateStatus, VerifyUser } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class AdminMaster extends Component {

  state = {
    user:'',
    senha:'',
    isAuth:false,
    pedidos:[],
    choosenOrder:'',
    produtos:[],
    statusI:'',
    rastreioI:'',
    choosenStatus:'',
    choosenRastreio:'',
    choosenIdx:'',
    orderSearch:'',
    addMode:false,
    formSample:'',
    added:false,
    distribuicao:'afiliado',
    nomeProduto:'',
    precoProduto:'',
    precoPromoProduto:'',
    disponivelProduto:true,
    promoProduto:false,
    categoria:'',
    tags:'',
    avaliacoes:'',
    caracteristicas:'',
    imagens:'',
    informacoes:'',
    parametros:'',
    variacoes:'',
    linkAfiliado:''


  }

  componentDidMount = () => {
    VerifyUser().then(res=>this.setState({isAuth:res}))
    // VerifyUser().then(res=>console.log(res))
    FetchOrders().then(res=>this.setState({pedidos:res}))
  }


    render(){

      let pedido = this.state.choosenOrder

      let produtos = this.state.choosenOrder.produtos

      let endereco = this.state.choosenOrder != '' ? pedido.endereco : ''

      let produtosList;
      
      if(produtos != undefined) {
        produtosList = produtos.prods.map((i,idx)=>{
          return (
            <p className='InfoTextO' key={idx}>{i} ({produtos.qtds[idx]}x) - {produtos.variacoes[idx]}</p>
            )
          })
      }

      let pedidosList;
      
      if(this.state.pedidos != undefined) {

        pedidosList= this.state.pedidos.map((i,idx)=>{

          if(this.state.orderSearch.length < 3){
            return (
              <div key={idx} className='OrderDiv' style={{borderBottomWidth:idx===this.state.pedidos.length-1 ? 0 :1}}>
                <button onClick={()=>{
                  this.setState({choosenOrder:i, addMode:false ,choosenStatus:i.status, choosenRastreio:i.rastreio, choosenIdx:idx})
                }} className='OrderBtn'>
                  <p className='OrderText' >{i.ID} </p>
                  <p className='OrderText' >{i.status} </p>
                </button>
              </div>
              )
              
            } else {
              if(i.ID.includes(this.state.orderSearch)){
                
                return (
                  <div key={idx} className='OrderDiv' style={{borderBottomWidth:idx===this.state.pedidos.length-1 ? 0 :1}}>
                    <button onClick={()=>{
                      this.setState({choosenOrder:i, addMode:false ,choosenStatus:i.status, choosenRastreio:i.rastreio, choosenIdx:idx})
                    }} className='OrderBtn'>
                      <p className='OrderText' >{i.ID} </p>
                      <p className='OrderText' >{i.status} </p>
                    </button>
                  </div>
                  )
                  
            }
          }

          })
      }

      let sample;

      if(this.state.formSample === 'aval'){
        sample = (
          <p>
            [
              {JSON.stringify({
                comentario:'Algum comentário',
                data:'20/07/2022',
                nome: 'Francisco Gomes',
                nota: 4.6,
                foto: 'https://linkdafoto.com/200/200'
            })},
            {JSON.stringify({
                comentario:'Algum comentário',
                data:'20/07/2022',
                nome: 'Francisco Gomes',
                nota: 4.6,
                foto: 'https://linkdafoto.com/200/200'
            })}
            ]
          </p>
        )
      } else if(this.state.formSample === 'car'){
        sample = (
          <p>Super útil, Confortável, Fácil de usar, Leve, Bateria de longa duração, Rápido e prático</p>
        )

      } else if(this.state.formSample === 'imgs'){
        sample = (
          <p>https://linkdafoto.com/200/400, https://linkdafoto.com/700/500, https://linkdafoto.com/150/600, https://linkdafoto.com/300/400</p>
        )

      } else if(this.state.formSample === 'info'){
        sample = (
          <p>
            [
              {JSON.stringify({
                desc:'Descrição sobre a informação do produto',
                img:'https://linkdafoto.com/200/400',
                title: 'Título da informação!',
            })},
            {JSON.stringify({
                desc:'Descrição sobre a informação do produto',
                img:'https://linkdafoto.com/200/400',
                title: 'Título da informação!',
            })}
            ]
          </p>
        )

      } else if(this.state.formSample === 'par'){
        sample = (
          <p>
              {JSON.stringify({
                altura:'15cm',
                largura:'10cm',
                profundidade: '5cm',
                peso: '1kg',
                voltagem: '220v',
            })}
          </p>
        )

      } else if(this.state.formSample === 'var'){
        sample = (
          <p>
              {JSON.stringify({
                1:['preto','branco'],
                2:['220v','110v']
            })}
          </p>
        )

      }

      let rightContainer;

      if(!this.state.addMode) {

        rightContainer = (

          <div className='OrderContainerI' >
            <div>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'ID:'}</p>
              <p className='InfoTextO'>{pedido.ID}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Data:'}</p>
              <p className='InfoTextO'>{pedido.data}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Nome:'}</p>
              <p className='InfoTextO'>{pedido.userName}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Email:'}</p>
              <p className='InfoTextO'>{pedido.userEmail}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Celular:'}</p>
              <p className='InfoTextO'>{pedido.userPhone}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Forma de pagamento:'}</p>
              <p className='InfoTextO'>{pedido.cdc}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Frete:'}</p>
              <p className='InfoTextO'>{pedido.frete}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Código de rastreio:'}</p>
              <p className='InfoTextO'>{this.state.choosenRastreio}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Status:'}</p>
              <p className='InfoTextO'>{this.state.choosenStatus}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'CPF:'}</p>
              <p className='InfoTextO'>{pedido.userDoc}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Valor total:'}</p>
              <p className='InfoTextO'>{pedido.valorTotal > 0 ? `R$${(pedido.valorTotal/100).toFixed(2).replace('.',',')}` : ''}</p>
            </div>
            <div>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Produtos:'}</p>
              {produtosList}
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Localidade:'}</p>
              <p className='InfoTextO'>  {pedido != '' ?`${endereco.city} - ${endereco.state}`: ''}</p>
              <p className='InfoTitleO'>{this.state.choosenOrder === '' ? '' : 'Endereço:'}</p>
              <p className='InfoTextO'>  {pedido != '' ?`${endereco.bairro}, ${endereco.endereco}, ${endereco.numero}, ${endereco.complemento}`: ''}</p>
            </div>
          </div>

        )
      } else {
        rightContainer = (
          <div className='OrderContainerI' >
            <div style={{width:'50%'}}> 
              <div style={{marginRight:10}}>
                  <p className='InfoTitleO'>Distribuição:</p>
                  <select className='AdmInput' value={this.state.distribuicao} onChange={i=>this.setState({distribuicao:i.target.value})} name="destribuicao">
                    <option value="afiliado">Afiliado</option>
                    <option value="exclusivo">Exclusivo</option>
                  </select>              
                </div>
              <p className='InfoTitleO'>Nome do produto:</p>
              <input className='AdmInput' value={this.state.nomeProduto} onChange={i=>this.setState({nomeProduto:i.target.value})} placeholder='Ex: Furadeira 3000'/>
              <p className='InfoTitleO'>Preço:</p>
              <input className='AdmInput' value={this.state.precoProduto} onChange={i=>this.setState({precoProduto:i.target.value})} placeholder='Ex: R$47,00'/>
              <p className='InfoTitleO'>Preço promocional:</p>
              <input className='AdmInput' value={this.state.precoPromoProduto} onChange={i=>this.setState({precoPromoProduto:i.target.value})} placeholder='Ex: R$24,70'/>
              <div style={{display:'flex', justifyContent:'center'}}>
                <div style={{marginRight:10}}>
                  <p className='InfoTitleO'>Disponível:</p>
                  <select className='AdmInput' value={this.state.disponivelProduto} onChange={i=>this.setState({disponivelProduto:i.target.value})} name="disponivel">
                    <option value="true">Disponível</option>
                    <option value="false">Indisponível</option>
                  </select>              
                </div>
                <div>
                  <p className='InfoTitleO'>Promoção:</p>
                  <select className='AdmInput' value={this.state.promoProduto} onChange={i=>this.setState({promoProduto:i.target.value})} name="promocao">
                    <option value="false">Não</option>
                    <option value="true">Sim</option>
                  </select>              
                </div>
              </div>
              <p className='InfoTitleO'>Categoria:</p>
              <input className='AdmInput' value={this.state.categoria} onChange={i=>this.setState({categoria:i.target.value})} placeholder='Ex: Pets'/>
              <p className='InfoTitleO'>Tags:</p>
              <input className='AdmInput' value={this.state.tags} onChange={i=>this.setState({tags:i.target.value})} placeholder='Ex: Pets, Práticidade, Conforto'/>
              <p style={{display:this.state.distribuicao === 'afiliado' ? 'block' : 'none'}} className='InfoTitleO'>Link afiliado:</p>
              <input style={{display:this.state.distribuicao === 'afiliado' ? 'inline' : 'none'}} className='AdmInput' value={this.state.linkAfiliado} onChange={i=>this.setState({linkAfiliado:i.target.value})} placeholder='Ex: https://linkafiliado.com/sajdkadhaiaqwiq'/>              
              <div>
                <p>Exemplo:</p>
                {sample}
              </div>
            </div>
            <div>
              <p className='InfoTitleO'>Avaliações:</p>
              <textarea onClick={()=>this.setState({formSample:'aval'})} style={{width:400, height:300}} className='AdmInput' value={this.state.avaliacoes} onChange={i=>this.setState({avaliacoes:i.target.value})} placeholder='lalala'/>
              <p className='InfoTitleO'>Características:</p>
              <textarea onClick={()=>this.setState({formSample:'car'})} style={{width:400, height:300}} className='AdmInput' value={this.state.caracteristicas} onChange={i=>this.setState({caracteristicas:i.target.value})} placeholder='lalala'/>
              <p className='InfoTitleO'>Imagens:</p>
              <textarea onClick={()=>this.setState({formSample:'imgs'})} style={{width:400, height:300}} className='AdmInput' value={this.state.imagens} onChange={i=>this.setState({imagens:i.target.value})} placeholder='lalala'/>
              <p className='InfoTitleO'>Informações:</p>
              <textarea onClick={()=>this.setState({formSample:'info'})} style={{width:400, height:300}} className='AdmInput' value={this.state.informacoes} onChange={i=>this.setState({informacoes:i.target.value})} placeholder='lalala'/>
              <p className='InfoTitleO'>Parâmetros:</p>
              <textarea onClick={()=>this.setState({formSample:'par'})} style={{width:400, height:300}} className='AdmInput' value={this.state.parametros} onChange={i=>this.setState({parametros:i.target.value})} placeholder='lalala'/>
              <p className='InfoTitleO'>Variações:</p>
              <textarea onClick={()=>this.setState({formSample:'var'})} style={{width:400, height:300}} className='AdmInput' value={this.state.variacoes} onChange={i=>this.setState({variacoes:i.target.value})} placeholder='lalala'/>
            </div>
            <button onClick={()=>saveProd(this.state.distribuicao, this.state.nomeProduto, this.state.precoProduto, this.state.precoPromoProduto, this.state.disponivelProduto, this.state.promoProduto, this.state.categoria, this.state.tags, this.state.avaliacoes, this.state.caracteristicas, this.state.imagens, this.state.informacoes, this.state.parametros, this.state.variacoes, this.state.linkAfiliado)
              .then(res=>{
                if(res){
                  this.setState({
                    added:true,
                    nomeProduto:'',
                    precoProduto:'',
                    precoPromoProduto:'',
                    disponivelProduto:true,
                    promoProduto:false,
                    categoria:'',
                    tags:'',
                    avaliacoes:'',
                    caracteristicas:'',
                    imagens:'',
                    informacoes:'',
                    parametros:'',
                    variacoes:'',
                    linkAfiliado:''
                
                  })
                  setTimeout(() => {
                    this.setState({added:false})
                  }, 8000);
                }
            })} className='AdminSaveProd'>
              <p>Adicionar produto</p>
              <IoCheckmark color={this.state.added ? 'green' : 'gray'} size={20} style={{marginLeft:10}}/>
            </button>

          </div>
        )
      }
          
      if(!this.state.isAuth){

        return (
          <div className="App">
            
            <div style={{height:window.innerHeight, display:'flex', flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
              <div style={{backgroundColor:'#a8c9ef67', padding:40, borderRadius:10, height:window.innerHeight/3}}>
                <div style={{padding:5, borderStyle:'solid', borderWidth:0, borderBottomWidth:1, borderColor:'white'}}>
                  <p style={{fontWeight:'bold', fontSize:'large', margin:0, color:"white"}}>LOGIN</p>
                </div>
                <p style={{marginBottom:5, color:"white"}}>Usuário:</p>
                <input style={{outline:'none', borderColor:'gainsboro', padding:5, borderStyle:'solid', borderWidth:1, borderRadius:5, color:"gray"}} value={this.state.user} onChange={i=>this.setState({user:i.target.value})}/>
                <p style={{marginBottom:5, color:"white"}}>Senha:</p>
                <input type={'password'} style={{outline:'none', borderColor:'gainsboro', padding:5, borderStyle:'solid', borderWidth:1, borderRadius:5, color:"gray"}} value={this.state.senha} onChange={i=>this.setState({senha:i.target.value})}/>
                <div>
                  <button onClick={()=>{
                    Login(this.state.user,this.state.senha).then(res=>{
                      window.localStorage.setItem('userID',JSON.stringify(res))
                      VerifyUser().then(res=>{
                        this.setState({isAuth:res})
                        window.location.reload()
                      })
                    })
                  }} style={{backgroundColor:"transparent", borderStyle:'none'}}>
                    <IoCheckmarkCircle className='LoginIcon'/>
                  </button>
                </div>
              </div>
            </div>
  
  
          </div>
        );
        
      } else {
        
        return (
          <div className="App">

            <div className='PainelControle' style={{display:this.state.addMode ? 'none' : 'block'}}>
              <p style={{color:'white', fontWeight:'bold', fontSize:'large'}}>Status</p>
              <div style={{display:'flex', alignItems:'center'}}>
                <button style={{ backgroundColor:"transparent", borderStyle:'none', color:'#990000', cursor:"pointer",opacity:0, pointerEvents:'none'}}>
                  <IoCheckmarkCircle color='white' size={25} />
                </button>
                <input style={{color:'gray' ,outline:'none',borderColor:'gainsboro', borderRadius:5, borderStyle:'solid', borderWidth:1}} value={this.state.statusI} onChange={i=>this.setState({statusI:i.target.value})} />
                <button onClick={()=>{UpdateStatus(this.state.statusI, this.state.choosenOrder.ID, pedido.userName, pedido.userEmail, pedido.rastreio ).then(FetchOrders().then(res=>this.setState({pedidos:res,choosenStatus:this.state.statusI})).then(()=>{
                  
                  // if(this.state.statusI === 'Despachado')

                  setTimeout(() => {
                    this.setState({statusI:''})
                  }, 100);
                }))
                   }} style={{ backgroundColor:"transparent", borderStyle:'none', color:'#990000', cursor:"pointer"}}>
                  <IoCheckmarkCircle color='white' size={25} />
                </button>
              </div>
              <p style={{color:'white', fontWeight:'bold', fontSize:'large'}}>Rastreio</p>
              <div style={{display:'flex', alignItems:'center'}}>
                <button style={{ backgroundColor:"transparent", borderStyle:'none', color:'#990000', cursor:"pointer",opacity:0, pointerEvents:'none'}}>
                  <IoCheckmarkCircle color='white' size={25} />
                </button>
                <input style={{color:'gray' ,outline:'none',borderColor:'gainsboro', borderRadius:5, borderStyle:'solid', borderWidth:1}} value={this.state.rastreioI} onChange={i=>this.setState({rastreioI:i.target.value})} />
                <button onClick={()=>{UpdateRastreio(this.state.rastreioI, this.state.choosenOrder.ID,pedido.userName, pedido.userEmail, pedido.status).then(FetchOrders().then(res=>this.setState({pedidos:res,choosenRastreio:this.state.rastreioI})).then(()=>{
                  setTimeout(() => {
                    this.setState({rastreioI:''})
                  }, 100);
                }))
                   }} style={{ backgroundColor:"transparent", borderStyle:'none', color:'#990000', cursor:"pointer"}}>
                  <IoCheckmarkCircle color='white' size={25} />
                </button>
              </div>
              <button onClick={()=>{
                DeleteOrder(this.state.choosenOrder.ID).then(FetchOrders().then(res=>{
                let array = this.state.pedidos

                array.splice(this.state.choosenIdx,1)

                this.setState({pedidos:array})

              }).then(res=>{

                this.setState({choosenOrder:'', choosenIdx:'', choosenRastreio:'', choosenStatus:''})

              }))
                
                 this.setState({statusI:'', rastreioI:''})
                 }} style={{display:'block', marginLeft:'auto ', marginRight:'auto', marginTop:20, backgroundColor:"transparent", borderStyle:'none', color:'#990000', cursor:"pointer"}}>
                <p>Excluir</p>
              </button>
            </div>
            
            <div style={{height:window.innerHeight, display:'flex', flexDirection:"column", justifyContent:'center', alignItems:"center"}}>

              <div style={{display:'flex', width:'95%', justifyContent:'space-between', marginBottom:10}}>
                <IoChevronBack onClick={()=>logout().then(window.location.reload())} style={{cursor:'pointer'}} color='#990000' size={30} />
                <IoAdd onClick={()=>this.setState({addMode:!this.state.addMode})} style={{cursor:'pointer'}} color={this.state.addMode ? 'gray' : 'gainsboro'} size={30} />
              </div>

              <div style={{display:'flex', justifyContent:"space-between", height:window.innerHeight-70, width:window.innerWidth-30}}>

                <div className='OrderContainer'>
                  <input placeholder='Insira o código do produto' className='OrderSearch' value={this.state.orderSearch} onChange={i=>{
                    this.setState({orderSearch:i.target.value})

                  }}/>
                  {pedidosList}
                </div>

                  {rightContainer}
              </div>

            </div>
  
  
          </div>
        );

      }

    }
    }


export default AdminMaster;