import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import QSLogo from '../assets/img/QSLogo.png'
import {IoCart, IoCartOutline, IoMenu} from 'react-icons/io5'
import { Link } from 'react-router-dom';
import { FetchCart, SearchProds } from '../SQL';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Navigate } from 'react-router-dom';
import { stack as Menu } from 'react-burger-menu'
import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}
class Header extends Component {
  
  state={
    cartN:0,
    searchInput:'',
    items:[],
    redirect:false
  }
  
  componentDidMount = async() => {
    FetchCart().then(res=>{
      this.setState({cartN:res.prods.length})
    })
  }
  
  
  render () {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    
    const handleOnSearch = async(string, results) => {
      // onSearch will have as the first callback parameter
      // the string searched and for the second the results.
      // console.log(string, results)

      this.setState({searchInput:string})

          await SearchProds(capitalizeFirstLetter(string))
          .then(res=>{
    
            let items = []
    
            res.forEach(doc=>{
              items.push({
                id:doc.id,
                name:doc.nome,
                picURL:doc.imgs[0]
              })

              this.setState({items:items})
    
            })
    
          })
    
    }
    
    const handleOnHover = (result) => {
      // the item hovered
      console.log(result)
    }
    
    const handleOnSelect = (item) => {
      // the item selected
      console.log(item)
      window.localStorage.setItem('prodId',JSON.stringify(item.id))
      this.setState({redirect:true})
    }
    
    const handleOnFocus = () => {
      console.log('Focused')
      this.setState({search:true})
    }
    
    const formatResult = (item) => {
      return (
        <div style={{display:'flex', alignItems:'center', cursor:"pointer"}}>
          <img src={URL=item.picURL} style={{maxWidth:50}} />
          <span style={{ display: 'block', textAlign: 'left', fontWeight:'bold', textTransform:'uppercase', color:"gray", marginLeft:10, fontSize:"large" }}>{item.name}</span>
        </div>
      )
    }
    
      
    return (
      <div className="App">
        <Desktop>
  
          <header style={{display:'flex', justifyContent:'space-around', alignItems:"center", backgroundColor:"white"}} className={this.state.searchInput != '' ? 'App-headerL' : "App-header"}>
            <div style={{width:'33%'}}>
              <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'}>
                <img className='QSLogo' style={{height:100}} src={QSLogo}/>
              </Link>
            </div>
            <div style={{width:'33%'}}>

              <ReactSearchAutocomplete
                placeholder='Digite o nome do produto exlcusivo'
                styling={{placeholderColor:'gainsboro'}}
                items={this.state.items}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                showNoResults={false}
                maxResults={3}
              />                
              
            </div>
            <div style={{width:'33%'}}>
              <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/Cart'}>
                {this.state.cartN > 0 ? <IoCart size={35} className='cartIcon' /> : <IoCartOutline size={35} className='cartIcon' /> }
                
              </Link>
            </div>
          </header>
    
          <div className='BelowHeader'>
            <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='CLinkPD' to={'/'}>
              <p>Pedidos</p>
            </Link>
            <div style={{display:'flex', width:'55%', justifyContent:'space-between'}}>
              <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='CLink' to={'/'}>
                <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>INÍCIO</p>
              </Link>
              <Link onClick={()=>{
                window.localStorage.setItem('categoria', JSON.stringify('Casa'))
                  window.scrollTo({behavior:'smooth',top:0})

              }} className='CLink' to={'/Catalog'}>
                <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>CASA</p>
              </Link>
              <Link onClick={()=>{
                window.localStorage.setItem('categoria', JSON.stringify('Ele'))
                  window.scrollTo({behavior:'smooth',top:0})

              }} className='CLink' to={'/Catalog'}>
                <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>ELE</p>
              </Link>
              <Link onClick={()=>{
                window.localStorage.setItem('categoria', JSON.stringify('Ela'))
                  window.scrollTo({behavior:'smooth',top:0})

              }} className='CLink' to={'/Catalog'}>
                <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>ELA</p>
              </Link>
              <Link onClick={()=>{
                window.localStorage.setItem('categoria', JSON.stringify('Pets'))
                  window.scrollTo({behavior:'smooth',top:0})

              }} className='CLink' to={'/Catalog'}>
                <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>PETS</p>
              </Link>
              <Link onClick={()=>{
                window.localStorage.setItem('categoria', JSON.stringify('Promo'))
                  window.scrollTo({behavior:'smooth',top:0})

              }} className='CLink' style={{width:'25%'}} to={'/QuickOfertas'}>
                <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>QUICK OFERTAS</p>
              </Link>
            </div>
            <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='CLinkP' to={'/Pedidos'}>
              <p>Pedidos</p>
            </Link>
        </div>
      </Desktop>
      { 
          this.state.redirect && <Navigate to='/Product' replace={true}/>
        }
        <Mobile>

          <div className={this.state.searchInput != '' ? 'HeaderDivL' :'HeaderDiv'}>
              <header style={{paddingTop:10, paddingBottom:10 ,display:'flex', justifyContent:'space-around', alignItems:"center", backgroundColor:"white"}} className="App-header">
                <div style={{width:'33%', display:'flex', justifyContent:"center"}}>
                  <Menu customBurgerIcon={<IoMenu color='#a8c9ef' size={35}/>} styles={{
                    bmBurgerButton:{
                      width:40, backgroundColor:"transparent", position:'relative'
                    },
                    bmMenuWrap:{
                      position:'fixed',
                      top:0,
                      left:0
                    },
                    bmOverlay:{
                      position:'fixed',
                      top:0,
                      left:0,
                    },

                    }}  className='BelowHeaderMobile'>
                    <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='CLink' to={'/'}>
                      <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>INÍCIO</p>
                    </Link>
                    <Link onClick={()=>{
                      window.localStorage.setItem('categoria', JSON.stringify('Casa'))
                        window.scrollTo({behavior:'smooth',top:0})

                    }} className='CLink' to={'/Catalog'}>
                      <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>CASA</p>
                    </Link>
                    <Link onClick={()=>{
                      window.localStorage.setItem('categoria', JSON.stringify('Ele'))
                        window.scrollTo({behavior:'smooth',top:0})

                    }} className='CLink' to={'/Catalog'}>
                      <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>ELE</p>
                    </Link>
                    <Link onClick={()=>{
                      window.localStorage.setItem('categoria', JSON.stringify('Ela'))
                        window.scrollTo({behavior:'smooth',top:0})

                    }} className='CLink' to={'/Catalog'}>
                      <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>ELA</p>
                    </Link>
                    <Link onClick={()=>{
                      window.localStorage.setItem('categoria', JSON.stringify('Pets'))
                        window.scrollTo({behavior:'smooth',top:0})

                    }} className='CLink' to={'/Catalog'}>
                      <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>PETS</p>
                    </Link>
                    <Link onClick={()=>{

                    }} className='CLink' style={{width:'100%', textAlign:'left'}} to={'/QuickOfertas'}>
                      <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>QUICK OFERTAS</p>
                    </Link>
                    <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='CLinkPMenu' to={'/Pedidos'}>
                      <p>Pedidos</p>
                    </Link>
                </Menu>
                </div>
                <div style={{width:'33%'}}>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'}>
                    <img className='QSLogo' style={{height:50}} src={QSLogo}/>
                  </Link>                  
                </div>
                <div style={{width:'33%'}}>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/Cart'}>
                    {this.state.cartN > 0 ? <IoCart size={35} className='cartIcon' /> : <IoCartOutline size={35} className='cartIcon' /> }
                    
                  </Link>
                </div>
              </header>
              <div style={{width:'70%', marginRight:'auto', marginLeft:"auto", marginBottom:10}}>
                <ReactSearchAutocomplete
                  placeholder='Digite o nome do produto'
                  items={this.state.items}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  maxResults={2}
                  formatResult={formatResult}
                  showNoResults={false}
                  styling={{height:25,placeholderColor:'gainsboro'}}
                />                
              </div>
        
            
          </div>
        </Mobile>
      </div>
    );
        
      }
    }

  export default Header;