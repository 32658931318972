import '../App.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import QSLogo from '../assets/img/QSLogo.png'
import Banner1 from '../assets/img/Banner1Ofertas.png'
import Banner2 from '../assets/img/Banner2.png'
import Banner3 from '../assets/img/Banner3.png'
import Banner4 from '../assets/img/Banner4.png'
import Banner4Ofertas from '../assets/img/Banner4OfertaBreve.png'
import Banner4OfertasMobile from '../assets/img/Banner4OfertaBreveMobile.png'
import Banner5 from '../assets/img/Banner5.png'
import lar from '../assets/img/BannerOfertas1.jpg'
import ela from '../assets/img/ela.jpg'
import ele from '../assets/img/BannerOfertas2.jpg'
import pet from '../assets/img/pet.jpg'
import catalogIcon1 from '../assets/img/1.png'
import catalogIcon3 from '../assets/img/3.png'
import catalogIcon4 from '../assets/img/4.png'
import catalogIcon5 from '../assets/img/5.png'
import {IoCartOutline, IoLocation, IoStar, IoChatbubbleEllipses, IoClose, IoLockClosed, IoMail, IoStarHalf} from 'react-icons/io5'
import { Link } from 'react-router-dom';
import { Debug, FetchAce, FetchEla, FetchEle, FetchMochi, FetchMost, FetchMostOfertas, FetchRecOfertas, FetchUser, MostAlgo } from '../SQL';
import ModalProd from '../components/ModalProd';

import HeaderOfertas from '../components/HeaderOfertas';
import Foot from '../components/Foot';
import aval from '../components/Rating';
import parcela from '../components/Parcela';
import porcentagem from '../components/Porcentagem';

import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 750 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 2
  }
};


class QuickOfertas extends Component {

  state={
    MostProds:[],
    MochiProds:[],
    AceProds:[],
    EleProds:[],
    ElaProds:[],
    user:'',
    MiniModal:false,

  }


  componentDidMount = async() => {
    let mochi = await FetchRecOfertas().then(res => this.setState({MochiProds:res}))
    let most = await FetchMostOfertas().then(res => this.setState({MostProds:res}))
  }

    render () {

      let MostList = this.state.MostProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdCont'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome} | {item.idNumber}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
              {parcela(item.preco,0)}
            </div>
            <div className='ProdBtnDiv'>
              <button className='ProdBtn' onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <a href={item.linkAfiliado} onClick={()=>MostAlgo(item.id)} className='ProdBtn' style={{width:'100%', textDecoration:'none'}} target='_blank'>
                  <p className='ProdLink'>Ir para o site</p>
              </a>
            </div>
          </div>

        )
      })  

      let MostListMobile = this.state.MostProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileOfertas'>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome.length > 15 ? item.nome.substring(0,15)+'...' | item.idNumber : item.nome + ' | ' + item.idNumber}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
            
            </div>
            <div className='ProdBtnDiv'>
              <button className='ProdBtn' onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <a href={item.linkAfiliado} onClick={()=>MostAlgo(item.id)} className='ProdBtn' style={{width:'100%', textDecoration:'none'}} target='_blank'>
                  <p className='ProdLink'>Ir para o site</p>
              </a>
            </div>
          </div>

        )
      })  

      let RecList = this.state.MochiProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContCat'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome} | {item.idNumber}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
              {parcela(item.preco,0)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <a href={item.linkAfiliado} onClick={()=>MostAlgo(item.id)} className='ProdBtn' style={{width:'100%', textDecoration:'none'}} target='_blank'>
                  <p className='ProdLink'>Ir para o site</p>
              </a>
            </div>
          </div>

        )
      })  

      let RecListMobile = this.state.MochiProds.slice(0,6).map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileOfertasCat' style={{width:'40%', marginBottom:20}}>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome.length > 15 ? item.nome.substring(0,15)+'...' | item.idNumber : item.nome + ' | ' + item.idNumber}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
            
            </div>
            <div className='ProdBtnDivCat' style={{width:"100%"}}>
              <button className='ProdBtn' onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <a href={item.linkAfiliado} onClick={()=>MostAlgo(item.id)} className='ProdBtn' style={{width:'100%', textDecoration:'none'}} target='_blank'>
                  <p className='ProdLink'>Ir para o site</p>
              </a>
            </div>
          </div>

        )
      })  

      let miniModal = this.state.MiniModal ? (
        <div className="MiniModal">
          <div style={{display:'flex', justifyContent:'flex-end'}}>
            <button onClick={()=>{this.setState({MiniModal:false})}} style={{backgroundColor:"transparent", borderStyle:'none', cursor:'pointer'}}>
              <IoClose color='gray' size={25}/>
            </button>
          </div>
          <ModalProd nome={this.state.ModalItem.nome} variacoes={this.state.ModalItem.variacoes} prod={this.state.ModalItem}/>
        </div>
      ) : ''


        return (
          <div className="App">

          <Desktop>

              {/* <button onClick={()=>Debug()}>
                Debug
              </button> */}

              {miniModal}
              <HeaderOfertas/>
        
              <a style={{overflow:'hidden'}} href={'https://instagram.com/quickshopbsb'} target='_blank'>
                <img className='Banner1' src={Banner1}/>
              </a>
                
              <div style={{backgroundColor:'white'}}>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', paddingRight:60, alignItems:"center"}}>
                  <p className='verMaisL'>Ver mais</p>
                  <p className='Title'>Mais vendidos</p>
                  <p className='verMaisR'>Ver mais</p>
                </div>
                <div className='CarouselCont'>
                  <Carousel slidesToSlide={4} showDots={true} arrows={false} responsive={responsive}>
                    {MostList}
                  </Carousel>
                </div>
              </div>
              
              <div style={{backgroundColor:'white'}}>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', paddingRight:60, alignItems:"center"}}>
                  <p className='verMaisL'>Ver mais</p>
                  <p className='Title'>Adicionados recentemente</p>
                  <p className='verMaisR'>Ver mais</p>
                </div>
                <div className='RecCont'>
                    {RecList}
                </div>
              </div>

              <div style={{backgroundColor:'white'}}>
                <div className='ApeCatDiv'>
                  <div className='ApeCatImgDiv'>
                <Link onClick={()=>{
                  window.scrollTo({behavior:"smooth",top:0})
                }} style={{textDecoration:'none'}} to={'/CatalogOfertas'}>
                    <img className='ApeCatImg' src={lar} />
                    <p className='ApeCatLink'>Veja todas as ofertas </p>
                  </Link> 
                  </div>
                  <div style={{width:'65%'}}>
                    <p className='ApeCatTitle'>Uma grande variedade.</p>
                    <p className='ApeCatText'>Variados produtos de diversar funções para facilitar sua vida! </p>
                  </div>
                </div>
              </div>

              <div style={{backgroundColor:'white'}}>
                <div className='ApeCatDiv'>
                  <div style={{width:'65%'}}>
                        <p className='ApeCatTitle'>Prático e eficiente.</p>
                        <p className='ApeCatText'>Não achou o produto? digite o código do produto na barra de busca e ache facilmente! </p>
                  </div>
                  <div className='ApeCatImgDiv'>
                <button  onClick={()=>{
                  window.scrollTo({behavior:"smooth",top:0})
                }} style={{textDecoration:'none', borderStyle:'none', backgroundColor:"transparent", cursor:'pointer', padding:0}} >
                    <img className='ApeCatImg' src={ele} />
                    <p className='ApeCatLink'>Ir para barra de busca</p>
                  </button> 
                  </div>
                </div>
              </div>
        
        
              <div >
                <div to={'/'}>
                  <img style={{maxWidth:'100%'}} src={Banner4Ofertas} />
                </div>
              </div>
              
              
          </Desktop>

          <Mobile>

              {/* <button onClick={()=>Debug()}>
                Debug
              </button> */}

              {miniModal}
              <HeaderOfertas/>
        
              <a style={{overflow:'hidden'}} href={'https://instagram.com/quickshopbsb'} target='_blank'>
                <img className='Banner1' src={Banner1}/>
              </a>
                
              <div style={{backgroundColor:'white'}}>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', paddingRight:60, alignItems:"center"}}>
                  <p className='verMaisL'>Ver mais</p>
                  <p className='TitleMobile'>Mais vendidos</p>
                  <p className='verMaisR'>Ver mais</p>
                </div>
                <div className='CarouselCont'>
                  <Carousel slidesToSlide={4} showDots={true} arrows={false} responsive={responsive}>
                    {MostListMobile}
                  </Carousel>
                </div>
              </div>
              
              <div style={{backgroundColor:'white'}}>
                <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:"center"}}>
                  <p className='TitleMobile'>Adicionados recentemente</p>
                </div>
                <div className='RecContMobile' >
                    {RecListMobile}
                </div>
              </div>

              <div style={{backgroundColor:'white'}}>
                <div className='ApeCatDivMobile'>
                  <div className='ApeCatImgDivMobile'>
                <Link onClick={()=>{
                  window.scrollTo({behavior:"smooth",top:0})
                }} style={{textDecoration:'none'}} to={'/CatalogOfertas'}>
                    <img className='ApeCatImgMobile' src={lar} />
                    <p className='ApeCatLinkMobile'>Veja todas as ofertas </p>
                  </Link> 
                  </div>
                  <div style={{width:'65%'}}>
                    <p className='ApeCatTitleMobile'>Grande variedade.</p>
                    <p className='ApeCatTextMobile'>Variados produtos de diversar funções para facilitar sua vida! </p>
                  </div>
                </div>
              </div>

              <div style={{backgroundColor:'white'}}>
                <div className='ApeCatDivMobile'>
                  <div style={{width:'65%'}}>
                        <p className='ApeCatTitleMobile'>Prático e eficiente.</p>
                        <p className='ApeCatTextMobile'>Não achou o produto? digite o código do produto na barra de busca e ache facilmente! </p>
                  </div>
                  <div className='ApeCatImgDivMobile'>
                <button  onClick={()=>{
                  window.scrollTo({behavior:"smooth",top:0})
                }} style={{textDecoration:'none', borderStyle:'none', backgroundColor:"transparent", cursor:'pointer', padding:0}} >
                    <img className='ApeCatImgMobile' src={ele} />
                    <p className='ApeCatLinkMobile'>Ir para barra de busca</p>
                  </button> 
                  </div>
                </div>
              </div>
        
        
              <div >
                <div to={'/'}>
                  <img style={{maxWidth:'100%'}} src={Banner4OfertasMobile} />
                </div>
              </div>
              
              

          </Mobile>
          </div>
        );
      }
    }

  export default QuickOfertas;