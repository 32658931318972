const firebaseConfig = {
    apiKey: "AIzaSyA8vWlBdtiPmc2yyYaqsvSEAlay0IZpWcs",
    authDomain: "quickshop-56f52.firebaseapp.com",
    projectId: "quickshop-56f52",
    storageBucket: "quickshop-56f52.appspot.com",
    messagingSenderId: "280807050160",
    appId: "1:280807050160:web:668a08c19ea834c9eee475",
    measurementId: "G-4GJ5G0MVPY"
  };

export default firebaseConfig
  