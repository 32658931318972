import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoRefresh, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FecthPedido, FetchAce, FetchMochi, FetchMost } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class Pedidos extends Component {


  state = {
    codigo:'',
    codigoR:'',
    nome:'',
    celular:'',
    status:'',
    rasteamento:'',
    valorTotal:'',
    produtos:[],
    produtosQtd:[],
    enderecos:[],
    cidade:'',
    estado:'',
    endereco:'',
    complemento:'',
    numero:'',
    bairro:'',
    enable:false,
    error:false
  }

    render(){

      let produtos;

      if(this.state.produtos != undefined){
        produtos = this.state.produtos.map((i,idx)=>{
          return(
            <p key={idx} className={this.state.enable ? 'PedidoTextR' : 'PedidoTextDR'} > <b>{i}</b> - {this.state.produtosQtd[idx]}x </p>
          )
        })
      }

        return (
          <div className="App">
      
            <Header/>

      
            <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white", marginBottom:40}}>
              <div style={{borderStyle:'solid', borderWidth:0, borderBottomWidth:1, borderColor:'#a8c9ef', paddingBottom:20, marginBottom:40}}>
                <p className='PedidoIT'>Digite o código do seu pedido:</p>
                <div style={{display:'flex', justifyContent:'center'}}>          
                  <button className='PedidoBtn' style={{opacity:0, pointerEvents:'none'}}>
                    <IoChevronForward className='PedidoIcon' />
                  </button>
                  <input className='PedidoI' value={this.state.codigo} onChange={i=>this.setState({codigo:i.target.value})} placeholder={'Ex: 9ab38hs7h1'}/>
                  <button className='PedidoBtn' onClick={()=>{
                      FecthPedido(this.state.codigo).then(res=>{
                        if(res==='e') {
                          this.setState({error:true})
                          setTimeout(() => {
                            this.setState({error:false})                
                          }, 2000);
                        } else {
                          this.setState({
                            enable:true,
                            nome:res.userName,
                            celular:res.userPhone,
                            valorTotal:res.valorTotal,
                            codigoR:res.ID,
                            rasteamento:res.rastreio,
                            produtos:res.produtos.prods,
                            produtosQtd:res.produtos.qtds,
                            enderecos:res.endereco,
                            status:res.status,
                            cidade:res.endereco.city,
                            estado:res.endereco.state,
                            bairro:res.endereco.bairro,
                            endereco:res.endereco.endereco,
                            complemento:res.endereco.complemento,
                            numero:res.endereco.numero,
                            email:res.userEmail,
                            ETA:res.ETA,
                          })
                        }
                      })
                  }}>
                    <IoChevronForward className={!this.state.error ? 'PedidoIcon' : 'PedidoIconE'} />
                  </button>
                </div>
              </div>
              <div style={{borderRadius:20, backgroundColor:'#a8c9ef', padding:10}}>
                <p className={this.state.enable ? 'PedidoN' : 'PedidoND'}>PEDIDO {this.state.codigoR}</p>
                <div style={{display:"flex", justifyContent:"space-between"}}>                  
                  <div>
                    <div className={this.state.enable ? 'PedidosDiv' : 'PedidosDivD'}>
                      <p className={this.state.enable ? 'PedidoText' : 'PedidoTextD'}><b>Comprador:</b> {this.state.nome}</p>
                      <p className={this.state.enable ? 'PedidoText' : 'PedidoTextD'}><b>Número para contato:</b> {this.state.celular}</p>
                      <p className={this.state.enable ? 'PedidoText' : 'PedidoTextD'}><b>Email:</b> {this.state.email}</p>
                    </div>
                    <div className={this.state.enable ? 'PedidosDiv' : 'PedidosDivD'}>
                      <p className={this.state.enable ? 'PedidoText' : 'PedidoTextD'}><b>Localização:</b> {this.state.cidade} - {this.state.estado} </p>
                      <p className={this.state.enable ? 'PedidoText' : 'PedidoTextD'}><b>Endereço:</b> {this.state.bairro}, {this.state.endereco}, {this.state.numero}, {this.state.complemento}</p>
                      <p className={this.state.enable ? 'PedidoText' : 'PedidoTextD'}><b>Estimativa de entrega:</b> {this.state.ETA}</p>
                    </div>
                    <div className={this.state.enable ? 'PedidosDiv' : 'PedidosDivD'} style={{borderStyle:'solid', borderWidth:.5, borderColor:'white', borderLeftWidth:0, borderTopRightRadius:10, borderBottomRightRadius:10}}>
                      <p className={this.state.enable ? 'PedidoText' : 'PedidoTextD'}><b>Status:</b> {this.state.status}</p>
                      <p className={this.state.enable ? 'PedidoText' : 'PedidoTextD'}><b>Codigo de rastreamento:</b> {this.state.rasteamento}</p>
                    </div>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:'center'}}>
                    <div className={this.state.enable ? 'PedidosDiv' : 'PedidosDivD'}>
                      {produtos}
                    </div>
                    <div className={this.state.enable ? 'PedidosDiv' : 'PedidosDivD'}>
                      <p className={this.state.enable ? 'PedidoTextR' : 'PedidoTextDR'} style={{fontSize:25, fontWeight:'bold'}} >Valor total: R${(this.state.valorTotal/100).toFixed(2).replace('.',',')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            <Foot/>
          </div>
        );
    }
    }


export default Pedidos;