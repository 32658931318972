import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoRefresh, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FecthPedido, FetchAce, FetchMochi, FetchMost } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class InfoPP extends Component {



    render(){

        return (
          <div className="App">
      
            <Header/>

      
            <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white", marginBottom:40}}>
              <p className='IPTitle'> POLÍTICA DE PRIVACIDADE</p>

              <p className='IPText'>A&nbsp;<b>QuickShop</b> &eacute; um site &eacute; mantido e operado por&nbsp;<b>NOM FANTASIA</b>, pessoa jur&iacute;dica de direito privado, com sede Al Santos, n&deg; 18, CONJ 26, Cerqueira Cesar, S&atilde;o Paulo/ SP, CEP CEEEEP, inscrita no CNPJ sob o n&ordm; CNPJJJJJJJJJ.</p>
<p className='IPText'>Por meio do presente site &eacute; feito a coleta e utiliza&ccedil;&atilde;o de alguns dados pessoais que pertencem &agrave;queles que utilizam o mesmo. Ao faz&ecirc;-lo, a empresa age na qualidade de controlador desses dados, estando sujeitos &agrave;s disposi&ccedil;&otilde;es da Lei Federal n. 13.709/2018 (Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais - LGPD).</p>
<p className='IPText'>A empresa respeita sua privacidade e a prote&ccedil;&atilde;o dos seus Dados Pessoais. Os Dados Pessoais s&atilde;o utilizados dentro dos limites legais e &eacute;ticos e para oferecer um maior cuidado, todas as medidas adotadas para proteg&ecirc;-los foram feitas pela Yampi e Shopify. E como forma de deixar transparente os cuidados tomados, disponibilizamos esta pol&iacute;tica de privacidade, que cont&eacute;m informa&ccedil;&otilde;es importantes sobre:</p>
<p className='IPText'>- Quem deve utilizar o site;</p>
<p className='IPText'>- Quais os dados coletados e a utiliza&ccedil;&atilde;o deles;</p>
<p className='IPText'>- Seus direitos em rela&ccedil;&atilde;o aos seus dados pessoais; e &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Como entrar em contato conosco.</p>
<ol>
    <li><b>Quem deve utilizar nosso site</b></li>
</ol>
<p className='IPText'>Este site s&oacute; deve ser utilizado por pessoas com mais de dezoito anos de idade. Sendo assim, crian&ccedil;as e adolescentes n&atilde;o devem utiliz&aacute;-lo.</p>
<ol start="2">
    <li><b>Dados que coletamos e motivos da coleta</b></li>
</ol>
<p className='IPText'>Este site coleta e utiliza alguns dados pessoais dos usu&aacute;rios, de acordo com o disposto nesta se&ccedil;&atilde;o.</p>
<ol>
    <li><b>Dados pessoais fornecidos expressamente pelo usu&aacute;rio</b></li>
</ol>
<p className='IPText'>Coleta-se os seguintes dados pessoais que os usu&aacute;rios fornecem expressamente ao utilizar o site:</p>
<p className='IPText'>- Nome Completo;</p>
<p className='IPText'>- CPF;</p>
<p className='IPText'>- Endere&ccedil;o; &nbsp; &nbsp;</p>
<p className='IPText'>- CEP; &nbsp;</p>
<p className='IPText'>- E-mail; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Celular e, ou, telefone fixo; &nbsp;</p>
<p className='IPText'>- Dados Banc&aacute;rios.</p>
<p className='IPText'>A coleta destes dados ocorre nos seguintes momentos:</p>
<p className='IPText'>- Quando o usu&aacute;rio faz seu cadastro no site/sistema; &nbsp; &nbsp; &nbsp;</p>
<p className='IPText'>- Quando o usu&aacute;rio utiliza o formul&aacute;rio de contato. &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'>Os dados fornecidos por nossos usu&aacute;rios s&atilde;o coletados com as seguintes finalidades:</p>
<p className='IPText'>- Para que o cliente possa ter acesso &agrave; plataforma da empresa; &nbsp;</p>
<p className='IPText'>- Para que o cliente possa entrar em contato com o nosso SAC; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Para que o nosso SAC possa entrar em contato com o cliente.</p>
<p className='IPText'>- Para oferecimento pela Empresa de ofertas, promo&ccedil;&otilde;es, descontos exclusivos para o cliente.</p>
<ol start="3">
    <li><b>Dados sens&iacute;veis</b></li>
</ol>
<p className='IPText'>N&atilde;o ser&atilde;o coletados dados sens&iacute;veis de nossos usu&aacute;rios, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de Prote&ccedil;&atilde;o de Dados Pessoais. Assim, n&atilde;o haver&aacute; coleta de dados sobre origem racial ou &eacute;tnica, convic&ccedil;&atilde;o religiosa, opini&atilde;o pol&iacute;tica, filia&ccedil;&atilde;o a sindicato ou a organiza&ccedil;&atilde;o de car&aacute;ter religioso, filos&oacute;fico ou pol&iacute;tico, dado referente &agrave; sa&uacute;de ou &agrave; vida sexual, dado gen&eacute;tico ou biom&eacute;trico, quando vinculado a uma pessoa natural.</p>
<ol start="4">
    <li><b>Coleta de dados n&atilde;o previstos expressamente</b></li>
</ol>
<p className='IPText'>Eventualmente, outros tipos de dados n&atilde;o previstos expressamente nesta Pol&iacute;tica de Privacidade poder&atilde;o ser coletados, desde que sejam fornecidos com o consentimento do usu&aacute;rio, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.</p>
<p className='IPText'>Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes ser&atilde;o informadas aos usu&aacute;rios do site.</p>
<ol start="5">
    <li><b>Armazenamento</b></li>
</ol>
<p className='IPText'>Os Dados s&atilde;o armazenados diretamente na plataforma (shopify), uma vez que s&atilde;o migrados da plataforma de hospedagem do site (yampi ) para ser utilizado.</p>
<ol start="6">
    <li><b>Compartilhamento de dados pessoais com terceiros</b></li>
</ol>
<p className='IPText'>N&oacute;s n&atilde;o compartilhamos seus dados pessoais com terceiros. Apesar disso, &eacute; poss&iacute;vel que o fa&ccedil;amos para cumprir alguma determina&ccedil;&atilde;o legal ou regulat&oacute;ria, ou, ainda, para cumprir alguma ordem expedida por autoridade p&uacute;blica.</p>
<ol start="7">
    <li><b>Por quanto tempo seus dados pessoais ser&atilde;o armazenados?</b></li>
</ol>
<p className='IPText'>Os dados pessoais coletados pelo site s&atilde;o armazenados e utilizados por per&iacute;odo de tempo que corresponda ao necess&aacute;rio para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do site e as disposi&ccedil;&otilde;es legais ou regulat&oacute;rias aplic&aacute;veis.</p>
<p className='IPText'>Uma vez expirados os per&iacute;odos de armazenamento dos dados pessoais, eles s&atilde;o removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposi&ccedil;&atilde;o legal ou regulat&oacute;ria.</p>
<ol start="8">
    <li><b>Bases legais para o tratamento de dados pessoais</b></li>
</ol>
<p className='IPText'>Uma base legal para o tratamento de dados pessoais nada mais &eacute; que um fundamento jur&iacute;dico, previsto em lei, que o justifica. Assim, cada opera&ccedil;&atilde;o de tratamento de dados pessoais precisa ter uma base legal a ela correspondente.</p>
<p className='IPText'>N&oacute;s tratamos os dados pessoais de nossos usu&aacute;rios nas seguintes hip&oacute;teses:</p>
<p className='IPText'>- Mediante o consentimento do titular dos dados pessoais;</p>
<p className='IPText'>- Para o exerc&iacute;cio regular de direitos em processo judicial, administrativo ou arbitral;</p>
<p className='IPText'>- Para a execu&ccedil;&atilde;o de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados pessoais.</p>
<ol start="9">
    <li><b>Consentimento</b></li>
</ol>
<p className='IPText'>Determinadas opera&ccedil;&otilde;es de tratamento de dados pessoais realizadas em nosso site depender&atilde;o da pr&eacute;via concord&acirc;ncia do usu&aacute;rio, que dever&aacute; manifest&aacute;-la de forma livre, informada e inequ&iacute;voca.</p>
<p className='IPText'>O usu&aacute;rio poder&aacute; revogar seu consentimento a qualquer momento, sendo que, n&atilde;o havendo hip&oacute;tese legal que permita ou que demande o armazenamento dos dados, os dados fornecidos mediante consentimento ser&atilde;o exclu&iacute;dos.</p>
<p className='IPText'>Al&eacute;m disso, se desejar, o usu&aacute;rio poder&aacute; n&atilde;o concordar com alguma opera&ccedil;&atilde;o de tratamento de dados pessoais baseada no consentimento. Nestes casos, por&eacute;m, &eacute; poss&iacute;vel que n&atilde;o possa utilizar alguma funcionalidade do site que dependa daquela opera&ccedil;&atilde;o. As consequ&ecirc;ncias da falta de consentimento para uma atividade espec&iacute;fica s&atilde;o informadas previamente ao tratamento.</p>
<ol start="10">
    <li><b>Execu&ccedil;&atilde;o de contrato</b></li>
</ol>
<p className='IPText'>Para a execu&ccedil;&atilde;o do contrato eventualmente firmado entre o site e o usu&aacute;rio, poder&atilde;o ser coletados e armazenados outros dados relacionados ou necess&aacute;rios a sua execu&ccedil;&atilde;o, incluindo o teor de eventuais comunica&ccedil;&otilde;es tidas com o usu&aacute;rio.</p>
<ol start="11">
    <li><b>Direitos do usu&aacute;rio</b></li>
</ol>
<p className='IPText'>O usu&aacute;rio do site possui os seguintes direitos, conferidos pela Lei de Prote&ccedil;&atilde;o de Dados Pessoais:</p>
<p className='IPText'>- Confirma&ccedil;&atilde;o da exist&ecirc;ncia de tratamento; &nbsp;&nbsp;</p>
<p className='IPText'>- Acesso aos dados; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Corre&ccedil;&atilde;o de dados incompletos, inexatos ou desatualizados; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o de dados desnecess&aacute;rios, excessivos ou tratados em desconformidade com o disposto na lei; &nbsp;</p>
<p className='IPText'>- Portabilidade dos dados a outro fornecedor de servi&ccedil;o ou produto, mediante requisi&ccedil;&atilde;o expressa, de acordo com a regulamenta&ccedil;&atilde;o da autoridade nacional, observados os segredos comercial e industrial; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Elimina&ccedil;&atilde;o dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Informa&ccedil;&atilde;o das entidades p&uacute;blicas e privadas com as quais o controlador realizou uso compartilhado de dados; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o fornecer consentimento e sobre as consequ&ecirc;ncias da negativa; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p className='IPText'>- Revoga&ccedil;&atilde;o do consentimento.</p>
<p className='IPText'>&Eacute; importante destacar que, nos termos da LGPD, n&atilde;o existe um direito de elimina&ccedil;&atilde;o de dados tratados com fundamento em bases legais distintas do consentimento, a menos que os dados sejam desnecess&aacute;rios, excessivos ou tratados em desconformidade com o previsto na lei.</p>
<ol start="12">
    <li><b>Como o titular pode exercer seus direitos</b></li>
</ol>
<p className='IPText'>Para garantir que o usu&aacute;rio que pretende exercer seus direitos &eacute;, de fato, o titular dos dados pessoais objeto da requisi&ccedil;&atilde;o, poderemos solicitar documentos ou outras informa&ccedil;&otilde;es que possam auxiliar em sua correta identifica&ccedil;&atilde;o, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente ser&aacute; feito, por&eacute;m, se for absolutamente necess&aacute;rio, e o requerente receber&aacute; todas as informa&ccedil;&otilde;es relacionadas.</p>
<ol start="13">
    <li><b>Medidas de seguran&ccedil;a no tratamento de dados pessoais</b></li>
</ol>
<p className='IPText'>Empregamos medidas t&eacute;cnicas e organizativas aptas a proteger os dados pessoais de acessos n&atilde;o autorizados e de situa&ccedil;&otilde;es de destrui&ccedil;&atilde;o, perda, extravio ou altera&ccedil;&atilde;o desses dados.</p>
<p className='IPText'>As medidas que utilizamos levam em considera&ccedil;&atilde;o a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual viola&ccedil;&atilde;o geraria para os direitos e liberdades do usu&aacute;rio, e os padr&otilde;es atualmente empregados no mercado por empresas semelhantes &agrave; nossa.</p>
<p className='IPText'>Entre as medidas de seguran&ccedil;a adotadas por n&oacute;s, destacamos as seguintes:</p>
<p className='IPText'>- Restri&ccedil;&otilde;es de acessos a bancos de dados; &nbsp; &nbsp;</p>
<p className='IPText'>- Monitoramento de acesso f&iacute;sicos a servidores; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p className='IPText'>- Limita&ccedil;&atilde;o de permiss&otilde;es a m&oacute;dulos do sistema.</p>
<p className='IPText'>Ainda que adote tudo o que est&aacute; ao seu alcance para evitar incidentes de seguran&ccedil;a, &eacute; poss&iacute;vel que ocorra algum problema motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usu&aacute;rio, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, respons&aacute;veis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situa&ccedil;&atilde;o excepcional como essas, sobre as quais n&atilde;o temos nenhum tipo de controle.</p>
<p className='IPText'>De qualquer forma, caso ocorra qualquer tipo de incidente de seguran&ccedil;a que possa gerar risco ou dano relevante para qualquer de nossos usu&aacute;rios, comunicaremos os afetados e a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Prote&ccedil;&atilde;o de Dados.</p>
<ol start="14">
    <li><b>Reclama&ccedil;&atilde;o a uma autoridade de controle</b></li>
</ol>
<p className='IPText'>Sem preju&iacute;zo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar reclama&ccedil;&atilde;o &agrave; Autoridade Nacional de Prote&ccedil;&atilde;o de Dados.</p>
<ol start="15">
    <li><b>Cookies</b></li>
</ol>
<p className='IPText'>Cookies s&atilde;o usados para coletar informa&ccedil;&otilde;es, inclusive:</p>
<p className='IPText'>Endere&ccedil;o IP</p>
<p className='IPText'>ID do dispositivo</p>
<p className='IPText'>P&aacute;ginas visualizadas</p>
<p className='IPText'>Tipo de navegador</p>
<p className='IPText'>Informa&ccedil;&otilde;es de navega&ccedil;&atilde;o</p>
<p className='IPText'>Sistema operacional</p>
<p className='IPText'>Provedor de servi&ccedil;os de internet</p>
<p className='IPText'>Registro de data/hora</p>
<p className='IPText'>Respostas a um an&uacute;ncio</p>
<p className='IPText'>Uma URL de refer&ecirc;ncia</p>
<p className='IPText'>Funcionalidades utilizadas ou atividades realizadas no site/aplicativos</p>
<p className='IPText'>Esses cookies analisam e monitoram como nosso site e aplicativos s&atilde;o usados. Usamos essas informa&ccedil;&otilde;es para melhorar nosso site, aplicativos e servi&ccedil;os.</p>
<p className='IPText'>Ajudam a entender como visitantes e clientes usam a Plataforma.</p>
<p className='IPText'>Ajudam a melhorar o site, aplicativos e comunica&ccedil;&otilde;es para garantir que n&oacute;s sejamos interessantes e relevantes.</p>
<ol start="16">
    <li><b>Altera&ccedil;&otilde;es nesta pol&iacute;tica</b></li>
</ol>
<p className='IPText'>A presente vers&atilde;o desta Pol&iacute;tica de Privacidade foi atualizada pela &uacute;ltima vez em: 18 de novembro de 2021</p>
<p className='IPText'>Nos reservamos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adapt&aacute;-las &agrave;s eventuais altera&ccedil;&otilde;es feitas em nosso site, seja pela disponibiliza&ccedil;&atilde;o de novas funcionalidades, seja pela supress&atilde;o ou modifica&ccedil;&atilde;o daquelas j&aacute; existentes.</p>
<p className='IPText'>Sempre que houver uma modifica&ccedil;&atilde;o, nossos usu&aacute;rios ser&atilde;o notificados acerca da mudan&ccedil;a.</p>
<ol start="17">
    <li><b>Como entrar em contato conosco</b></li>
</ol>
<p className='IPText'>Para esclarecer quaisquer d&uacute;vidas sobre esta Pol&iacute;tica de Privacidade ou sobre os dados pessoais que tratamos, entre em contato com nosso Encarregado de Prote&ccedil;&atilde;o de Dados Pessoais, por algum dos canais mencionados abaixo:</p>
<p className='IPText'><em>E-mail: contato@quickshopbr.com.br</em></p>
<p className='IPText'><em>N&uacute;mero de telefone: +55 11 00000000</em></p>
<p className='IPText'><em>Link para formul&aacute;rio: https://quickshopbr.com.br/pages/fale-conosco</em></p>

            </div>
      
            <Foot/>
          </div>
        );
    }
    }


export default InfoPP;