import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoRefresh, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FecthPedido, FetchAce, FetchMochi, FetchMost } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class InfoPF extends Component {



    render(){

        return (
          <div className="App">
      
            <Header/>

      
            <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white", marginBottom:40}}>
              <p className='IPTitle'>Perguntas frequentes</p>

              <p className='IPText'><strong>1. O SITE &Eacute; CONFI&Aacute;VEL?</strong><br/><br/>A <strong>SmartMan Shop</strong> trabalha com as maiores plataformas de seguran&ccedil;a para ecommerce no Brasil. Al&eacute;m disso, nosso site conta com a <strong>seguran&ccedil;a SSL</strong> que &eacute; obrigat&oacute;ria por Lei para todas lojas online no Brasil, pois criptografa todas transa&ccedil;&otilde;es efetuadas no site garantindo sigilo de todas informa&ccedil;&otilde;es. &nbsp;</p>
<br/>
<p className='IPText'><br/><strong>2.&nbsp;</strong><strong>TODOS OS PEDIDOS ACOMPANHAM N&Uacute;MERO DE RASTREIO?</strong><br/><br/>Sim. N&oacute;s presamos pela transpar&ecirc;ncia e, por isso, todos os produtos s&atilde;o enviados com c&oacute;digos de rastreamento v&aacute;lidos internacionalmente.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'><strong>3. QUANDO RECEBEREI O N&Uacute;MERO DE RASTREAMENTO AP&Oacute;S A MINHA COMPRA?</strong><br/><br/>Nossos fornecedores (ind&uacute;strias e varejos internacionais) <strong>pedem de&nbsp;3 a 7 dias</strong> &uacute;teis para enviarem o n&uacute;mero de rastreio. Se mesmo ap&oacute;s este per&iacute;odo voc&ecirc; n&atilde;o tiver recebido, basta entrar em contato conosco via e-mail ou whatsapp <strong>INCLUINDO o n&uacute;mero do seu pedido (algo como #1234)</strong> e lhe daremos uma posi&ccedil;&atilde;o.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'><strong>4.&nbsp;COMO COMPRAR UM PRODUTO? VEJA OS PASSOS:</strong></p>
<ul>
    <li className='IPText'><strong>&nbsp;</strong><strong>Escolha o Produto:&nbsp;</strong>Selecione&nbsp;o produto desejado e clique em &ldquo;<strong>Compre J&aacute;</strong>&rdquo;.</li>
    <li className='IPText'>&nbsp;<strong>Cupom de Desconto:&nbsp;</strong>Caso tenha um cupom de desconto e queira utiliz&aacute;-lo, clique em &quot;adicionar cupom de desconto&quot;, digite o c&oacute;digo e clique em &quot;usar&quot;. O sistema far&aacute; o c&aacute;lculo autom&aacute;tico do desconto.</li>
    <li className='IPText'><strong>&nbsp;</strong><strong>Prosseguir a compra:&nbsp;</strong>Clique em &quot;<strong>Finalizar Compra</strong>&quot; quando todos os produtos desejados estiverem no carrinho.</li>
    <li className='IPText'><strong>&nbsp;</strong><strong>Endere&ccedil;o de Entrega:&nbsp;</strong>Preencha os dados de entrega e selecione o tipo de entrega<strong>.&nbsp;</strong>Caso deseje enviar um presente para outro destinat&aacute;rio, n&atilde;o se esque&ccedil;a de conferir se o endere&ccedil;o est&aacute; correto e informar o nome do destinat&aacute;rio (quem receber&aacute; o presente)</li>
    <li className='IPText'><strong>Escolha a Forma de Pagamento:&nbsp;</strong>Op&ccedil;&otilde;es dispon&iacute;veis:<ul>
            <li className='IPText'><strong>Mercado Pago</strong> (cart&atilde;o de cr&eacute;dito): selecione essa forma de pagamento e finalize o pedido. Ap&oacute;s finalizar, voc&ecirc; ser&aacute; direcionado ao site do MercadoPago para efetuar o pagamento.</li>
            <li className='IPText'><strong>Boleto Banc&aacute;rio:</strong> selecione essa forma de pagamento e finalize o pedido. O sistema far&aacute; o c&aacute;lculo do desconto automaticamente. Ap&oacute;s finalizar, clique no link para impress&atilde;o do boleto.</li>
        </ul>
    </li>
    <li className='IPText'>&nbsp;<strong>Finalizar Compra:&nbsp;</strong>Confira as informa&ccedil;&otilde;es do seu pedido e clique em &quot;<strong>Finalizar Compra</strong>&quot;.</li>
    <li className='IPText'>&nbsp;<strong>Comunicados:&nbsp;</strong>Voc&ecirc; receber&aacute; em seu e-mail mensagens com os dados do pedido. Para garantir o recebimento de nossas mensagens, adicione o e-mail&nbsp;contato@smartmanshop.com.br em seus contatos. N&atilde;o esque&ccedil;a de verificar sua caixa de spam/lixo eletr&ocirc;nico, pois, algumas mensagens importantes podem ir para l&aacute; dependendo da configura&ccedil;&atilde;o de seu e-mail.</li>
    <li className='IPText'>&nbsp;<strong>Acompanhe do pedido:&nbsp;</strong>Pela área "Pedidos" no nosso site, e atrav&eacute;s do c&oacute;digo de rastreio, acompanhe seu pedido. A entrega est&aacute; condicionada &agrave; disponibilidade em estoque. Caso o produto esteja indispon&iacute;vel, entraremos em contato.</li>
</ul>

            </div>
      
            <Foot/>
          </div>
        );
    }
    }


export default InfoPF;