import { IoStar, IoStarHalf} from 'react-icons/io5'

export default function aval (rating,n) {

    if (rating >= 1 && rating < 1.5) {
      return (
        <div>
          <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
        </div>
        )
      } 
      else if (rating >= 1.5 && rating < 2) {
      return (
        <div>
          <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
          <IoStarHalf size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'}/>
        </div>
    )
    }
      else if (rating >= 2 && rating < 2.5) {
        return (
            <div>
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            </div>
        )
    }
      else if (rating >= 2.5 && rating < 3) {
        return (
            <div>
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStarHalf size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'}/>
            </div>
        )
    }
      else if (rating >= 3 && rating < 3.5) {
        return (
            <div>
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            </div>
        )        
    }
      else if (rating >= 3.5 && rating < 4) {
        return (
            <div>
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStarHalf size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'}/>
            </div>
        )        
    }
      else if (rating >= 4 && rating < 4.5) {
        return (
            <div>
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            </div>
        )        
    }
      else if (rating >= 4.5 && rating < 5) {
        return (
            <div>
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStarHalf size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'}/>
            </div>
        )        
    }
      else if (rating >= 5) {
        return (
            <div>
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            <IoStar size={n===1 ? 15 : 13} color={n===0 ? 'white' :'a8c9ef'} />
            </div>
        )        
    }
  }
  