import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import { IoClose, IoRemoveCircleOutline, IoAddCircleOutline } from 'react-icons/io5';
import { AddToCart } from '../SQL';


class ModalProd extends Component {

  state = {
    qtd:1,
    variaveis:[],
    res:''
  }

    render () {

      let variacoes;

      if(this.props.variacoes != undefined) {
        variacoes = Object.values(this.props.variacoes).map((i,idx)=>{
          return (
            <div style={{width:'100%'}} key={idx}>
              <p style={{textAlign:'center', color:"white", display:'flex', alignItems:'center', justifyContent:"center", fontWeight:100, marginTop:0}}>Variação {idx+1}</p>
              <div style={{display:'flex', marginTop:0, justifyContent:'space-around'}}>
                {i.map((i2,idx2)=>{
                return  <button key={idx2} onClick={()=>{
                  let array = this.state.variaveis
                  array[idx] = i2
                  this.setState({variaveis:array})
                }} className={this.state.variaveis.includes(i2) ? 'ModalBTNS' :'ModalBTN'}>
                    <p>{i2}</p>
                  </button>
                })}
              </div>
            </div>
        )
        })
      }

      let precoCart = this.props.prod.isPromo ? this.props.prod.precoPromo : this.props.prod.preco

        
        return (
          <div >

            <div style={{backgroundColor:this.state.res === 'success' ? 'rgba(0, 128, 0, .9)' : this.state.res === 'fail' ? 'rgba(255, 0, 0, 1)' : 'rgba(168, 201, 239, .9)', padding:15, borderRadius:10}}>
              <div>
                <p style={{fontWeight:'bold', color:'white  ', borderStyle:'solid', borderWidth:0, borderBottomWidth:1, paddingBottom:5}}>{this.props.nome}</p>
              </div>
              <div>
                <p style={{color:'white'}}>Escolha as variações!</p>
                <div style={{display:'flex', justifyContent:'space-around', flexWrap:'wrap'}}>
                  {variacoes}
                </div>
              </div>

              <div>
                <p style={{color:'white'}}>Vai querer quantos?</p>
                <div style={{display:'flex', alignItems:'center', justifyContent:"center"}}>
                  <button className='QtdBtn' onClick={()=>{if(this.state.qtd > 1) this.setState({qtd:this.state.qtd - 1})}}>
                    <IoRemoveCircleOutline className='QtdIconModal' size={30} />
                  </button>
                  <p style={{color:'white', marginRight:5, fontWeight:'bold'}}>{this.state.qtd}</p>
                  <button className='QtdBtn' onClick={()=>{this.setState({qtd:this.state.qtd + 1})}}>
                    <IoAddCircleOutline  className='QtdIconModal' size={30}  />
                  </button>
                </div>
              </div>
              <div style={{display:'flex', justifyContent:'center', marginTop:10}}>
                <button onClick={()=>{
                            if(this.state.variaveis.length === Object.values(this.props.variacoes).length){
                              AddToCart(this.props.nome, precoCart, this.state.qtd, this.state.variaveis,this.props.prod.tags, window.localStorage.getItem('userID').replace('"','').replace('"',''), Object.values(this.props.prod.imgs)[0], this.props.prod.id, this.props.prod.parametros).then(res=>{
                                if(res){
                                  this.setState({res:'success'})
                                  setTimeout(() => {
                                    this.setState({res:''})
                                  }, 8000);
                                } else {
                                  this.setState({res:'fail'})
                                  setTimeout(() => {
                                    this.setState({res:''})
                                  }, 8000);
                                }
                              })
                            } else {
                              this.setState({res:'VarFail'})
                              setTimeout(() => {
                                this.setState({res:''})
                              }, 8000);
                            }
                          }} style={{color:this.state.variaveis.length === Object.values(this.props.variacoes).length ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, .2)', borderColor:this.state.variaveis.length === Object.values(this.props.variacoes).length ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, .2)'}} className='AddCartBTNModal'>
                  <p>
                    Confirmar
                  </p>
                </button>
              </div>
            </div>
              
          </div>
        );
      }
    }

  export default ModalProd;