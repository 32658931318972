
export default function porcentagem (a,b,n) {

  let A = parseFloat(a.replace('R$','').replace(',','.')).toFixed(2)
  let B = parseFloat(b.replace('R$','').replace(',','.')).toFixed(2)

  var percDiff =  Math.floor((A / B) * 100) - 100;

  if(n === 0){

    return (

        <p style={{position:'absolute', transform:'translate(100px,-125px)', padding:10, backgroundColor:'#a8c9ef', color:"white", fontSize:13}}>{percDiff}%</p>
  
    )


  } else {
    
    return (
        <p style={{position:'absolute', transform:'translate(150px,-200px)', padding:10, backgroundColor:'#a8c9ef', color:"white"}}>{percDiff}%</p>
  
    )
  }


  }
  