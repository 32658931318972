import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Carousel from 'react-multi-carousel';
import Header from '../components/Header';
import Foot from '../components/Foot';
import {IoStar, IoChevronForward, IoAddCircleOutline, IoRemoveCircleOutline, IoClose, IoCartOutline, IoRocket, IoRocketOutline, IoRefresh, IoArrowForwardCircle, IoArrowForwardCircleOutline} from 'react-icons/io5'
import { Link } from 'react-router-dom';
import QSLogo from '../assets/img/QSLogo.png'
import AliLogo from '../assets/img/aliexpress.png'
import ShopeeLogo from '../assets/img/shopee.png'
import { AddToCart, FetchProd, FetchRel, FetchRelOfertas, MostAlgo, SaveAval } from '../SQL';
import aval from '../components/Rating';
import parcela from '../components/Parcela';
import porcentagem from '../components/Porcentagem';
import ModalProd from '../components/ModalProd';
import {IoCheckmarkCircle, IoChevronBack, IoExit, IoFilter} from 'react-icons/io5'
import { Debug, DeleteOrder, FetchAce, FetchMochi, FetchMost, FetchOrders, Login, logout, UpdateRastreio, UpdateStatus, VerifyUser } from '../SQL';
import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}


// import SQL from 
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const responsive2 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};


class Product extends Component {

  state = {
    ZoomedIMG:'',
    variaveis:[],
    qtd:1,
    prod:[],
    RelProds:[],
    res:'',
    MiniModal:false,
    ModalItem:[],
    comment:false,
    nomeI:'',
    comentarioI:'',
    notaI:5,
    imgURL:'',
    avalRes:'',
    zoomAval:false,
    choosenAval:[]
  }

  componentDidMount = async() => {
    let prod = await FetchProd(JSON.parse(window.localStorage.getItem("prodId"))).then(async res => {
      this.setState({
      prod:res,
      ZoomedIMG:Object.values(res.imgs)[0]
    })
    let rel = await FetchRelOfertas(res.tags).then(res => {
      this.setState({RelProds:res})
      console.log(res)
    })

    })

  }

    render(){

      let avalZoom;

        avalZoom = (
          <div onClick={()=>this.setState({zoomAval:false})} className={this.state.zoomAval ? "AvalZoomModal" : 'AvalZoomModalD'} style={{backgroundColor:'rgba(0,0,0,.5)', height:window.innerHeight, width:window.innerWidth, position:'fixed', display:"flex",justifyContent:"center", alignItems:"center"}}>
            <div style={{display:"flex", width:'70%', justifyContent:"space-between", backgroundColor:"#a8c9ef", borderRadius:20}}>
              <div style={{width:'50%', display:"flex", alignItems:"center", justifyContent:"center"}}>
                <img src={URL=this.state.choosenAval.picURL} style={{width:'100%', objectFit:'cover', maxHeight:window.innerHeight-20}}/>
              </div>
              <div style={{width:'50%', padding:20}}>
                <div style={{width:"100%", display:"flex", flexDirection:"column", justifyContent:'space-around'}}>
                  <p style={{textAlign:'right', fontWeight:'bolder', color:"white", margin:0, marginTop:5}}>{this.state.choosenAval.nome}</p>
                  <p style={{textAlign:'left', color:"white", marginTop:5}}>{this.state.choosenAval.comentario}</p>
                  <p className='DescDateM'>{this.state.choosenAval.data}</p>
                </div>
                <div style={{display:"flex", marginTop:5}}>
                  {aval(this.state.choosenAval.nota,0)}
                </div>
              </div>
            </div>
          </div>
        )

      let avalZoomMobile;

        avalZoomMobile = (
          <div onClick={()=>this.setState({zoomAval:false})} className={this.state.zoomAval ? "AvalZoomModal" : 'AvalZoomModalD'} style={{backgroundColor:'rgba(0,0,0,.5)', height:window.innerHeight, width:window.innerWidth, position:'fixed', display:"flex",justifyContent:"center", alignItems:"center"}}>
            <div style={{display:"flex", flexDirection:"column", width:'70%', justifyContent:"space-between", alignItems:"center", backgroundColor:"#a8c9ef", borderRadius:20}}>
              <div style={{width:'100%', display:"flex", alignItems:"center", justifyContent:"center"}}>
                <img src={URL=this.state.choosenAval.picURL} style={{width:'100%', objectFit:'cover', maxHeight:window.innerHeight-20}}/>
              </div>
              <div style={{width:'90%', padding:20}}>
                <div style={{width:"100%", display:"flex", flexDirection:"column", justifyContent:'space-around'}}>
                  <p style={{textAlign:'right', fontWeight:'bolder', color:"white", margin:0, marginTop:5}}>{this.state.choosenAval.nome}</p>
                  <p style={{textAlign:'left', color:"white", marginTop:5}}>{this.state.choosenAval.comentario}</p>
                  <p className='DescDateM'>{this.state.choosenAval.data}</p>
                </div>
                <div style={{display:"flex", marginTop:5}}>
                  {aval(this.state.choosenAval.nota,0)}
                </div>
              </div>
            </div>
          </div>
        )

      let RelList = this.state.RelProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        let precoCart = item.isPromo ? item.precoPromo : item.preco

        let precoTotal = parseFloat(precoCart.replace('R$','').replace(',','.')).toFixed(2)*this.state.qtd

        return (
          <div key={index} className='ProdCont'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : '' }
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDiv'>
              <button className='ProdBtn' onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
                window.location.reload()
                window.scrollTo(0,0)
                }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' >
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let RelListMobile = this.state.RelProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileOfertas'>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome.length > 15 ? item.nome.substring(0,15)+'...' : item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDiv'>
              <button className='ProdBtn' style={{width:'100%'}} onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' style={{width:'100%'}}>
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  


      let prod = this.state.prod

      let ratings=[];

      if(prod.avaliacoes != undefined) {
        Object.values(prod.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })
      }

      let rating = (ratings.reduce((a, b) => a + b, 0)/ratings.length).toFixed(1)

      let preco;

      if(prod.isPromo){
        preco = (
            <div style={{display:'flex', marginTop:10}}>
              <p className='ProdPrecoPromo' > {prod.preco}</p>
              <p className='ProdPromoP' > {prod.precoPromo}</p>
            </div>  
        )
      } else {
        preco = (
            <div style={{display:'flex', marginTop:10}}>
              <p className='ProdPrecoP  ' > {prod.preco}</p>
            </div>  
        )
      }

      let imgs;

      if(prod.imgs != undefined) {

        imgs =  (
          <Carousel responsive={responsive2}>
              {Object.values(prod.imgs).map((i,idx)=>{
                return (
                  <div className='littleImgDiv' key={idx}>
                    <img className='littleImg' onClick={ () => this.setState({ZoomedIMG:i})} src={URL=i}/>
                  </div>
                )
              })}
          </Carousel>
        )

      }

      let infos;

      if(prod.info != undefined) {
        infos = Object.values(prod.info).map((i,idx)=>{
          return (
            <div key={idx} style={{marginTop:80}}>
              <div>
                <p className='DescTitle'>{i.title.toUpperCase()}</p>
              </div>
              <div>
                <p className='DescPara'>{i.desc}</p>
              </div>
              <div>
                <img className='DescImg' src={URL=i.img}/>
              </div>
            </div>

          )
        })
      }

      let infosMobile;

      if(prod.info != undefined) {
        infosMobile = Object.values(prod.info).map((i,idx)=>{
          return (
            <div key={idx} style={{marginTop:80}}>
              <div>
                <p className='DescTitleMobile'>{i.title.toUpperCase()}</p>
              </div>
              <div>
                <p className='DescParaMobile'>{i.desc}</p>
              </div>
              <div>
                <img className='DescImgMobile' src={URL=i.img}/>
              </div>
            </div>

          )
        })
      }

      let caracteristicas;

      if(prod.caracteristicas != undefined) {
        caracteristicas = Object.values(prod.caracteristicas).map((i,idx)=>{
          return (
            <p key={idx} className='DescCat'>{idx+1}. {i}</p>
          )
        })
      }

      let parametros;

      if(prod.parametros != undefined) {
        parametros = Object.values(prod.parametros).map((i,idx)=>{
          return (
            <p key={idx} className='DescMed'>{Object.keys(prod.parametros)[idx]}: {i}</p>
          )
        })
      }

      let caracteristicasMobile;

      if(prod.caracteristicas != undefined) {
        caracteristicasMobile = Object.values(prod.caracteristicas).map((i,idx)=>{
          return (
            <p key={idx} style={{fontSize:12, width:"100%"}} className='DescCat'>{idx+1}. {i}</p>
          )
        })
      }

      let parametrosMobile;

      if(prod.parametros != undefined) {
        parametrosMobile = Object.values(prod.parametros).map((i,idx)=>{
          return (
            <p key={idx} style={{fontSize:12, width:"100%"}} className='DescMed'>{Object.keys(prod.parametros)[idx]}: {i}</p>
          )
        })
      }

      let avaliacoes;

      if(prod.avaliacoes != undefined) {
        avaliacoes = Object.values(prod.avaliacoes).map((i,idx)=>{
          return (
            <div onClick={()=>this.setState({choosenAval:i, zoomAval:true})} key={idx} className='AvalDiv' style={{width:'20%', marginRight:20,marginBottom:20, marginTop:5}}>
              <img className='AvalImg' src={URL=i.picURL}/>
              <div style={{display:"flex", marginTop:5}}>
                {aval(i.nota)}
              </div>
              <div>
                <p style={{textAlign:'right', fontWeight:'bolder', color:"gray", margin:0, marginTop:5}}>{i.nome}</p>
                <p style={{textAlign:'left', color:"gray", marginTop:5}}>{i.comentario}</p>
                <p className='DescDate'>{i.data}</p>
              </div>
            </div>
          )
        })
      }

      let avaliacoesMobile;

      if(prod.avaliacoes != undefined) {
        avaliacoesMobile = Object.values(prod.avaliacoes).map((i,idx)=>{
          return (
            <div onClick={()=>this.setState({choosenAval:i, zoomAval:true})} key={idx} className='AvalDiv' style={{width:'40%', marginRight:20,marginBottom:20, marginTop:5}}>
              <img className='AvalImgMobile' src={URL=i.picURL}/>
              <div style={{display:"flex", marginTop:5}}>
                {aval(i.nota)}
              </div>
              <div>
                <p style={{textAlign:'right', fontWeight:'bolder', color:"gray", margin:0, marginTop:5, fontSize:13}}>{i.nome}</p>
                <p style={{textAlign:'left', color:"gray", marginTop:5, fontSize:13}}>{i.comentario.substring(0,100)} <b style={{fontSize:20}}>...</b></p>
                <p className='DescDate'>{i.data}</p>
              </div>
            </div>
          )
        })
      }

      let variacoes;

      if(prod.variacoes != undefined) {
        variacoes = Object.values(prod.variacoes).map((i,idx)=>{
          return (
            <div key={idx}>
              <p style={{textAlign:'left', color:"gray", display:'flex', alignItems:'center', textTransform:'uppercase'}}>Variação {idx+1}: <p style={{marginRight:5, marginLeft:5, color:'#639ee2', fontWeight:'bold'}}> {this.state.variaveis[idx]} </p></p>
              <div style={{display:'flex', marginTop:0}}>
                {i.map((i2,idx2)=>{
                return  <button key={idx2} onClick={()=>{
                  if(prod.idNumber === undefined){

                    let array = this.state.variaveis
                    array[idx] = i2
                    this.setState({
                      variaveis:array,
                      ZoomedIMG:prod.imgs[idx2]
                    })
                  }
                }} className={this.state.variaveis.includes(i2) ? 'ProdVarBTNS' :'ProdVarBTN'}>
                    <p>{i2}</p>
                  </button>
                })}
              </div>
            </div>
        )
        })
      }

      let variacoesMobile;

      if(prod.variacoes != undefined) {
        variacoesMobile = Object.values(prod.variacoes).map((i,idx)=>{
          return (
            <div key={idx}>
              <p style={{textAlign:'left', color:"gray", display:'flex', alignItems:'center', textTransform:'uppercase'}}>Variação {idx+1}: <p style={{marginRight:5, marginLeft:5, color:'#639ee2', fontWeight:'bold'}}> {this.state.variaveis[idx]} </p></p>
              <div style={{display:'flex', marginTop:0}}>
                {i.map((i2,idx2)=>{
                return  <button key={idx2} onClick={()=>{
                  if(prod.idNumber === undefined){

                    let array = this.state.variaveis
                    array[idx] = i2
                    this.setState({
                      variaveis:array,
                      ZoomedIMG:prod.imgs[idx2]
                    })
                  }
                }} className={this.state.variaveis.includes(i2) ? 'ProdVarBTNSMobile' :'ProdVarBTNMobile'}>
                    <p>{i2}</p>
                  </button>
                })}
              </div>
            </div>
        )
        })
      }

      let miniModal = this.state.MiniModal ? (
        <div className="MiniModal">
          <div style={{display:'flex', justifyContent:'flex-end'}}>
            <button onClick={()=>{this.setState({MiniModal:false})}} style={{backgroundColor:"transparent", borderStyle:'none', cursor:'pointer'}}>
              <IoClose color='gray' size={25}/>
            </button>
          </div>
          <ModalProd nome={this.state.ModalItem.nome} variacoes={this.state.ModalItem.variacoes} prod={this.state.ModalItem}/>
        </div>
      ) : ''

      let precoCart = prod.isPromo ? prod.precoPromo : prod.preco

      let precoTotal = precoCart != undefined ? (parseFloat(precoCart.replace('R$','').replace(',','.')).toFixed(2)*this.state.qtd).toFixed(2) : ''

      let painelControle;

        painelControle = (
          <div className={this.state.comment ? 'PainelControleP' : 'PainelControlePD'}>
              <div style={{display:'flex', justifyContent:'flex-end', height:20}}>
                <IoClose onClick={()=>{this.setState({comment:false})}} className='CommentClose' />
              </div>
            <p style={{color:'white', fontWeight:'bold', paddingBottom:10, borderStyle:'solid', borderWidth:0, borderBottomWidth:1, fontSize:'large'}}>FAÇA SUA AVALIAÇÃO!</p>
            <p style={{color:'white', marginBottom:5}}>NOME</p>
            <input style={{textAlign:"center", padding:2,width:'100%',color:'gray' ,outline:'none',borderColor:'gainsboro', borderRadius:5, borderStyle:'solid', borderWidth:1}} value={this.state.nomeI} onChange={i=>this.setState({nomeI:i.target.value})} />
            <p style={{color:'white', marginBottom:5}}>COMENTÁRIO</p>
            <textarea type={'text'}  style={{width:'100%',display:'block',color:'gray' ,outline:'none',borderColor:'gainsboro', borderRadius:5, borderStyle:'solid', borderWidth:1, marginRight:'auto', marginLeft:'auto'}} value={this.state.comentarioI} onChange={i=>this.setState({comentarioI:i.target.value})} />
            <p style={{color:'white', marginBottom:5}}>NOTA</p>
            <div style={{display:"flex", justifyContent:'space-between', alignItems:"center"}}>
              <IoChevronBack onClick={()=>{
                if(this.state.notaI > 0){
                  this.setState({notaI:this.state.notaI-0.5})
                }
              }} className='Chevron'/>
              <p style={{fontSize:'large', fontWeight:'bold', color:'white', pointerEvents:'none'}}>{this.state.notaI}</p>
              <IoChevronForward onClick={()=>{
                if(this.state.notaI < 5 ){
                  this.setState({notaI:this.state.notaI+0.5})
                }
              }} className='Chevron'/>
            </div>
              <p style={{color:'white', marginBottom:5}}>IMAGEM</p>
              <p style={{fontSize:10, color:'white'}}>{this.state.imgURL.name != undefined ? this.state.imgURL.name.substring(0,30)+'...' : ''}</p>
              <label className='ImgBtn'>
                <input onChange={res=> this.setState({imgURL:res.target.files[0]})} style={{display:'none'}} accept="image/gif, image/jpeg, image/png" type={'file'}/>
                <p style={{padding:0, margin:0, fontSize:'small'}}>Selecione a imagem</p>
              </label>
            <button onClick={()=>{
              SaveAval(this.state.nomeI,this.state.comentarioI,this.state.notaI, this.state.imgURL,JSON.parse(window.localStorage.getItem("prodId"))).then(res=>{
                this.setState({
                  avalRes:res ? 's' : 'f'
                })
                if(res){
                  this.setState({
                    nomeI:'',
                    comentarioI:'',
                    notaI:'',
                    imgURL:'',
                    comment:false
                  })
                  setTimeout(() => {                  
                    FetchProd(JSON.parse(window.localStorage.getItem("prodId"))).then(async res => {
                      this.setState({
                      prod:res,
                      ZoomedIMG:Object.values(res.imgs)[0],
                      avalRes:''
                    })
                  })
                  }, 2000);
                }
              })
            }} style={{display:'block', marginLeft:'auto ', marginRight:'auto', marginTop:20, backgroundColor:"transparent", borderStyle:'none', color:'#990000', cursor:"pointer"}}>
              <IoCheckmarkCircle className={this.state.nomeI === '' || this.state.comentarioI === '' || this.state.imgURL === '' ? 'ConfirmAvalD' : this.state.avalRes==='s' ? 'ConfirmAvalS' : !this.state.avalRes==='f' ? 'ConfirmAvalF' : 'ConfirmAval'} />
            </button>
          </div>

        )

      let painelControleMobile;

        painelControleMobile = (
          <div className={this.state.comment ? 'PainelControlePM' : 'PainelControlePD'}>
              <div style={{display:'flex', justifyContent:'flex-end', height:20}}>
                <IoClose onClick={()=>{this.setState({comment:false})}} className='CommentClose' />
              </div>
            <p style={{color:'white', fontWeight:'bold', paddingBottom:10, borderStyle:'solid', borderWidth:0, borderBottomWidth:1, fontSize:'large'}}>FAÇA SUA AVALIAÇÃO!</p>
            <p style={{color:'white', marginBottom:5}}>NOME</p>
            <input style={{textAlign:"center", padding:2,width:'100%',color:'gray' ,outline:'none',borderColor:'gainsboro', borderRadius:5, borderStyle:'solid', borderWidth:1}} value={this.state.nomeI} onChange={i=>this.setState({nomeI:i.target.value})} />
            <p style={{color:'white', marginBottom:5}}>COMENTÁRIO</p>
            <textarea type={'text'}  style={{width:'100%',display:'block',color:'gray' ,outline:'none',borderColor:'gainsboro', borderRadius:5, borderStyle:'solid', borderWidth:1, marginRight:'auto', marginLeft:'auto'}} value={this.state.comentarioI} onChange={i=>this.setState({comentarioI:i.target.value})} />
            <p style={{color:'white', marginBottom:5}}>NOTA</p>
            <div style={{display:"flex", justifyContent:'space-between', alignItems:"center"}}>
              <IoChevronBack onClick={()=>{
                if(this.state.notaI > 0){
                  this.setState({notaI:this.state.notaI-0.5})
                }
              }} className='Chevron'/>
              <p style={{fontSize:'large', fontWeight:'bold', color:'white', pointerEvents:'none'}}>{this.state.notaI}</p>
              <IoChevronForward onClick={()=>{
                if(this.state.notaI < 5 ){
                  this.setState({notaI:this.state.notaI+0.5})
                }
              }} className='Chevron'/>
            </div>
              <p style={{color:'white', marginBottom:5}}>IMAGEM</p>
              <p style={{fontSize:10, color:'white'}}>{this.state.imgURL.name != undefined ? this.state.imgURL.name.substring(0,30)+'...' : ''}</p>
              <label className='ImgBtn'>
                <input onChange={res=> this.setState({imgURL:res.target.files[0]})} style={{display:'none'}} accept="image/gif, image/jpeg, image/png" type={'file'}/>
                <p style={{padding:0, margin:0, fontSize:'small'}}>Selecione a imagem</p>
              </label>
            <button onClick={()=>{
              SaveAval(this.state.nomeI,this.state.comentarioI,this.state.notaI, this.state.imgURL,JSON.parse(window.localStorage.getItem("prodId"))).then(res=>{
                this.setState({
                  avalRes:res ? 's' : 'f'
                })
                if(res){
                  this.setState({
                    nomeI:'',
                    comentarioI:'',
                    notaI:'',
                    imgURL:'',
                    comment:false
                  })
                  setTimeout(() => {                  
                    FetchProd(JSON.parse(window.localStorage.getItem("prodId"))).then(async res => {
                      this.setState({
                      prod:res,
                      ZoomedIMG:Object.values(res.imgs)[0],
                      avalRes:''
                    })
                  })
                  }, 2000);
                }
              })
            }} style={{display:'block', marginLeft:'auto ', marginRight:'auto', marginTop:20, backgroundColor:"transparent", borderStyle:'none', color:'#990000', cursor:"pointer"}}>
              <IoCheckmarkCircle className={this.state.nomeI === '' || this.state.comentarioI === '' || this.state.imgURL === '' ? 'ConfirmAvalD' : this.state.avalRes==='s' ? 'ConfirmAvalS' : !this.state.avalRes==='f' ? 'ConfirmAvalF' : 'ConfirmAval'} />
            </button>
          </div>

        )

      let response;

      if(this.state.res != ''){
        response = (
          <div className='Response'>
            <p style={{ color:"white", fontWeight:'light'}}>{this.state.res === 'success' ? 'Produto adicionado com sucesso!' : this.state.res === 'fail' ? 'Houve um erro, contate o suporte!' : this.state.res === 'VarFail' ? 'Escolha as variações!' : ''}</p>
          </div>
        )  
      }  

      let media;

      if(this.state.ZoomedIMG.includes('.mp4') || this.state.ZoomedIMG.includes('.webm') || this.state.ZoomedIMG.includes('.ogg')){
        media = (
          <video className='Img' src={URL=this.state.ZoomedIMG}/>
        )
      } else {
        media = (
          <img className='Img' src={URL=this.state.ZoomedIMG}/>
        )
      }

      let prodInfo;

      if(prod.idNumber != undefined) {
        prodInfo = (
          <div style={{ width:'40%', display:'flex', flexDirection:"column", justifyContent:'space-between'}}>
          <div>
            <p style={{textAlign:'left', color:"gray", fontWeight:'bold', fontSize:20, marginBottom:0, textTransform:'uppercase', borderStyle:'solid', borderWidth:0, borderBottomWidth:1, borderColor:"gainsboro"}}>{prod.nome}</p>
          <div style={{display:"flex", marginTop:5}}>
              {aval(rating,1)}
            </div>
          </div>

          <div style={{display:"flex", alignItems:'center'}}>
            <p style={{color:'gainsboro', marginRight:10}}>Disponivel e entregue por </p>
            <img style={{width:'20%', marginRight:20}} src={prod.linkAfiliado.includes('shopee') ? ShopeeLogo : prod.linkAfiliado.includes('ali') ? AliLogo : QSLogo}/>
          </div>


          <div style={{display:'flex', marginTop:10}}>
              <p className='ProdPrecoO' > {prod.preco}</p>
            </div>  
            <div>
            {variacoes}
          </div>
            <div>
              <a href={prod.linkAfiliado} target="_blank" className='AddCartBTN' style={{textDecoration:'none', display:"flex", justifyContent:"center"}} >
                <p>Comprar no site</p>
                <IoArrowForwardCircleOutline size={30} className='CartIcon' />
              </a>
            </div>

          <div >
            <div style={{display:'flex', alignItems:"center", margin:0, padding:0}}>                        
              <IoRefresh color='#a8c9ef' size={35} style={{marginRight:10}}/>
                <p style={{margin:0, fontSize:'small',color:"#a8c9ef", display:"flex", alignItems:"center"}}>Devolução <p style={{fontWeight:'bold' , marginRight:5, marginLeft:5}}> gratuita, </p> em até <p style={{fontWeight:'bold' , marginRight:5, marginLeft:5}}> 7 dias </p> após o recebimento!</p>
            </div>
          </div>
        </div>

        )
      } else {
        prodInfo = (
          <div style={{ width:'40%', display:'flex', flexDirection:"column", justifyContent:'space-between'}}>
          <div>
            <p style={{textAlign:'left', color:"gray", fontWeight:'bold', fontSize:30, marginBottom:0, textTransform:'uppercase'}}>{prod.nome}</p>
          </div>
          <div style={{display:"flex", alignItems:'center'}}>
            <p style={{color:'gainsboro', marginRight:10}}>Disponivel e entregue por </p>
            <img style={{width:'20%', marginRight:20}} src={QSLogo}/>
          </div>
            <div style={{display:"flex", marginTop:5}}>
              {aval(rating)}
            </div>
            {preco}
            {prod.preco != undefined ? parcela(prod.preco, 0) : ''}
          <div>
            {variacoes}
          </div>
          <div style={{display:'flex', marginTop:30, alignItems:"center"}}>
            <div style={{display:'flex', alignItems:'center'}}>
              <button className='QtdBtn' onClick={()=>{if(this.state.qtd > 1) this.setState({qtd:this.state.qtd - 1})}}>
                <IoRemoveCircleOutline className='QtdIcon' size={30} />
              </button>
              <p style={{color:'gray', marginRight:5, fontWeight:'bold'}}>{this.state.qtd}</p>
              <button className='QtdBtn' onClick={()=>{this.setState({qtd:this.state.qtd + 1})}}>
                <IoAddCircleOutline  className='QtdIcon' size={30}  />
              </button>
            </div>
            <div>
              <button  className='AddCartBTN' onClick={()=>{
                  if(this.state.variaveis.length === Object.values(prod.variacoes).length){
                    AddToCart(prod.nome, precoCart, this.state.qtd, this.state.variaveis,prod.tags, window.localStorage.getItem('userID').replace('"','').replace('"',''), Object.values(prod.imgs)[0], prod.id, prod.parametros).then(res=>{
                      if(res){
                        this.setState({res:'success'})
                        setTimeout(() => {
                          this.setState({res:''})
                        }, 8000);
                      } else {
                        this.setState({res:'fail'})
                        setTimeout(() => {
                          this.setState({res:''})
                        }, 8000);
                      }
                    })
                  } else {
                    this.setState({res:'VarFail'})
                    setTimeout(() => {
                      this.setState({res:''})
                    }, 8000);
                  }
                }}>
                <p>Adicionar ao carrinho</p>
                <IoCartOutline size={30} className='CartIcon' />
              </button>
            </div>

          </div>
          <div >
            <div style={{display:'flex', alignItems:"center", marginTop:20, padding:0}}>
              <IoRocketOutline color='#a8c9ef' size={35} style={{marginRight:10}}/>
                <p style={{margin:0, fontSize:'small',color:"#a8c9ef", display:"flex", alignItems:"center"}}><p style={{fontWeight:'bold' , marginRight:5}}>Entrega rápida </p> para <p style={{fontWeight:'bold' , marginRight:5, marginLeft:5}}> Brasília </p> e Região!</p>
            </div>
            <div style={{display:'flex', alignItems:"center", margin:0, padding:0}}>                        
              <IoRefresh color='#a8c9ef' size={35} style={{marginRight:10}}/>
                <p style={{margin:0, fontSize:'small',color:"#a8c9ef", display:"flex", alignItems:"center"}}>Devolução <p style={{fontWeight:'bold' , marginRight:5, marginLeft:5}}> gratuita, </p> em até <p style={{fontWeight:'bold' , marginRight:5, marginLeft:5}}> 7 dias </p> após o recebimento!</p>
            </div>
          </div>
        </div>

        )
      }

      let prodInfoMobile;

      if(prod.idNumber != undefined) {
        prodInfoMobile = (
          <div style={{ display:'flex', flexDirection:"column", justifyContent:'space-between'}}>
          <div>
            <p style={{textAlign:'left', color:"gray", fontWeight:'bold', fontSize:20, marginBottom:0, textTransform:'uppercase', borderStyle:'solid', borderWidth:0, borderBottomWidth:1, borderColor:"gainsboro"}}>{prod.nome}</p>
          <div style={{display:"flex", marginTop:5}}>
              {aval(rating,1)}
            </div>
          </div>

          <div style={{display:"flex", alignItems:'center'}}>
            <p style={{color:'gainsboro', marginRight:10}}>Disponivel e entregue por </p>
            <img style={{width:'20%', marginRight:20}} src={prod.linkAfiliado.includes('shopee') ? ShopeeLogo : prod.linkAfiliado.includes('ali') ? AliLogo : QSLogo}/>
          </div>


          <div style={{display:'flex', marginTop:10}}>
              <p className='ProdPrecoO' > {prod.preco}</p>
            </div>  
            <div>
            {variacoesMobile}
          </div>
            <div>
              <a href={prod.linkAfiliado} target="_blank" className='AddCartBTN' style={{textDecoration:'none', display:"flex", justifyContent:"center", marginTop:20, marginBottom:20}} >
                <p>Comprar no site</p>
                <IoArrowForwardCircleOutline size={30} className='CartIcon' />
              </a>
            </div>

          <div >
            <div style={{display:'flex', alignItems:"center", margin:0, padding:0}}>                        
              <IoRefresh color='#a8c9ef' size={20} style={{marginRight:10}}/>
                <p style={{margin:0, fontSize:10,color:"#a8c9ef"}}>Devolução <b>gratuita,</b> em até <b>7 dias</b> após o recebimento!</p>
            </div>
          </div>
        </div>

        )
      } else {
        prodInfoMobile = (
          <div style={{ display:'flex', flexDirection:"column", justifyContent:'space-between'}}>
          <div>
            <p style={{textAlign:'left', color:"gray", fontWeight:'bold', fontSize:30, marginBottom:20, textTransform:'uppercase'}}>{prod.nome}</p>
          </div>
          <div style={{display:"flex", alignItems:'center'}}>
            <p style={{color:'gainsboro', marginRight:10, fontSize:10}}>Disponivel e entregue por </p>
            <img style={{width:'30%', marginRight:20}} src={QSLogo}/>
          </div>
            <div style={{display:"flex", marginTop:5}}>
              {aval(rating, 1)}
            </div>
            {preco}
            {prod.preco != undefined ? parcela(prod.preco,0) : ''}
          <div>
            {variacoesMobile}
          </div>
          <div style={{display:'flex', alignItems:"center", flexDirection:"column"}}>
            <div style={{display:'flex', alignItems:'center', marginBottom:10, marginTop:10}}>
              <button className='QtdBtn' onClick={()=>{if(this.state.qtd > 1) this.setState({qtd:this.state.qtd - 1})}}>
                <IoRemoveCircleOutline className='QtdIcon' size={30} />
              </button>
              <p style={{color:'gray', marginRight:5, fontWeight:'bold', fontSize:20}}>{this.state.qtd}</p>
              <button className='QtdBtn' onClick={()=>{this.setState({qtd:this.state.qtd + 1})}}>
                <IoAddCircleOutline  className='QtdIcon' size={30}  />
              </button>
            </div>
            <div>
              <button  className='AddCartBTNMobile' onClick={()=>{
                  if(this.state.variaveis.length === Object.values(prod.variacoes).length){
                    AddToCart(prod.nome, precoCart, this.state.qtd, this.state.variaveis,prod.tags, window.localStorage.getItem('userID').replace('"','').replace('"',''), Object.values(prod.imgs)[0], prod.id, prod.parametros).then(res=>{
                      if(res){
                        this.setState({res:'success'})
                        setTimeout(() => {
                          this.setState({res:''})
                        }, 8000);
                      } else {
                        this.setState({res:'fail'})
                        setTimeout(() => {
                          this.setState({res:''})
                        }, 8000);
                      }
                    })
                  } else {
                    this.setState({res:'VarFail'})
                    setTimeout(() => {
                      this.setState({res:''})
                    }, 8000);
                  }
                }}>
                <p>Adicionar ao carrinho</p>
                <IoCartOutline size={20} className='CartIcon' />
              </button>
            </div>

          </div>
          <div >
            <div style={{display:'flex', alignItems:"center", marginTop:40, padding:0}}>
              <IoRocketOutline color='#a8c9ef' size={25} style={{marginRight:10}}/>
                <p style={{ fontSize:12,color:"#a8c9ef", textAlign:'left'}}><b>Entrega rápida</b> para <b>Brasília</b> e Região!</p>
            </div>
            <div style={{display:'flex', alignItems:"center", margin:0, padding:0}}>                        
              <IoRefresh color='#a8c9ef' size={25} style={{marginRight:10}}/>
                <p style={{ fontSize:12,color:"#a8c9ef", textAlign:"left"}}>Devolução  <b>gratuita</b>, em até <b>7 dias</b> após o recebimento!</p>
            </div>
          </div>
        </div>

        )
      }

        return (
          <div className="App">
            <Desktop>

            {painelControle}  
            {response}
            {miniModal}
        
              <Header/>

              <div style={{width:'80%', marginLeft:'auto', marginRight:'auto'}}>
                <div style={{alignItems:'center', display:"flex"}}>
                <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'} style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                  <p className='Chain'>Início</p>
                  </Link>
                  <IoChevronForward color='gray' size={10} style={{marginTop:3, marginRight:3, marginLeft:3}}/> 
                <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'}  style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                    <p className='Chain'>Categoria</p>
                  </Link>
                  <IoChevronForward color='gray' size={10} style={{marginTop:3, marginRight:3, marginLeft:3}}/> 
                <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'}  style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                    <p className='Chain'>{prod.nome}</p>
                  </Link>
                </div>

                <div style={{flexWrap:'wrap', justifyContent:'center', flexDirection:"column"}}>      
                  <div style={{display:"flex", flexWrap:'wrap', justifyContent:'space-between', height:600}}>

                    <div style={{width:'50%', flexDirection:"column", flexWrap:'wrap', height:'100%'}}>
                      <div style={{ height:"80%", alignItems:'center', justifyContent:'center', display:"flex"}}>
                        {media}
                      </div>
                      <div style={{ height:'20%', marginTop:10, marginBottom:'auto'}}>
                        {imgs}
                      </div>
                    </div>

                    {prodInfo}

                  </div>
                    <div style={{borderStyle:'solid', borderColor:"#a8c9ef", borderWidth:0, borderBottomWidth:1, marginTop:40}}>
                      <p style={{fontSize:'larger', color:"#a8c9ef", fontWeight:'bold', backgroundColor:'white'}}>INFORMAÇÕES</p>
                    </div>

                    <div>
                      {infos}
                      <div style={{display:'flex', justifyContent:'space-between', marginTop:40}}>
                        <div style={{width:'50%'}}>
                          <p className='DescCat'><b>Características:</b></p>
                          {caracteristicas}
                        </div>
                        <div>
                          <p className='DescMed'><b>Parâmetros:</b></p>
                          {parametros}
                        </div>
                      </div>
                    </div>

                    <div style={{borderStyle:'solid', borderColor:"#a8c9ef", borderWidth:0, borderBottomWidth:1, marginTop:40}}>
                      <p style={{fontSize:'larger', color:"#a8c9ef", fontWeight:'bold', backgroundColor:'white'}}>AVALIAÇÕES</p>
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', marginBottom:20, alignItems:'center'}}>
                      <div style={{display:'flex', flexDirection:"column", alignItems:"center"}}>
                        <p style={{backgroundColor:'#a8c9ef', color:'white', borderRadius:5, height:'50%', width:'50%', padding:10, fontSize:40, margin:0, marginTop:30, marginBottom:15}}>{rating}</p>
                        <p style={{color:'gray', marginTop:0}}>Baseado em <b style={{color:'#a8c9ef'}}> {ratings.length} avaliações</b></p>
                      </div>
                      <button onClick={()=>this.setState({comment:true})} className='DescAval'>
                        <p>AVALIAR</p>
                      </button>
                    </div>

                    <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                      {avaliacoes}
                    </div>

                  <div style={{width:'100%', display:'flex', justifyContent:'space-between', paddingRight:60, alignItems:"center"}}>
                    <p className='verMaisL'>Ver mais</p>
                    <p className='Title'>Relacionados</p>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} style={{textDecoration:'none'}} to={'/'}>
                      <p className='verMaisR'>Ver mais</p>
                    </Link> 
                  </div>

                    

                    <div className='CarouselCont'>
                  <Carousel slidesToSlide={4} showDots={true} arrows={false} responsive={responsive}>
                    {RelList}
                  </Carousel>
                </div>


                </div>

              </div>
              {avalZoom}
              <Foot/>
            </Desktop>
            <Mobile>

            {painelControleMobile}  
            {response}
            {miniModal}
        
              <Header/>

              <div style={{width:'80%', marginLeft:'auto', marginRight:'auto'}}>
                <div style={{alignItems:'center', display:"flex"}}>
                <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'} style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                  <p className='Chain' style={{fontSize:12}}>Início</p>
                  </Link>
                  <IoChevronForward color='gray' size={10} style={{marginTop:3, marginRight:3, marginLeft:3}}/> 
                <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'}  style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                    <p className='Chain' style={{fontSize:12}}>Categoria</p>
                  </Link>
                  <IoChevronForward color='gray' size={10} style={{marginTop:3, marginRight:3, marginLeft:3}}/> 
                <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'}  style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                    <p className='Chain' style={{fontSize:12}}>{prod.nome}</p>
                  </Link>
                </div>

                <div style={{ flexWrap:'wrap', justifyContent:'center', flexDirection:"column"}}>      
                  <div >

                    <div style={{ height:'100%'}}>
                      <div style={{ height:"80%", alignItems:'center', justifyContent:'center', display:"flex", marginBottom:20, marginTop:30}}>
                        <img className='Img' src={URL=this.state.ZoomedIMG}/>
                      </div>
                      <div style={{ height:'20%', marginTop:10, marginBottom:20}}>
                        {imgs}
                      </div>
                    </div>

                  {prodInfoMobile}

                  </div>
                    <div style={{borderStyle:'solid', borderColor:"#a8c9ef", borderWidth:0, borderBottomWidth:1, marginTop:40}}>
                      <p style={{fontSize:'larger', color:"#a8c9ef", fontWeight:'bold', backgroundColor:'white'}}>INFORMAÇÕES</p>
                    </div>

                    <div>
                      {infosMobile}
                      <div style={{display:'flex', justifyContent:'space-between', marginTop:40}}>
                        <div style={{width:'50%'}}>
                          <p className='DescCat' style={{fontSize:13}}><b>Características:</b></p>
                          {caracteristicasMobile}
                        </div>
                        <div>
                          <p className='DescMed' style={{fontSize:13}}><b>Parâmetros:</b></p>
                          {parametrosMobile}
                        </div>
                      </div>
                    </div>

                    <div style={{borderStyle:'solid', borderColor:"#a8c9ef", borderWidth:0, borderBottomWidth:1, marginTop:40}}>
                      <p style={{fontSize:'larger', color:"#a8c9ef", fontWeight:'bold', backgroundColor:'white'}}>AVALIAÇÕES</p>
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', marginBottom:20, alignItems:'center'}}>
                      <div style={{display:'flex', flexDirection:"column", alignItems:"center"}}>
                        <p style={{backgroundColor:'#a8c9ef', color:'white', borderRadius:5, height:'50%', width:'50%', padding:10, fontSize:40, margin:0, marginTop:30, marginBottom:15}}>{rating}</p>
                        <p style={{color:'gray', marginTop:0}}>Baseado em <b style={{color:'#a8c9ef'}}> {ratings.length} avaliações</b></p>
                      </div>
                      <button onClick={()=>this.setState({comment:true})} className='DescAval'>
                        <p>AVALIAR</p>
                      </button>
                    </div>

                    <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                      {avaliacoesMobile}
                    </div>

                  <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:"center"}}>
                    <p className='Title' style={{fontSize:25}}>Relacionados</p>
                  </div>

                    

                    <div className='CarouselCont'>
                  <Carousel slidesToSlide={4} showDots={true} arrows={false} responsive={responsive}>
                    {RelListMobile}
                  </Carousel>
                </div>


                </div>

              </div>
              {avalZoomMobile}
              <Foot/>

            </Mobile>
         
          </div>
        );
    }
    }


export default Product;