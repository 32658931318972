
export default function parcela (preco, n) {

  let precoInt = parseFloat(preco.replace('R$','').replace(',','.')).toFixed(2)

  let precoComp = ((precoInt/5).toFixed(2)).toString().replace('.',',')

  return (
    <div style={{display:"flex", alignItems:"flex-end", margin:0, marginTop:n != 0 ? 5 : 10, width:'101%', fontSize:n != 0 && n!= 1 ? 10 : n === 1 ? 12 : 15}}>
      <p style={{color:'gray', margin:0, fontSize:n != 0 && n!= 1 ? 10 : n === 1 ? 12 : 15}} > 5x de </p>
      <p style={{color:'green', margin:0, marginRight:5, marginLeft:5, fontWeight:n != 0 ? 'normal' : 'bold', fontSize:n != 0 && n!= 1 ? 10 : n === 1 ? 12 : 15}} > R${precoComp}</p>
      <p style={{color:'gray', margin:0, fontSize:n != 0 && n!= 1 ? 0 : n === 1 ? 12 : 15}} > sem juros </p>
    </div>

  )

  }
  