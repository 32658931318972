import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoClose, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FetchAce, FetchMochi,  FetchEla, FetchEle, FetchPromo, MostAlgo  } from '../SQL';
import ModalProd from '../components/ModalProd';


import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}


  

class Catalog extends Component {


  state = {
    MochiProds:[],
    AceProds:[],
    EleProds:[],
    ElaProds:[],
    PromoProds:[],
    categoria:JSON.parse(window.localStorage.getItem("categoria")),
    MiniModal:false,

  }

  componentDidMount = async() => {
    let mochi = await FetchMochi(0).then(res => this.setState({MochiProds:res}))
    let ace = await FetchAce(0).then(res => this.setState({AceProds:res}))
    let ele = await FetchEle(0).then(res => this.setState({EleProds:res}))
    let ela = await FetchEla(0).then(res => this.setState({ElaProds:res}))
    let promo = await FetchPromo(0).then(res => this.setState({PromoProds:res}))

  }


    render(){

      let MochiList = this.state.MochiProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = (ratings.reduce((a, b) => a + b, 0)/ratings.length).toFixed(1)

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContCat'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn'  onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'}>
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' >
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let PromoList = this.state.PromoProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = (ratings.reduce((a, b) => a + b, 0)/ratings.length).toFixed(1)

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContCat'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn'  onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'}>
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' >
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let AceList = this.state.AceProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContCat'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn'  onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'}>
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn'>
                  <p  className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let EleList = this.state.EleProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContCat'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn'>
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let ElaList = this.state.ElaProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContCat'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn'>
                  <p  className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let MochiListMobile = this.state.MochiProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileCat'>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' style={{width:'100%'}} onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' style={{width:'100%'}}>
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let PromoListMobile = this.state.PromoProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileCat'>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' style={{width:'100%'}} onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' style={{width:'100%'}}>
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let AceListMobile = this.state.AceProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileCat'>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' style={{width:'100%'}} onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' style={{width:'100%'}}>
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let EleListMobile = this.state.EleProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileCat'>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' style={{width:'100%'}} onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' style={{width:'100%'}}>
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  

      let ElaListMobile = this.state.ElaProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileCat'>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
              {parcela(item.preco)}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' style={{width:'100%'}} onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'} >
                  <p>Ver produto</p>
                </Link>
              </button>
              <button onClick={()=> {
                if(this.state.MiniModal){
                  this.setState({MiniModal:false})
                  setTimeout(() => {
                    this.setState({MiniModal:true,ModalItem:item})
                  }, 100);
                } else {
                  this.setState({
                    MiniModal:true,
                    ModalItem:item
                    })
                }
              }} className='ProdBtn' style={{width:'100%'}}>
                  <p className='ProdLink'>Adicionar ao carrinho</p>
              </button>
            </div>
          </div>

        )
      })  


      let list = this.state.categoria === 'Pets' ? MochiList : this.state.categoria === 'Casa' ? AceList : this.state.categoria === 'Ele' ? EleList : this.state.categoria === 'Ela' ? ElaList : PromoList
      let listMobile = this.state.categoria === 'Pets' ? MochiListMobile : this.state.categoria === 'Casa' ? AceListMobile : this.state.categoria === 'Ele' ? EleListMobile : this.state.categoria === 'Ela' ? ElaListMobile : PromoListMobile

      let miniModal = this.state.MiniModal ? (
        <div className="MiniModal">
          <div style={{display:'flex', justifyContent:'flex-end'}}>
            <button onClick={()=>{this.setState({MiniModal:false})}} style={{backgroundColor:"transparent", borderStyle:'none', cursor:'pointer'}}>
              <IoClose color='gray' size={25}/>
            </button>
          </div>
          <ModalProd nome={this.state.ModalItem.nome} variacoes={this.state.ModalItem.variacoes} prod={this.state.ModalItem}/>
        </div>
      ) : ''


        return (
          <div className="App">
            <Desktop>
              {miniModal}
              <Header/>
        
              <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white"}}>
                <div style={{display:'flex', justifyContent:"space-between"}}>
                  <div style={{alignItems:'center', display:"flex"}}>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'} style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                    <p className='Chain'>home</p>
                    </Link>
                    <IoChevronForward color='gray' size={10} style={{marginTop:3, marginRight:3, marginLeft:3}}/> 
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'}  style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                      <p className='Chain'>category</p>
                    </Link>
                  </div>
                  <p style={{fontWeight:'bold', color:"#a8c9ef", fontSize:"large", textTransform:'uppercase'}}>{this.state.categoria}</p>
                </div>
                
                <div style={{display:"flex", flexWrap:'wrap', justifyContent:'space-evenly'}}>      
                  {list}
                </div>

              </div>
        
              <Foot/>
            </Desktop>
            <Mobile>
              {miniModal}
              <Header/>
        
              <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white"}}>
                <div style={{display:'flex', justifyContent:"space-between"}}>
                  <div style={{alignItems:'center', display:"flex"}}>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'} style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                    <p className='Chain'>home</p>
                    </Link>
                    <IoChevronForward color='gray' size={10} style={{marginTop:3, marginRight:3, marginLeft:3}}/> 
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/'}  style={{textDecoration:'none', display:"flex", alignItems:"flex-start"}}>
                      <p className='Chain'>category</p>
                    </Link>
                  </div>
                  <p style={{fontWeight:'bold', color:"#a8c9ef", fontSize:"large", textTransform:'uppercase'}}>{this.state.categoria}</p>
                </div>
                
                <div style={{display:"flex", flexWrap:'wrap', justifyContent:'space-evenly'}}>      
                  {listMobile}
                </div>

              </div>
        
              <Foot/>

            </Mobile>
          </div>
        );
    }
    }


export default Catalog;