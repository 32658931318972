import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import QSLogo from '../assets/img/QSLogoOfertas.png'
import {IoCart, IoCartOutline, IoLogoInstagram, IoMenu} from 'react-icons/io5'
import { Link } from 'react-router-dom';
import { FetchCart, MostAlgo, SearchProds, SearchProdsOfertas } from '../SQL';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Navigate } from 'react-router-dom';
import { stack as Menu } from 'react-burger-menu'
import { useMediaQuery } from 'react-responsive'
import { Button } from 'bootstrap';


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}
class HeaderOfertas extends Component {
  
  state={
    cartN:0,
    searchInput:'',
    items:[],
    redirect:false
  }
  
  componentDidMount = async() => {
    FetchCart().then(res=>{
      this.setState({cartN:res.prods.length})
    })
  }
  
  
  render () {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    
    const handleOnSearch = async(string, results) => {
      // onSearch will have as the first callback parameter
      // the string searched and for the second the results.
      // console.log(string, results)
    

          await SearchProdsOfertas(capitalizeFirstLetter(string))
          .then(res=>{
    
            let items = []
    
            res.forEach(doc=>{
              items.push({
                id:doc.id,
                name:doc.nome,
                idNumber:doc.idNumber,
                picURL:doc.imgs[0]
              })

              this.setState({items:items})
    
            })
    
          })
    
    }
    
    const handleOnHover = (result) => {
      // the item hovered
      console.log(result)
    }
    
    const handleOnSelect = (item) => {
      // the item selected
      console.log(item)
      window.localStorage.setItem('prodId',JSON.stringify(item.id))
      MostAlgo(item.id)
      this.setState({redirect:true})
    }
    
    const handleOnFocus = () => {
      console.log('Focused')
    }
    
    const formatResult = (item) => {
      return (
        <div style={{display:'flex', alignItems:'center', cursor:"pointer"}}>
          <img src={URL=item.picURL} style={{maxWidth:50}} />
          <span style={{ display: 'block', textAlign: 'left', fontWeight:'bold', textTransform:'uppercase', color:"gray", marginLeft:10, fontSize:"large" }}>{item.name} | {item.idNumber}</span>
        </div>
      )
    }
    
      
    return (
      <div className="App">
        <Desktop>
  
          <header style={{padding:10 ,display:'flex', justifyContent:'space-around', alignItems:"center", backgroundColor:"white"}} className="App-header">
            <div style={{width:'33%'}}>
              <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/QuickOfertas'}>
                <img className='QSLogo' style={{height:100}} src={QSLogo}/>
              </Link>
            </div>
            <div style={{width:'33%'}}>

              <ReactSearchAutocomplete
                styling={{placeholderColor:'gainsboro'}}
                placeholder='Digite o código do produto'
                items={this.state.items}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                showNoResults={false}
              />                
              
            </div>
            <div style={{width:'33%'}}>
              <a onClick={()=>window.scrollTo({behavior:'smooth',top:0})} href={'https://instagram.com/quickshopbsb'} target='_blank'>
                <IoLogoInstagram size={60} className='cartIcon' />
                
              </a>
            </div>
          </header>
    
          <div className='BelowHeader'>
            <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
              <div onClick={()=>{
                window.scrollTo({behavior:'smooth',top:100000000})
              }} className='CLink' style={{width:'30%'}} >
                <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>EM BREVE, QUICKSHOP EXCLUSIVOS! </p>
              </div>
            </div>
        </div>
      </Desktop>
      { 
          this.state.redirect && <Navigate to='/Product' replace={true}/>
        }
        <Mobile>

          <div>
              <header style={{paddingTop:10, paddingBottom:10 ,display:'flex', justifyContent:'space-around', alignItems:"center", backgroundColor:"white"}} className="App-header">
                <div style={{width:'33%', display:'flex', justifyContent:"center"}}>
                  <Menu customBurgerIcon={<IoMenu color='#a8c9ef' size={35}/>} styles={{
                    bmBurgerButton:{
                      width:40, backgroundColor:"transparent", position:'relative'
                    },
                    bmMenuWrap:{
                      position:'fixed',
                      top:0,
                      left:0
                    },
                    bmOverlay:{
                      position:'fixed',
                      top:0,
                      left:0,
                    },

                    }}  className='BelowHeaderMobile'>
                  <div onClick={()=>{
                      window.scrollTo({behavior:'smooth',top:100000000})
                  }} className='CLink' style={{width:'100%', padding:0}}>
                    <p style={{fontWeight:'bold', fontSize:'large'}} className='Category'>EM BREVE, QUICKSHOP EXCLUSIVOS! </p>
                  </div>
                </Menu>
                </div>
                <div style={{width:'33%'}}>
                  <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} to={'/QuickOfertas'}>
                    <img className='QSLogo' style={{height:50}} src={QSLogo}/>
                  </Link>                  
                </div>
                <div style={{width:'33%'}}>
                <a onClick={()=>window.scrollTo({behavior:'smooth',top:0})} href={'https://instagram.com/quickshopbsb'} target='_blank'>
                  <IoLogoInstagram size={40} className='cartIcon' />
                
                </a>
                </div>
              </header>
              <div style={{width:'70%', marginRight:'auto', marginLeft:"auto", marginBottom:10}}>
                <ReactSearchAutocomplete
                  placeholder='Digite o código do produto'
                  items={this.state.items}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  formatResult={formatResult}
                  showNoResults={false}
                  styling={{height:25,placeholderColor:'gainsboro'}}
                />                
              </div>
        
            
          </div>
        </Mobile>
      </div>
    );
        
      }
    }

  export default HeaderOfertas;