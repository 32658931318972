import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
import emailjs from '@emailjs/browser';


// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
import firebaseConfig from "./firebaseConfig"

const axios = require('axios').default; 

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = firebase.firestore();
const analytics = firebase.analytics()
const auth = firebase.auth()
const storage = firebase.storage()

export function Debug () {

    db
    .collection('ProdutosAfiliados')
    .get()
    .then(snapshot=>{
        snapshot.forEach(doc=>{

            db
            .collection('ProdutosAfiliados')
            .doc(doc.id)
            .update({
                imgs:['https://picsum.photos/200/300','https://picsum.photos/250/300','https://picsum.photos/200/350','https://picsum.photos/300/300','https://picsum.photos/350/300']
            })

        })
    })


}

export function FetchMochi (n) {

    if(n = 0) {
        let response = db
        .collection('Produtos')
        .where('categoria', '==', 'Pets')
        .limit(8)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response
        
    } else {
        let response = db
        .collection('Produtos')
        .where('categoria', '==', 'Pets')
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response

    }


}

export function FetchOfertas (n, ordem) {

    if(ordem === 'a'){
        let response = db
        .collection('ProdutosAfiliados')
        .limit(n)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response
        
    } else if(ordem === 'r'){
        let response = db
        .collection('ProdutosAfiliados')
        .orderBy('idNumber','desc')
        .limit(n)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response

    } else if(ordem === 'v'){
        let response = db
        .collection('ProdutosAfiliados')
        .orderBy('mostAlgo','desc')
        .limit(n)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response

    }
        
    
    


}

export function MostAlgo(id){
    db
    .collection('Produtos')
    .doc(id)
    .get()
    .then(doc=>{
        if(doc.exists){
            doc
            .ref
            .update({
                mostAlgo:doc.data().mostAlgo + 1
            })
        } else {
            db
            .collection('ProdutosAfiliados')
            .doc(id)
            .get()
            .then(doc=>{
                doc
                .ref
                .update({
                    mostAlgo:doc.data().mostAlgo + 1
                })
            })
        }
    })
}

export function FetchAce (n) {

    if(n = 0) {

        let response = db
        .collection('Produtos')
        .where('categoria', '==', 'Casa')
        .limit(8)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response
    } else {
        let response = db
        .collection('Produtos')
        .where('categoria', '==', 'Casa')
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response

    }


}

export function FetchEle (n) {

    if(n = 0) {

        let response = db
        .collection('Produtos')
        .where('categoria', '==', 'Ele')
        .limit(8)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response
    } else {
        let response = db
        .collection('Produtos')
        .where('categoria', '==', 'Ele')
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response

    }


}

export function FetchEla (n) {

    if(n = 0) {

        let response = db
        .collection('Produtos')
        .where('categoria', '==', 'Ela')
        .limit(8)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response
    } else {
        let response = db
        .collection('Produtos')
        .where('categoria', '==', 'Ela')
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response

    }


}

export function FetchPromo (n) {

    if(n = 0) {

        let response = db
        .collection('Produtos')
        .where('isPromo', '==', true)
        .limit(8)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response
    } else {
        let response = db
        .collection('Produtos')
        .where('isPromo', '==', true)
        .get()
        .then(snapshot => {
            
            let list = []
    
            snapshot.forEach(doc => {
                list.push(doc.data())
            })
            
            return list
        })
    
        return response

    }


}

export function FetchMost () {

    let response = db
    .collection('Produtos')
    .orderBy('mostAlgo', 'desc')
    .limit(8)
    .get()
    .then(snapshot => {
        
        let list = []

        snapshot.forEach(doc => {
            list.push(doc.data())
        })
        
        return list
    })

    return response

}

export function FetchMostOfertas () {

    let response = db
    .collection('ProdutosAfiliados')
    .orderBy('mostAlgo', 'desc')
    .limit(8)
    .get()
    .then(snapshot => {
        
        let list = []

        snapshot.forEach(doc => {
            list.push(doc.data())
        })
        
        return list
    })

    return response

}

export function FetchRecOfertas () {

    let response = db
    .collection('ProdutosAfiliados')
    .orderBy('idNumber', 'desc')
    .limit(12)
    .get()
    .then(snapshot => {
        
        let list = []

        snapshot.forEach(doc => {
            list.push(doc.data())
        })
        
        return list
    })

    return response

}

export function FetchRel (tags,n) {

    let cleanTags = n === 0 ? tags.length > 10 ? tags.splice(8,tags.length-8) : tags : tags

    let response = db
    .collection('Produtos')
    .where('tags','array-contains-any',cleanTags)
    .limit(8)
    .get()
    .then(snapshot => {
        
        let list = []

        snapshot.forEach(doc => {
            list.push(doc.data())
        })
        
        return list
    })

    return response

}

export function FetchRelOfertas (tags,n) {

    let cleanTags = n === 0 ? tags.length > 10 ? tags.splice(8,tags.length-8) : tags : tags

    let response = db
    .collection('ProdutosAfiliados')
    .where('tags','array-contains-any',cleanTags)
    .limit(8)
    .get()
    .then(snapshot => {
        
        let list = []

        snapshot.forEach(doc => {
            list.push(doc.data())
        })
        
        return list
    })

    return response

}

export function FetchCart () {

    let response = db
    .collection('carts')
    .doc(window.localStorage.getItem('userID').replace('"','').replace('"',''))
    .get()
    .then( snapshot => {

        return snapshot.data()
        
    })
    

    return response

}

export function FetchProd (id) {

    let response = db
    .collection('Produtos')
    .doc(id)
    .get()
    .then(doc => {
        if(doc.exists){
            return doc.data()
        } else {
            
            return db
            .collection('ProdutosAfiliados')
            .doc(id)
            .get()
            .then(doc => {
                    return doc.data()
            })
        }

    })

    return response

}

export async function FetchUser () {

    let response = auth.currentUser != null ? await auth.currentUser.uid : SignIn()

    return response

}

export function SignIn () {

    let response = auth
    .signInAnonymously()
    .then(res=>{
        return res.user.uid
    })

    return response
}

export async function SaveAval (nome,comentario, nota,imagem, prodId) {

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;

    let response = await storage
    .ref(`${nome.replace(' ','')}-${auth.currentUser.uid}-AvalImg-${prodId}`)
    .put(imagem)
    .then(res=>{

        db
        .collection('Produtos')
        .doc(prodId)
        .get()
        .then(async snapshot=>{
            let array = snapshot.data().avaliacoes

            array.push({
                comentario:comentario,
                data:formattedToday,
                nome:nome,
                nota:nota,
                picURL: await res.ref.getDownloadURL()
            })

            db
            .collection('Produtos')
            .doc(prodId)
            .update({
                avaliacoes:array
            })
            .then(()=>{return true})
            .catch(e=>{
                return false
            })
        })
        .then(()=>{return true})
        .catch(e=>{
            return false
        })

    })
    .then(()=>{return true})
    .catch(e=>{
        return false
    })


    return response

}

export async function AddToCart (prod, preco, quantidade, variaveis, Tags ,user, Img, id, parametro) {
    
    let response = await db
    .collection('carts')
    .doc(user)
    .get()
    .then(async snapshot => {

        if(snapshot.exists){
            
            let prods=snapshot.data().prods
            let precos=snapshot.data().precos
            let qtds=snapshot.data().qtds
            let variacoes=snapshot.data().variacoes
            let tags=snapshot.data().tags 
            let imgs=snapshot.data().imgs 
            let ids=snapshot.data().ids 
            let parametros=snapshot.data().parametros 
    
            prods.push(prod)
            precos.push(preco)
            qtds.push(quantidade)
            imgs.push(Img)
            ids.push(id)
            parametros.push(parametro)

            variaveis.forEach((i,idx)=>{
                variacoes.push(i)
            })

            Tags.forEach((i,idx)=>{
                if(tags.length <= 10) {
                    tags.push(i)
                }
            })

    
            return await db
            .collection('carts')
            .doc(user)
            .update({
                prods:prods,
                precos:precos,
                qtds:qtds,
                variacoes:variacoes,
                tags:tags,
                imgs:imgs,
                ids:ids,
                parametros:parametros
            })
            .then(()=>{

                db
                .collection('Produtos')
                .doc(id)
                .get()
                .then(doc=>{
                    db
                    .collection('Produtos')
                    .doc(id)
                    .update({
                        mostAlgo:doc.data().mostAlgo+1
                    })
                })

                return true
            })
            .catch(e=>{
                return false
            })
            
        } else {

            return await db
            .collection('carts')
            .doc(user)
            .set({
                prods:[prod],
                precos:[preco],
                qtds:[quantidade],
                variacoes:variaveis,
                tags:Tags,
                imgs:[Img],
                ids:[id],
                parametros:[parametro]
            })
            .then(()=>{

                db
                .collection('Produtos')
                .doc(id)
                .get()
                .then(doc=>{
                    db
                    .collection('Produtos')
                    .doc(id)
                    .update({
                        mostAlgo:doc.data().mostAlgo+1
                    })
                })

                return true
            })
            .catch(e=>{
                return false
            })
            

        }
    })

    console.log(response)
    return response
    
}

export function ChangeQtd (val) {

    db
    .collection('carts')
    .doc(window.localStorage.getItem('userID').replace('"','').replace('"',''))
    .update({
        qtds:val
    })
}

export async function RemoveProd (cart, idx) {

    let ids = cart.ids
    let imgs = cart.imgs
    let precos = cart.precos
    let prods = cart.prods
    let qtds = cart.qtds
    let tags = cart.tags
    let variacoes = cart.variacoes
    
    ids.splice(idx,1)
    imgs.splice(idx,1)
    precos.splice(idx,1)
    prods.splice(idx,1)
    qtds.splice(idx,1)
    tags.splice(idx,1)
    variacoes.splice(idx,1)
    
    db
    .collection('carts')
    .doc(window.localStorage.getItem('userID').replace('"','').replace('"',''))
    .update({
        ids: ids,
        imgs: imgs,
        precos: precos,
        prods: prods,
        qtds: qtds,
        tags: tags,
        variacoes: variacoes,
    })
}

export async function FecthPedido (id) {

    let response = await db
    .collection('pedidos')
    .doc(id)
    .get()
    .then(snapshot=>{
        if(snapshot.exists){
            return snapshot.data()
        } else {
            return 'e'
        }
    })

    return response

}

export async function VerifyUser (){


    let response =  JSON.parse(window.localStorage.getItem("userID")) === 'pqoT8LU7rVVSUGny6A9IElN2CGR2' ? true : false

    return response
}

export async function Login(user,senha) {
    
    return auth.signInWithEmailAndPassword(user,senha).then(user=>{return user.user.uid})
}

export async function FetchOrders () {

    let response = await db
    .collection('pedidos')
    .get()
    .then(snapshot=>{

        let lista = []

        snapshot.forEach(doc=>{
            lista.push(doc.data())
        })

        return lista

    })

    return response

}

export async function UpdateStatus (status, id, nome, email, rastreio) {

    db
    .collection('pedidos')
    .doc(id)
    .update({
        status:status
    })
    .then(()=>{
            var templateParams2 = {
            ID:id,
            nome: nome.split(' ')[0],
            email: email,
            status: status,
            rastreio: rastreio,
        };
         
        emailjs.send('service_vlsavec', 'template_40wo84u', templateParams2, 'YAzRojtNKaIjV2Cl1')
            .then(function(response) {
               console.log('SUCCESS!', response.status, response.text);
            }, function(error) {
               console.log('FAILED...', error);
            });

    })

}

export async function UpdateRastreio (rastreio, id, nome, email, status) {

    db
    .collection('pedidos')
    .doc(id)
    .update({
        rastreio:rastreio
    })
    .then(()=>{
            var templateParams2 = {
            ID:id,
            nome: nome.split(' ')[0],
            email: email,
            status: status,
            rastreio: rastreio,
        };
         
        emailjs.send('service_vlsavec', 'template_40wo84u', templateParams2, 'YAzRojtNKaIjV2Cl1')
            .then(function(response) {
               console.log('SUCCESS!', response.status, response.text);
            }, function(error) {
               console.log('FAILED...', error);
            });

    })

}

export async function DeleteOrder ( id) {

    db
    .collection('pedidos')
    .doc(id)
    .delete()

}

export async function logout () {

    auth.signOut()
    window.localStorage.setItem('userID',JSON.stringify(''))
    

}

export async function SearchProds (input) {

   let response = await db
    .collection('Produtos')
    .orderBy('nome','asc')
    .startAt(input)
    .endAt(`${input}\uf8ff`)
    .limit(4)
    .get()
    .then(async snapshot=>{

        let list = []

        snapshot.forEach(async doc=>{
            list.push( doc.data() )
        })
        
        return list
    })
    
    return response

}

export async function SearchProdsOfertas (input) {

   let response = await db
    .collection('ProdutosAfiliados')
    .orderBy('nome','asc')
    .startAt(input)
    .endAt(`${input}\uf8ff`)
    .limit(4)
    .get()
    .then(async snapshot=>{

        let list = []

        snapshot.forEach(async doc=>{
            list.push( doc.data() )
        })
        
        return list
    })
    
    return response
}

export async function FetchFrete (cart, cep) {

    let items = []

    cart.prods.forEach((i,idx)=>{
        items.push({
            "id": cart.ids[idx],
            "width": cart.parametros[idx].Largura.replace('cm',''),
            "height": cart.parametros[idx].Altura.replace('cm',''),
            "length": cart.parametros[idx].Profundidade.replace('cm',''),
            "weight": cart.parametros[idx].Peso.replace('kg',''),
            "insurance_value":parseFloat(cart.precos[idx].replace('R$','').replace(',','.')).toFixed(2),
            "quantity": cart.qtds[idx]    
        })
    })

    var myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjcxYTQzMmE5MmI3NDk5MzY3ZTM3NzI2Y2ZiZDc4YzExYWExN2Y4YjUxMzExNGU0Y2MyNTM0NGE3NzA0NTAwYTkzNzVlOGE0OWJjOTg1MGQyIn0.eyJhdWQiOiI5NTYiLCJqdGkiOiI3MWE0MzJhOTJiNzQ5OTM2N2UzNzcyNmNmYmQ3OGMxMWFhMTdmOGI1MTMxMTRlNGNjMjUzNDRhNzcwNDUwMGE5Mzc1ZThhNDliYzk4NTBkMiIsImlhdCI6MTY2NDUyMDU3NCwibmJmIjoxNjY0NTIwNTc0LCJleHAiOjE2OTYwNTY1NzQsInN1YiI6ImYxZjRiNGNkLTI1OGUtNDdjNi1iYWUyLTQ5MWViY2IxZjU0OSIsInNjb3BlcyI6WyJjYXJ0LXJlYWQiLCJjYXJ0LXdyaXRlIiwiY29tcGFuaWVzLXJlYWQiLCJjb21wYW5pZXMtd3JpdGUiLCJjb3Vwb25zLXJlYWQiLCJjb3Vwb25zLXdyaXRlIiwibm90aWZpY2F0aW9ucy1yZWFkIiwib3JkZXJzLXJlYWQiLCJwcm9kdWN0cy1yZWFkIiwicHJvZHVjdHMtZGVzdHJveSIsInByb2R1Y3RzLXdyaXRlIiwicHVyY2hhc2VzLXJlYWQiLCJzaGlwcGluZy1jYWxjdWxhdGUiLCJzaGlwcGluZy1jYW5jZWwiLCJzaGlwcGluZy1jaGVja291dCIsInNoaXBwaW5nLWNvbXBhbmllcyIsInNoaXBwaW5nLWdlbmVyYXRlIiwic2hpcHBpbmctcHJldmlldyIsInNoaXBwaW5nLXByaW50Iiwic2hpcHBpbmctc2hhcmUiLCJzaGlwcGluZy10cmFja2luZyIsImVjb21tZXJjZS1zaGlwcGluZyIsInRyYW5zYWN0aW9ucy1yZWFkIiwidXNlcnMtcmVhZCIsInVzZXJzLXdyaXRlIiwid2ViaG9va3MtcmVhZCIsIndlYmhvb2tzLXdyaXRlIl19.O9qMI7tDSYe-29sjq1D31NsEHrfDyE4E-k2rY50buCLRUeBzi08QHx59iu_G4OPTNbA3S6t3ZIuhJeagRYN1_HopjT2fWM9NvKHD1y3IDmyGOAY5qzFJ6Qev_9Xvcms_Dm-AZmYXwRhfSrlwk43q1YfvsCMeqpqI4XF15fxylTUMk4Sy8BFS49w-OwHfE43jNN6lWPX11SMnVsD2AoMbjVATQOlmNuUXVG6-2VrciP8uQCLQ7AR2Z1hEPYAItP7z3IszMdUM7TPN3qgr-AO5fJD2jeG4zpnCMSwE9z_lT4oEXdnNKlrnmUoKopoyBGS_rpHHfC2VbHEZBeJmuPt8xO_mZu8cyXZwlXERNDhiJNc9tkp7SkmefqU05RNlPMI62mnKLeltrx6KdDJoczVA1FKJVZpLpXKw0wGFYYjfdPx--Yxon1JHrWCAWvJCrR4NN-JK_ubFOIlz4eNJndOF1Shj-X62kwEBqgTbfaw3JrypC8Bbj_s9tmPSU0n2SCKrwrkJDtQt7DNAxzG_eqncVoWRjxMJsdWl9iKOg-SP1BSy6lOeFnTHf2Ke2Mm_lIeae57f3B-9Rgy_Gx0YOe8aElo8mTMj--R1SkerN3F1NGYyOSzXvqwO--Y-bmsFmPlbK41jGexHfLdFtilvM4cicPrwaQwUUG2XgVQBXj2li3I");
    myHeaders.append("User-Agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/106.0.0.0 Safari/537.36 (contato@quickshopbr.com)");

    var raw = JSON.stringify({
    "from": {
        "postal_code": "72035503"
    },
    "to": {
        "postal_code": cep.replace('-','')
    },
    "products": items,
    });


    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect:'follow'
    };

    let response = await fetch("https://sandbox.melhorenvio.com.br/api/v2/me/shipment/calculate", requestOptions)
    .then(response => response.json())
    .then(result => {return result})
    .catch(error => console.log('error', error));

    return response
}


export async function Pay (nome, email, cpf, celular, cep, bairro, cidade, estado, endereco, numero, complemento, destinatario, FP, numeroCartao, validade, codigoSeguranca, nomeTitular, parcelas, cart, frete, valorTotal, ETA) {

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let ddPlus = new Date();
    let ddBoleto = new Date();

    ddPlus.setDate(today.getDate() + ETA)
    ddBoleto.setDate(today.getDate() + 2)

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    const freteETA = ddPlus.toString().split(' ')[2] + '/' + mm + '/' + yyyy;
    const boletoVencimento = yyyy + '-' + mm + '-' + ddBoleto.toString().split(' ')[2] + 'T00:00:00Z';
    //2022-09-20T00:00:00Z

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    let orderId =  makeid(10).toUpperCase()

    let items = []
    
    for(let i in cart.prods) {

        items.push({
            amount: parseInt(cart.precos[i].replace(',','').replace('R$','')),
            description: cart.prods[i],
            quantity: cart.qtds[i],
            code: cart.ids[i]
        
        })
    }    




    const options = {
        method:'POST',
        headers: {accept: 'application/json', 'content-type': 'application/json'},
        body:JSON.stringify({
            user:auth.currentUser.uid,
            userName:nome,
            userEmail:email,
            userPhone:celular,
            userDoc:cpf,
            produtos:cart,
            valorTotal:valorTotal,
            formaPagamento:FP,
            data:formattedToday,
            frete:frete,
            endereco: {
                country: 'BR',
                state: estado,
                city: cidade,
                zip_code: cep,
                line_1: `${numero},${endereco},${bairro}`,
                line_2: complemento,
                destinatario:destinatario,
                numero:numero,
                endereco:endereco,
                bairro:bairro,
                complemento:complemento
            },
            ETA:freteETA,
            boletoVencimento:boletoVencimento,
            status:'Em separação',
            rastreio:'Não despachado',
            ID:orderId,
            items:items,
            numeroCartao:numeroCartao,
            nomeTitular:nomeTitular,
            validade:validade,
            codigoSeguranca:codigoSeguranca,
            parcelas:parcelas === 'À vista' ? 1 : parcelas
        })
    }        

    let reqs;
    
    if(FP === 'cdc'){

        reqs = await fetch('http://localhost:3001/cdc', options)

        let ress = await reqs.json()

        let response;
    
        if(ress[1]){
    
            if(ress[0].charges[0].status === 'paid') {
        
                response = await db
                .collection('pedidos')
                .doc(orderId)
                .set({
                    user:auth.currentUser.uid,
                    userName:nome,
                    userEmail:email,
                    userPhone:celular,
                    userDoc:cpf,
                    produtos:cart,
                    valorTotal:valorTotal,
                    formaPagamento:FP,
                    data:formattedToday,
                    frete:frete,
                    endereco: {
                        country: 'BR',
                        state: estado,
                        city: cidade,
                        zip_code: cep,
                        line_1: `${numero},${endereco},${bairro}`,
                        line_2: complemento,
                        destinatario:destinatario,
                        numero:numero,
                        endereco:endereco,
                        bairro:bairro,
                        complemento:complemento
                    },
                    ETA:freteETA,
                    status:'Em separação',
                    rastreio:'Não despachado',
                    ID:orderId
                })
                .then(()=>{
            
                    let produtos = []
            
                    cart.prods.forEach((i,idx)=>{
                        produtos.push(`${i} ${cart.qtds[idx]}x - ${cart.variacoes[idx]}`)
                    })
            
                    var templateParams1 = {
                        ID:orderId,
                        nome: nome.split(' ')[0],
                        nomeCompleto: nome,
                        celular: celular,
                        email: email,
                        localizacao: `${cidade} - ${estado}`,
                        endereco: `${bairro},${endereco},${numero}`,
                        produtos: JSON.stringify(produtos),
                        valorTotal: 'R$'+(valorTotal/100).toFixed(2).replace('.',','),
                        status: 'Em separação',
                        rastreio: 'Não despachado',
                        data:formattedToday,
                        ETA:freteETA
                    };
                     
                    emailjs.send('service_vlsavec', 'template_9n1f6id', templateParams1, 'YAzRojtNKaIjV2Cl1')
                        .then(function(response) {
                           console.log('SUCCESS!', response.status, response.text);
                        }, function(error) {
                           console.log('FAILED...', error);
                        });
                    
                    
                })
                .then(()=>{
                    let ID = orderId
                    let res = {
                        id:ID,
                        status:'s'
                    }
            
                    db
                    .collection('carts')
                    .doc(auth.currentUser.uid)
                    .delete()
            
                    return res
            
                })
            
                
            } else {
                
                response = {
                    id:'',
                    status:'f'
                }
            }
        } else if(!ress[1]){
            response = {
                id:'',
                status:'f'
            }
    
        }
        
        
        return response

    }    

    if(FP === 'pix'){
        
        reqs = await fetch('http://localhost:3001/pix', options)

        let ress = await reqs.json()

        // console.log(ress)
        let response = {
            qrMode:true,
            qrCode:ress[0].charges[0].last_transaction.qr_code_url,
            id:ress[0].id
        }

        return response
    }    
    
    if(FP === 'boleto'){
        
        reqs = await fetch('http://localhost:3001/boleto', options)

        let ress = await reqs.json()

        if(ress[0].charges[0].last_transaction.status === 'pending'){

        }

        // console.log(ress)
        let response = {
            boletoMode:true,
            boletoCode:ress[0].charges[0].last_transaction.pdf,
            boletoID:ress[0].id,
        }

        return response
    }    
    
    
    
}

export async function VerifyPayment (id, nome, email, cpf, celular, cep, bairro, cidade, estado, endereco, numero, complemento, destinatario, FP, numeroCartao, validade, codigoSeguranca, nomeTitular, parcelas, cart, frete, valorTotal, ETA) {

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let ddPlus = new Date();

    ddPlus.setDate(today.getDate() + ETA)

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    const freteETA = ddPlus.toString().split(' ')[2] + '/' + mm + '/' + yyyy;


    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    let orderId =  makeid(10).toUpperCase()

    let items = []
    
    for(let i in cart.prods) {

        items.push({
            amount: parseInt(cart.precos[i].replace(',','').replace('R$','')),
            description: cart.prods[i],
            quantity: cart.qtds[i]
        
        })
    }    




    const options = {
        method:'POST',
        headers: {accept: 'application/json', 'content-type': 'application/json'},
        body:JSON.stringify({
            id:id,
        })
    }

    let reqs = await fetch('http://localhost:3001/verificar', options)
    
    let ress = await reqs.json()
    
    // console.log(ress)
    let response;

    if(ress[0].status === 'pending'){

        response = {
            status:ress[0].status
        }

    } else if(ress[0].status === 'paid'){

        response = await db
        .collection('pedidos')
        .doc(orderId)
        .set({
            user:auth.currentUser.uid,
            userName:nome,
            userEmail:email,
            userPhone:celular,
            userDoc:cpf,
            produtos:cart,
            valorTotal:valorTotal,
            formaPagamento:FP,
            data:formattedToday,
            frete:frete,
            endereco: {
                country: 'BR',
                state: estado,
                city: cidade,
                zip_code: cep,
                line_1: `${numero},${endereco},${bairro}`,
                line_2: complemento,
                destinatario:destinatario,
                numero:numero,
                endereco:endereco,
                bairro:bairro,
                complemento:complemento
            },
            ETA:freteETA,
            status:'Em separação',
            rastreio:'Não despachado',
            ID:orderId
        })
        .then(()=>{
    
            let produtos = []
    
            cart.prods.forEach((i,idx)=>{
                produtos.push(`${i} ${cart.qtds[idx]}x - ${cart.variacoes[idx]}`)
            })
    
            var templateParams1 = {
                ID:orderId,
                nome: nome.split(' ')[0],
                nomeCompleto: nome,
                celular: celular,
                email: email,
                localizacao: `${cidade} - ${estado}`,
                endereco: `${bairro},${endereco},${numero}`,
                produtos: JSON.stringify(produtos),
                valorTotal: 'R$'+(valorTotal/100).toFixed(2).replace('.',','),
                status: 'Em separação',
                rastreio: 'Não despachado',
                data:formattedToday,
                ETA:freteETA
            };
             
            emailjs.send('service_vlsavec', 'template_9n1f6id', templateParams1, 'YAzRojtNKaIjV2Cl1')
                .then(function(response) {
                   console.log('SUCCESS!', response.status, response.text);
                }, function(error) {
                   console.log('FAILED...', error);
                });
            
            
        })
        .then(()=>{
            let ID = orderId
            let res = {
                id:ID,
                status:'s'
            }
    
            db
            .collection('carts')
            .doc(auth.currentUser.uid)
            .delete()
    
            return res
    
        })
    


    }

    
    
    return response

}

export async function SaveBoleto (nome, email, cpf, celular, cep, bairro, cidade, estado, endereco, numero, complemento, destinatario, FP, boletoID, boletoCode, cart, frete, valorTotal, ETA) {

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let ddPlus = new Date();

    ddPlus.setDate(today.getDate() + ETA)

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    const freteETA = ddPlus.toString().split(' ')[2] + '/' + mm + '/' + yyyy;

    let orderId =  boletoID

    let items = []
    
    for(let i in cart.prods) {

        items.push({
            amount: parseInt(cart.precos[i].replace(',','').replace('R$','')),
            description: cart.prods[i],
            quantity: cart.qtds[i]
        
        })
    }    

    let response = await db
    .collection('boletosPendentes')
    .doc(orderId)
    .set({
        user:auth.currentUser.uid,
        userName:nome,
        userEmail:email,
        userPhone:celular,
        userDoc:cpf,
        produtos:cart,
        valorTotal:valorTotal,
        formaPagamento:FP,
        data:formattedToday,
        frete:frete,
        endereco: {
            country: 'BR',
            state: estado,
            city: cidade,
            zip_code: cep,
            line_1: `${numero},${endereco},${bairro}`,
            line_2: complemento,
            destinatario:destinatario,
            numero:numero,
            endereco:endereco,
            bairro:bairro,
            complemento:complemento
        },
        ETA:freteETA,
        status:'Em separação',
        rastreio:'Não despachado',
        boletoID:orderId,
        boletoCode:boletoCode

    })
    


    

    
    
    return response

}

export async function saveProd (distribuicao, nomeProduto, precoProduto, precoPromoProduto, disponivelProduto, promoProduto, categoria, tags, avaliacoes, caracteristicas, imagens, informacoes, parametros, variacoes, linkAfiliado) {

    // console.log( distribuicao )
    // console.log( nomeProduto )
    // console.log( precoProduto )
    // console.log( precoPromoProduto )
    // console.log( disponivelProduto )
    // console.log( promoProduto )
    // console.log( categoria )
    // console.log( tags.split(', ') )
    // console.log( JSON.parse(avaliacoes) )
    // console.log( caracteristicas.split(', ') )
    // console.log( imagens.split(', ') )
    // console.log( JSON.parse(informacoes) )
    // console.log( JSON.parse(parametros) )
    // console.log( JSON.parse(variacoes) )
    // console.log( linkAfiliado )
    
    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    let prodID =  makeid(20).toUpperCase()

    if(distribuicao === 'exclusivo'){

        let response = await db
        .collection('Produtos')
        .doc(prodID)
        .set({
            nome:nomeProduto,
            preco:precoProduto,
            precoPromo:precoPromoProduto,
            mostAlgo:1,
            isPromo:promoProduto,
            isDisponivel:disponivelProduto,
            categoria:categoria,
            id:prodID,
            tags:tags.split(', '),
            variacoes:JSON.parse(variacoes),
            parametros:JSON.parse(parametros),
            info:JSON.parse(informacoes),
            imgs:imagens.split(', '),
            caracteristicas:caracteristicas.split(', '),
            avaliacoes:JSON.parse(avaliacoes)
    
        })
        .then(()=>{
            return true
        })
        .catch(e=>{
            return false
        })

        return response

    } else {

        let response = await db
        .collection('ProdutosAfiliados')
        .get()
        .then(querySnaphot => {

            db
            .collection('ProdutosAfiliados')
            .doc(prodID)
            .set({
                nome:nomeProduto,
                preco:precoProduto,
                precoPromo:precoPromoProduto,
                mostAlgo:1,
                isPromo:promoProduto,
                isDisponivel:disponivelProduto,
                categoria:categoria,
                id:prodID,
                idNumber:querySnaphot.size+1,
                tags:tags.split(', '),
                variacoes:JSON.parse(variacoes),
                parametros:JSON.parse(parametros),
                info:JSON.parse(informacoes),
                imgs:imagens.split(', '),
                caracteristicas:caracteristicas.split(', '),
                avaliacoes:JSON.parse(avaliacoes),
                linkAfiliado:linkAfiliado
        
            })
    

        })
        .then(()=>{
            return true
        })
        .catch(e=>{
            return false
        })

        return response

    }


}















// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Pets',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzWkWafyaWDFEsUgIltR2MeA09rqsghk6QogK6SjmynIy-7NuHOgj9chERfKG0MMSt3rU&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBJCEHXvFogiVcc8KD9Icn3ki7BlspW3MqTnTZSmLHrkT9HdA-_hum04GAehzEjcf3xLw&usqp=CAU',
//         'https://images.tcdn.com.br/img/img_prod/498856/180_kit_10_pas_higienicas_coletoras_p_gato_stillpet_481_1_20210903101302.png',
//         'https://cdnv2.moovin.com.br/totalcasaeconforto/imagens/produtos/det/pa-higienica-para-gatos-joy-6471498bae224dec9ac567250f362c61.jpg',
//         'https://io.convertiez.com.br/m/abana/shop/products/images/226/medium/pa-higienica_505.jpg',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Pá Higiênica',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$9,90',
//     precoPromo:'R$8,70',
//     tags:[
//         'Pets',
//         'Praticidade',
//         'Casa',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Pets',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiOvN69jZOdFE9XCNTZKcGfLRvvgEl4rdslII0mwvhnVu5O_Dj8q5liL9pvts4lN08VMY&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRirM_eYzW4BQn55h0Htzq8ACfjLolYbiDdMUNyRlc5wAHnLVzudP5Xu-LEoEucf0YBgcw&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4LtV7fEB7QtXimmenDJ0-OTbaa0naORoIGQ&usqp=CAU',
//         'https://www.petlove.com.br/images/products/203159/product/Peitoral_Pawise_com_Guia_Azul_para_Gatos.jpg?1627651907',
//         'https://www.gatolinobebedouros.com.br/imagem/index/18925720/G/gato_vestindo_coleira_peitoral_com_guia_estampa_jeans_com_vermelho.jpg',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Guia para gatos',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$34,90',
//     precoPromo:'R$21,70',
//     tags:[
//         'Pets',
//         'Ar livre',
//         'Esporte',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Pets',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://a-static.mlcdn.com.br/618x463/cama-listra-rosa-fabrica-pet-fabrica-pet/bledpet/gxayjnhmr-2/aad472210f3b24e79b4333ba8de49af7.jpg',
//         'https://www.petlove.com.br/images/products/239746/product/Cama_2_em_1_F%C3%A1brica_Pet_Fran%C3%A7a_Cinza_1857485_1.jpg?1627776814',
//         'https://foxpet.fbitsstatic.net/img/p/cama-fabrica-pet-abacaxi-71175/258299.jpg?w=420&h=420&v=no-change&qs=ignore',
//         'https://www.petlove.com.br/images/products/241457/large/Cama_F%C3%A1brica_Pet_Decor_Rosa_2433165_medidas.jpg?1627784172',
//         'https://images.tcdn.com.br/img/img_prod/470728/cama_pet_m_personalizada_688_1_20191211110458.jpg',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Cama para Pets',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$31,90',
//     precoPromo:'R$20,00',
//     tags:[
//         'Pets',
//         'Casa',
//         'Conforto',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Ele',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJTyxe3DAC7eLi6vdTEoSO0juqQiGGvK4NYUczAuUB_BqUEEXCl1-645MhyhsQq8G0IcA&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV4GKUYwHpoS9WhP47JSd3jf0mtlnaGfyQlD9M_SORjsAICl7zmPiNyzzERNSDauEilcI&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS5krVRwOB4nfwY7Fr9sdCQoh3ipWk5S0dGeIDrrr1PfyZQigxtXKgqxemQXfRiUTvJPI&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwc-b1AAMjZmiufmfNshT7YYH8Z2yd-NpyNg&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_uaCRVfsYMoG0EjrZ-OlFaDMbuB2JK64taQ&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmc-PmDY4J8Q2OdETlr82U7BrFpq54F4t1OQ&usqp=CAU',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'SmartWatch',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$199,50',
//     precoPromo:'R$90,70',
//     tags:[
//         'Homens',
//         'Tecnologia',
//         'Estilo',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Ele',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://images-americanas.b2w.io/produtos/3436551381/imagens/escova-p-remover-limpa-pelos-de-gato-e-cachorro-pet-show/3436551364_1_large.jpg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5wL09C7Y_l0CuFw2z2V7j88E8Qcc0VUauVKbxk2AlqPBIKq97i8FPTEAzN-Mvwo616vY&usqp=CAU',
//         'https://cdn.leroymerlin.com.br/products/removedor_pelos_cachorro_pets_caes_gatos_roupas_escova_casa_1567048830_fcf4_600x600.jpeg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7ryAJ_tIZ4xPWM88frdOAOdEa94W4hhtBgGQYatwdVUEsYkmrFPE1i7KiqbQ6FLM9VTc&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI8L1xG_I2ONV_secIDSgOF8QxqenFiK5Avg3RBxUNHHh74T6XdugEwRFAzPE3FDaAaL8&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMJ15_nOnJJp0C_rUtT8fIf7_N4AztOFrwtOrM1TnmO49WmBflgEgDDA_fF2sycwU5FWo&usqp=CAU',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Limpa Pelos',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$9,90',
//     precoPromo:'R$8,70',
//     tags:[
//         'Casa',
//         'Praticidade',
//         'Estilo',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Ele',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyBJcBRy2AWTCImZ9uA7FHqJT8NBXj8UT52et-qIBbyueGrZkYVHlhpyqsbtsXr34q87w&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT00ZdAzQQgPAYGWPGVbBIpXTTflYaOPrw7nmrcDs8nm0p-0eGtKPuroFjNzhl9FrXmCtg&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBl-rxI7E0cWXdNDOKToO4ulCHv2MBIk1ExaBJf8BpMISxnXy5gG3ePLDgwVnWvZGZtdA&usqp=CAU',
//         'https://m.media-amazon.com/images/S/aplus-media-library-service-media/1753df46-f78a-45dd-b5bf-9b329d7818f0.__CR0,0,3880,2400_PT0_SX970_V1___.jpg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwI9I4v0FVj8Q-4KNwMnfbFXajCoRRk1kZHIPXBPYHIEKFB-4Od_7wcH62eaW2FkTmS-o&usqp=CAU',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Jawline',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$49,90',
//     precoPromo:'R$38,70',
//     tags:[
//         'Homens',
//         'Estilo',
//         'Esporte',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Ela',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRECxtvPCjnkYQ1X7sSsknnk9puoBvtzCsuMWCnRshShp4ot6RgeJRy0oIuWwQ24I20WDI&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUPpzHZEldUazudyDVB6Fb_O1pkcuKT7fIvylrz-CE6RzO2H0FOLXhC9vv74spgjudlyQ&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvZiv4XiRoHSU__v2AQ3cCCG7HY4qr-YSVCw&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFfbBIZ8ISFo_q6EwipNSVisU2ecX6ZfhVvQ&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzHqAeG1uW_hs5hVk05ImT6xdKMqOgRWcEAg&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkSahalyn1TDntuOGOwoYevRPy0C2JJ1OwFw&usqp=CAU',
//         'https://res.cloudinary.com/beleza-na-web/image/upload/w_1500,f_auto,fl_progressive,q_auto:eco,w_800/v1/imagens/product/E92547/1c50c889-89df-436c-b008-1486b5c7ddf1-esponja-de-maquiagem-gota.png',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Esponja de Maquiagem',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$3,90',
//     precoPromo:'R$2,70',
//     tags:[
//         'Mulheres',
//         'Estilo',
//         'Praticidade',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Ela',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcUENfRSXBg-LVi3LsLmkYrIdg20y3hq6I0g&usqp=CAU',
//         'https://gazin-images.gazin.com.br/unsafe/origxorig/filters:format(webp):quality(90)/https://gazin-marketplace.s3.amazonaws.com/midias/imagens/2020/10/chapinha-taiff-ceramica-180oc-102010142612.jpg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbHhHyK2ObStIkZdnH-LZZUNTH6hq-RgyktDxD-bY9s8Ze5mRbtP1l94H9hOMPB_31LPI&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVFIIH1_8SQshs0YaasKUpy5NE-ejFitN8zn1usHebRh6GsrVXJnkGUbj-kMRNq5xkiRg&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp6yPD09dmo_ZqBZyCQqS1Pxv6TzhpUrH0RQeXJcjrl2koryp1_Z_0gVBEDubFGyAzE0c&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUqHqEn51KLgObUHCWEat6phZRZ0Jgyl3gKQ&usqp=CAU',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Chapinhas Ultralizo',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$329,90',
//     precoPromo:'R$222,70',
//     tags:[
//         'Mulheres',
//         'Tecnologia',
//         'Praticidade',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Ela',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://produtos.smartbr.com/000000000109404/640/mini-massageador-croporal-acte-azul-01.jpg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVLMLodIvjPewwV8feXmOO2LMpPC1TPrnC6YKDnn9UYfL614NptTo0U87qutufrjeJIXg&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQchbz-afYv-TyD0OSXECNlXrKj_dI_Hrv69yXKOSpg2IFKATOmodzsySG9qN-JJv8BKnw&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqreMvLe8Dzg2bgrLv_nFGoZdyKBXrwyGg8Eb6djIcw1xQxcxLiuK9LPktcOCCSnNUK64&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEqRxFra2hBEiyKxg8OukgaC8ubSe8eC7NBgoy30IqGc7AE7pE2plEFkRJ60-ykLscdXs&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsjCyN5TspnIbuHyynoVLUI1qYTJG7ib1R84zFzngJwyXQL0HTfGsmCDdiqp8a23-hggs&usqp=CAU',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'AlivStress 2000',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$50,00',
//     precoPromo:'R$32,50',
//     tags:[
//         'Mulheres',
//         'Conforto',
//         'Praticidade',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Casa',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://casaeconstrucao.org/wp-content/uploads/2017/05/aromatizador-de-ambiente-3.jpg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7Y7-zRJMEMnZLBCQjgzATmfZQth96YO-AOE67DcCvhdUW6ti92M4Jyk-X7KSbppuKdVQ&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0agPvCxxklLaNFv9Doli-CM_RB6CL3UvFaROS4qbGziyTsChZqftW0CHYmEE-mUK9dGk&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ8hhZUU8Co0Vj2DRme_2AFKOaRpIoplTGjizAWofBjY3VJE_IkogdC5H089_N9BcYPEw&usqp=CAU',
//         'https://static.clubeextra.com.br/img/uploads/1/574/20233574.jpg',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Aromatizador de Ambiente',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$30,90',
//     precoPromo:'R$25,50',
//     tags:[
//         'Casa',
//         'Conforto',
//         'Praticidade',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Casa',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://cf.shopee.com.br/file/0983d6a90e707814185ed328792f39e8',
//         'https://m.media-amazon.com/images/I/41eSBNHgCDL._AC_SY450_.jpg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU3OfXXTHe0vfe7MQ2XX895gM7bcYe25AmUjN24zWm5OR2lBRMfVJQCDk9GqIjVFe3nqA&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO-d39HTrOPFIKVRc2AYWsrAZAAVelfzrKt7XAFmkWu_DobQmwTZZ_NwKjJSYk38z2mio&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUeUi5S8fxGwB7KCqKzBjQ4P1n2jiprphBzSM8CkNxjlxuP0fR3iWryknVvOGEqqn3qkU&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHUu_lermpimX861AqnlW-HQlaJ577rxUCeJzX0A0oTGiMWQxzYObbrAvEwH69TjujVZY&usqp=CAU',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'Processa +',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$19,90',
//     precoPromo:'R$8,70',
//     tags:[
//         'Casa',
//         'Praticidade',
//         'Tecnologia',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })






// db
// .collection('Produtos')
// .add({

//     avaliacoes:[
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labo.',
//             data:'20/07/2022',
//             nome:'Francisco',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/300'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amensequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'09/09/2022',
//             nome:'Fernanda',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciroident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'10/07/2022',
//             nome:'Marcos',
//             nota:4.6,
//             picURL:'https://picsum.photos/300/400'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'30/09/2022',
//             nome:'Luisa',
//             nota:4.6,
//             picURL:'https://picsum.photos/250/350'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'02/01/2022',
//             nome:'Vanessa',
//             nota:4.6,
//             picURL:'https://picsum.photos/200/200'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'05/10/2022',
//             nome:'Claudio',
//             nota:4.6,
//             picURL:'https://picsum.photos/400/500'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt m;',
//             data:'16/03/2022',
//             nome:'Pedro',
//             nota:4.6,
//             picURL:'https://picsum.photos/350/450'
//         },
//         {
//             comentario:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//             data:'11/09/2022',
//             nome:'João Victor',
//             nota:4.6,
//             picURL:'https://picsum.photos/500/600'
//         },
//     ],
//     caracteristicas:[
//         'Super Útil',
//         'Absolutamente Eficiente',
//         'Praticidade como nunca visto!',
//         'Extremamente Bom',
//         'Melhora sua vida',
//         'Você não pode viver sem!',
//     ],
//     categoria:'Casa',
//     id:makeid(10).toUpperCase(),
//     imgs:[
//         'https://uploads.metropoles.com/wp-content/uploads/2020/06/03165326/Rob%C3%B4-de-Limpeza.jpg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE7Id09XPDJkDGCjDEPMVsxs46cDdC65VQAeju5IdBpta5yRU-pbqtt971ofz3i78QWts&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqE_knC6HBeSS-G1ODIIuzjvnTZpBspHiSPquHKY2wMz7zSj5tlZq1pSwlFn_m1reKaV8&usqp=CAU',
//         'https://conteudo.imguol.com.br/c/noticias/cf/2020/10/30/aspirador-de-po-robo-para-limpeza-da-casa-1604070944482_v2_4x3.jpg',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLfnFE-ADktAQ60dDe1OayaRxlOyLMvx4OJmokA4cMavjQCsvdQdQi2KYk0Ksg9zE4NI8&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS88BTx4hO9hN59mdYNSIjF7SqzPUQGbLXuPRNMIs1he0XQkQWxuFUqIkgwB3gKsAzfOX0&usqp=CAU',
//         'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXgGS3fxuvgxGDOOkE28YUi8MaqEqUDISscyGjNbvuG6qSn6UUE4eaYrocfiqBDFTzdUs&usqp=CAU',
//     ],
//     info:[
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//         {
//             desc:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
//             img:'https://picsum.photos/800/400',
//             title:'The standard Lorem Ipsum passage, used since the 1500s'
//         },
//     ],
//     isPromo:false,
//     isDisponivel:true,
//     mostAlgo:1,
//     nome:'CleanBot',
//     parametros:[
//         'Altura: 15cm',
//         'Largura: 30cm',
//         'Profundidade: 10cm',
//         'Peso: 1kg',
//         'Voltagem: 220w',
//     ],
//     preco:'R$399,90',
//     precoPromo:'R$340,00',
//     tags:[
//         'Casa',
//         'Praticidade',
//         'Tecnologia',
//     ],
//     variacoes:[
//         'Preto',
//         'Branco',
//     ]
// })









 
