import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoRefresh, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FecthPedido, FetchAce, FetchMochi, FetchMost } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class InfoEnv extends Component {



    render(){

        return (
          <div className="App">
      
            <Header/>

      
            <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white", marginBottom:40}}>
              <p className='IPTitle'>Prazo de Entrega</p>
              <p className='IPText'>O prazo de entrega para qualquer lugar do Brasil de acordo com a transportadora atual, varia de 5 a 25 dias úteis a contar do envio do código de rastreio do produto pela Empresa ao visitante, podendo tal período ser prorrogado por motivos alheios a vontade da Empresa, tais como mas não se limitando: greve dos correios, ou problemas durante o transporte que não tenham como ser atribuídos a Empresa, ou ainda localização geográfica de difícil acesso.
              </p>
              <p className='IPText'>Lembrando que informamos o código de rastreamento de todos os produtos que forem encomendados. </p>
              <p className='IPText'>Os códigos de rastreios levam de 1 a 7 dias úteis para serem repassados de nossos fornecedores para você, cliente. Assim que disponível, é possivel verifica-lo na área "Pedidos" do nosso site, ou em seu email. </p>
              <p className='IPText'>Realizaremos o reembolso do valor relativo às compras efetuadas que forem extraviadas.</p>
              <p className='IPText'>É importante e um dever do comprador que possibilite os meios para a efetivação da entrega no endereço cadastrado no seu pedido. Após 2 (duas) tentativas comprovadas de entrega, você terá que buscar o pedido em uma agência dos Correios próxima ao seu endereço em até 7 dias. Taxas de armazenagem são cobradas se não forem retiradas em até 7 dias corridos. O valor da taxa depende do peso/volume do produto. Assim que algum produto for comprado, e já despachado, não é possível ser abortado, ter a rota alterada ou ser retornado para nós enquanto ainda em trânsito.</p>
            </div>
      
            <Foot/>
          </div>
        );
    }
    }


export default InfoEnv;