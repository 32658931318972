import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import HeaderOfertas from '../components/HeaderOfertas';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoClose, IoRocket, IoStar, IoAdd} from 'react-icons/io5'
import { Debug, FetchAce, FetchMochi,  FetchEla, FetchEle, FetchPromo, FetchOfertas, MostAlgo  } from '../SQL';
import ModalProd from '../components/ModalProd';


import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}


  

class CatalogOfertas extends Component {


  state = {
    MochiProds:[],
    categoria:JSON.parse(window.localStorage.getItem("categoria")),
    MiniModal:false,
    n:8,
    ordem:'r'

  }

  componentDidMount = async() => {
    let mochi = await FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))

  }


    render(){

      let MochiList = this.state.MochiProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = (ratings.reduce((a, b) => a + b, 0)/ratings.length).toFixed(1)

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContCat'>
            <div>
              <img className='ProdIMG' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName'  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating)}
              </div>
              {preco}
            </div>
            <div className='ProdBtnDivCat'>
              <button className='ProdBtn' style={{width:'120%'}}  onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'}>
                  <p>Ver produto</p>
                </Link>
              </button>
              <a onClick={()=> {
                MostAlgo(item.id)
              }} href={item.linkAfiliado} className='ProdBtn' style={{width:'120%'}} >
                  <p className='ProdLink' style={{fontSize:12}}>Ir para o site</p>
              </a>
            </div>
          </div>

        )
      })  

      let MochiListMobile = this.state.MochiProds.map((item,index)=>{
        
        let ratings=[];

        Object.values(item.avaliacoes).forEach(i => {
          let nota = i.nota
          ratings.push(nota)
        })

        let rating = ratings.reduce((a, b) => a + b, 0)/ratings.length

        let preco;

                if(item.isDisponivel){

          if(item.isPromo){
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:9}} className='ProdPrecoPromo  ' > {item.preco}</p>
                  <p style={{fontSize:13}} className='ProdPromo' > {item.precoPromo}</p>
                </div>  
            )
          } else {
            preco = (
                <div style={{display:'flex'}}>
                  <p style={{fontSize:13}} className='ProdPreco  ' > {item.preco}</p>
                </div>  
            )
          }
        } else {
          
          preco = (
              <div style={{display:'flex'}}>
                <p style={{fontSize:13}} className='ProdPreco  ' > INDISPONÍVEL</p>
              </div>  
          )
        }


        return (
          <div key={index} className='ProdContMobileCat' style={{marginBottom:20}}>
            <div>
              <img className='ProdIMGMobile' src={URL=item.imgs[0]}/>
              {item.isPromo ? porcentagem(item.precoPromo,item.preco, 0) : ''}
            </div>
            <div style={{marginBottom:25}}>
              <p className='ProdName' style={{fontSize:13}}  >{item.nome}</p>
              <div style={{display:'flex', alignItems:"center", marginTop:5}}>
                {aval(rating,1)}
              </div>
              {preco}
            </div>
            <div className='ProdBtnDivCat' style={{width:'100%'}}>
              <button className='ProdBtn' onClick={()=> {
                window.localStorage.setItem('prodId',JSON.stringify(item.id))
                MostAlgo(item.id)
              }}>
               <Link onClick={()=>window.scrollTo({behavior:'smooth',top:0})} className='ProdLink' style={{textDecoration:"none"}} to={'/Product'}>
                  <p>Ver produto</p>
                </Link>
              </button>
              <a onClick={()=> {
                MostAlgo(item.id)
              }} href={item.linkAfiliado} className='ProdBtn'>
                  <p className='ProdLink' style={{fontSize:12}}>Ir para o site</p>
              </a>
            </div>
          </div>

        )
      })  

      let list = MochiList
      let listMobile = MochiListMobile

      let miniModal = this.state.MiniModal ? (
        <div className="MiniModal">
          <div style={{display:'flex', justifyContent:'flex-end'}}>
            <button onClick={()=>{this.setState({MiniModal:false})}} style={{backgroundColor:"transparent", borderStyle:'none', cursor:'pointer'}}>
              <IoClose color='gray' size={25}/>
            </button>
          </div>
          <ModalProd nome={this.state.ModalItem.nome} variacoes={this.state.ModalItem.variacoes} prod={this.state.ModalItem}/>
        </div>
      ) : ''


        return (
          <div className="App">
            <Desktop>
              {miniModal}
              <HeaderOfertas/>
        
              <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white"}}>
                <div style={{display:'flex', justifyContent:"space-between"}}>
                  <p style={{fontWeight:'bold', color:"#a8c9ef", fontSize:25, textTransform:'uppercase'}}>Quick Ofertas</p>
                  <div style={{display:"flex", justifyContent:'space-between', width:'23%', alignItems:'center'}}>
                    <p onClick={()=>{
                      this.setState({ordem:'r'})
                      setTimeout(() => {
                        
                        FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))
                      }, 500);
                    }} className={this.state.ordem === 'r' ? 'OrdemS' : 'Ordem'}>Mais recentes</p>
                    <p onClick={()=>{
                      this.setState({ordem:'v'})
                      setTimeout(() => {
                        
                        FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))
                      }, 500);
                    }} className={this.state.ordem === 'v' ? 'OrdemS' : 'Ordem'}>Mais vendidos</p>
                    <p onClick={()=>{
                      this.setState({ordem:'a'})
                      setTimeout(() => {
                        
                        FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))
                      }, 500);
                    }} className={this.state.ordem === 'a' ? 'OrdemS' : 'Ordem'}>Aleatório</p>
                  </div>
                </div>
                
                <div style={{display:"flex", flexWrap:'wrap', justifyContent:'space-evenly'}}>      
                  {list}
                </div>

                <button onClick={()=>{
                  this.setState({n:this.state.n+8})
                  setTimeout(() => {
                    FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))
                  }, 1000);
                }} className='LoadMore'>
                  <IoAdd  className='LoadMoreIcon' />
                </button>

              </div>
        
              <Foot/>
            </Desktop>
            <Mobile>
              {miniModal}
              <HeaderOfertas/>
        
              <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white"}}>
              <div style={{display:'flex', justifyContent:"space-between"}}>
                  <div style={{display:"flex", justifyContent:'space-between', width:'100%', alignItems:'center', marginBottom:30}}>
                    <p onClick={()=>{
                      this.setState({ordem:'r'})
                      setTimeout(() => {
                        
                        FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))
                      }, 500);
                    }} style={{fontSize:10}} className={this.state.ordem === 'r' ? 'OrdemS' : 'Ordem'}>Mais recentes</p>
                    <p onClick={()=>{
                      this.setState({ordem:'v'})
                      setTimeout(() => {
                        
                        FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))
                      }, 500);
                    }} style={{fontSize:10}} className={this.state.ordem === 'v' ? 'OrdemS' : 'Ordem'}>Mais vendidos</p>
                    <p onClick={()=>{
                      this.setState({ordem:'a'})
                      setTimeout(() => {
                        
                        FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))
                      }, 500);
                    }} style={{fontSize:10}} className={this.state.ordem === 'a' ? 'OrdemS' : 'Ordem'}>Aleatório</p>
                  </div>
                </div>
                
                <div style={{display:"flex", flexWrap:'wrap', justifyContent:'space-evenly'}}>      
                  {listMobile}
                </div>

                <button onClick={()=>{
                  this.setState({n:this.state.n+8})
                  setTimeout(() => {
                    FetchOfertas(this.state.n, this.state.ordem).then(res => this.setState({MochiProds:res}))
                  }, 1000);
                }} className='LoadMore'>
                  <IoAdd  className='LoadMoreIcon' />
                </button>

              </div>
        
              <Foot/>

            </Mobile>
          </div>
        );
    }
    }


export default CatalogOfertas;