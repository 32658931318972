import logo from './logo.svg';
import './App.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Routes, Route, Link } from "react-router-dom";
import React, { useEffect } from 'react';

import Home from './screens/home';
import Cart from './screens/cart';
import Catalog from './screens/catalog';
import CatalogOfertas from './screens/catalogOfertas';
import Product from './screens/product';
import Checkout from './screens/checkout';
import Pedidos from './screens/pedidos';
import AdminMaster from './screens/adminMaster';
import InfoEnv from './screens/infoEnv';
import InfoTroca from './screens/infoTroca';
import InfoPP from './screens/infoPP';
import InfoTS from './screens/infoTS';
import InfoPR from './screens/infoPR';
import InfoPF from './screens/infoPF';
import InfoFC from './screens/infoFC';
import QuickOfertas from './screens/quickofertas';
import { FetchUser } from './SQL';

function App() {

  useEffect(()=>{
    FetchUser().then(res=>{
      if(JSON.parse(window.localStorage.getItem("userID")) != 'pqoT8LU7rVVSUGny6A9IElN2CGR2') {

        window.localStorage.setItem('userID',JSON.stringify(res))
        console.log(res)
      }
    })

  })
  
  
  return (
      <div className="App">
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/Cart" element={<Cart />} /> */}
          {/* <Route path="/Catalog" element={<Catalog />} /> */}
          <Route path="/CatalogOfertas" element={<CatalogOfertas />} />
          <Route path="/Product" element={<Product />} />
          {/* <Route path="/Checkout" element={<Checkout />} /> */}
          {/* <Route path="/Pedidos" element={<Pedidos />} /> */}
          {/* <Route path="/InfoEnv" element={<InfoEnv />} />
          <Route path="/InfoTroca" element={<InfoTroca />} />
          <Route path="/InfoPP" element={<InfoPP />} />
          <Route path="/InfoTS" element={<InfoTS />} />
          <Route path="/InfoPR" element={<InfoPR />} />
          <Route path="/InfoPF" element={<InfoPF />} />
          <Route path="/InfoFC" element={<InfoFC />} /> */}
          <Route path="/QuickOfertas" element={<QuickOfertas />} />
          <Route path="/AdminMaster" element={<AdminMaster />} />
        </Routes>
      </div>
  );
}


export default App;
