import '../App.css';
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react'
import Header from '../components/Header';
import Foot from '../components/Foot';
import { Link } from 'react-router-dom';
import {IoChevronForward, IoFilter, IoRefresh, IoRocket, IoStar} from 'react-icons/io5'
import { Debug, FecthPedido, FetchAce, FetchMochi, FetchMost } from '../SQL';

import aval from '../components/Rating';

import parcela from '../components/Parcela';

import porcentagem from '../components/Porcentagem';

  

class InfoPR extends Component {



    render(){

        return (
          <div className="App">
      
            <Header/>

      
            <div style={{width:'80%', marginLeft:'auto', marginRight:'auto', backgroundColor:"white", marginBottom:40}}>
              <p className='IPTitle'> Política de reembolso</p>

              <p className='IPText'>Nossa pol&iacute;tica de trocas dos produtos e reembolso visa proporcionar ao cliente total&nbsp;seguran&ccedil;a em rela&ccedil;&atilde;o aos produtos adquiridos em nossa loja.&nbsp;Todos nossos produtos possuem garantia contra defeitos de fabrica&ccedil;&atilde;o.&nbsp;Tamb&eacute;m realizamos trocas de produtos que vieram diferente daquele&nbsp;encomendado pelo cliente.</p>
<p className='IPText'>Caso voc&ecirc; receba um produto nosso com algum defeito de fabrica&ccedil;&atilde;o,&nbsp;diferente do que voc&ecirc; encomendou ou desist&ecirc;ncia siga os seguintes passos para&nbsp;realizar a troca:<br/><br/>1. Informe nossa equipe sobre o problema atrav&eacute;s de nosso e-mail&nbsp;<strong>(contato@quickshopbr.com.br)</strong>, enviando fotos claras do produto com defeito ou do produto errado&nbsp;que voc&ecirc; recebeu, no prazo de 7 dias ap&oacute;s o recebimento.<br/>Aten&ccedil;&atilde;o, n&atilde;o realizaremos trocas ou ressarcimentos ap&oacute;s este&nbsp;prazo.</p>
<p className='IPText'><br/>2. Sua reclama&ccedil;&atilde;o ser&aacute; analisada em no m&aacute;ximo e&nbsp;constatado o problema, entraremos em contato via e-mail e <strong>voc&ecirc;&nbsp;</strong><strong>poder&aacute; escolher uma das seguintes alternativas:</strong></p>
<p className='IPText'><br/>&bull; Receber o reembolso total do valor pago.<br/>&bull; Receber um novo produto igual o encomendado.<br/>&bull; Receber um produto de valor semelhante.<br/>&bull; Receber um cupom com o valor do produto para utilizar em&nbsp;compras futuras.<br/><br/>Todos os itens dos termos de reembolso e devolu&ccedil;&atilde;o abaixo devem ser considerados.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'><strong>Reembolsos e devolu&ccedil;&otilde;es</strong></p>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>7.1. A Empresa fornece detalhadamente todas as especifica&ccedil;&otilde;es (informa&ccedil;&otilde;es) sobre os tamanhos, cores e demais caracter&iacute;sticas dos produtos.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.2. O visitante somente poder&aacute; pedir reembolso ou desistir da compra (direito de arrependimento) at&eacute; o prazo de 7 (sete) dias a partir da data do recebimento do produto (que ser&aacute; conferido por meio da data de envio aos correios), ultrapassado este per&iacute;odo a EMPRESA n&atilde;o far&aacute; qualquer esp&eacute;cie de reembolso.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.3. O pedido dever&aacute; ser realizado por meio de qualquer canal da central de atendimento da Empresa, seja e-mail telefone ou outro mecanismo de comunica&ccedil;&atilde;o v&aacute;lido com exce&ccedil;&atilde;o das redes sociais da Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.4. O produto deve estar no estado de conserva&ccedil;&atilde;o original em que foi enviado e fechado. A Empresa devolver&aacute; o valor correspondente ao pre&ccedil;o de aquisi&ccedil;&atilde;o.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.5. Na hip&oacute;tese de devolu&ccedil;&atilde;o de algum produto, os custos do transporte ser&atilde;o suportados pela Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.6. Em caso de reembolso, o visitante dever&aacute; enviar o produto por meio do material adequado indicado pela Empresa durante a comunica&ccedil;&atilde;o, a exemplo mas n&atilde;o se limitando a pl&aacute;stico bolha ou demais materiais.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.7. A autenticidade dos produtos comercializados &eacute; controlada pela Empresa por meio do nosso controle de qualidade, o qual ser&aacute; verificado na ocasi&atilde;o da devolu&ccedil;&atilde;o a fim de garantir que o produto retornado &eacute; o mesmo e com as mesmas condi&ccedil;&otilde;es enviadas pela Empresa.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.8 A Empresa informa que disp&otilde;e de 15 (quinze) dias &uacute;teis para cadastrar a devolu&ccedil;&atilde;o dos produtos, por meio do formul&aacute;rio a ser preenchido pelo visitante e processar o devido reembolso.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.9. O reembolso somente ser&aacute; realizado ap&oacute;s o produto retornar a sede da Empresa e ser submetido a nova avalia&ccedil;&atilde;o.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.10. Caso seja constatado que o produto retornado n&atilde;o condiz com as especifica&ccedil;&otilde;es t&eacute;cnicas e estado de conserva&ccedil;&atilde;o que saiu da EMPRESA, fica esta &uacute;ltima desobrigada de qualquer tipo/esp&eacute;cie de reembolso.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.11 Caso aconte&ccedil;a o previsto no item 7.10 supramencionado o produto ficar&aacute; disponibilizado na sede da EMPRESA para que o visitante o retire, desde que todos os custos referentes ao transporte sejam arcados por ele.</p>
<p className='IPText'>&nbsp;</p>
<ol>
    <li><strong>Troca</strong></li>
</ol>
<p className='IPText'><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>8.1. A Empresa fornece detalhadamente todas as especifica&ccedil;&otilde;es (informa&ccedil;&otilde;es) sobre os tamanhos, cores e demais caracter&iacute;sticas dos produtos.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.2. Para que seja realizada a troca &eacute; necess&aacute;rio que o visitante envie &agrave; Empresa imagem e, ou, v&iacute;deo mostrando o defeito do produto.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.3. Caso o visitante venha a produzir v&iacute;deo e, ou, imagem adulterado e seja constatado quando do recebimento do produto na sede da Empresa que o mesmo se apresenta em perfeitas condi&ccedil;&otilde;es de estado e uso, a Empresa n&atilde;o realizar&aacute; o retorno do mesmo ao visitante, sendo de responsabilidade deste o custo com o envio do produto.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.4. Caso o visitante queria realizar a troca do produto e nem realizar o reembolso poder&aacute; reverte o valor do cr&eacute;dito em vale-compra, conforme elencado no item 8.5.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.5. Caso o visitante n&atilde;o queira trocar o produto por outro modelo, poder&aacute; reverter o pagamento em vale-compra, no qual poder&aacute; em outra oportunidade efetuar compra.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.6. O direito de troca somente poder&aacute; ser exercido dentro do prazo de 7 (sete) dias ap&oacute;s o recebimento do produto na resid&ecirc;ncia do visitante.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.7. Em caso do visitante solicitar a contesta&ccedil;&atilde;o da compra junto ao cart&atilde;o de cr&eacute;dito, dever&aacute;, obrigatoriamente comunicar a Empresa o motivo de tal ato, sob pena de responder extra e judicialmente por perdas e danos e lucros cessantes al&eacute;m de honor&aacute;rios advocat&iacute;cios.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.8. Caso o visitante n&atilde;o queira nenhuma das hip&oacute;teses de troca acima transcritas, poder&aacute; receber o estorno desde que envie o produto &agrave; Empresa e, ap&oacute;s, o produto ser submetido a avalia&ccedil;&atilde;o nos moldes do item 7 e subitens do presente termo.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.9. Ressalta-se que o per&iacute;odo de troca corresponde aquele descrito no item 7.2 do presente instrumento.</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.10. O prazo para envio do produto trocado &eacute; aquele estabelecido no item 4 do presente termo de uso a contar da avalia&ccedil;&atilde;o feita quando do retorno do produto a sede da Empresa.</p>
<p className='IPText'>&nbsp;</p>
<p className='IPText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; E assim, por estarem justas e acertadas, as partes (Visitante e Empresa) reconhecem a validade do presente termos de uso, nada podendo reclamar seja extra ou judicialmente a qualquer t&iacute;tulo. Reconhecendo como foro a comarca de S&atilde;o Paulo, Estado de S&atilde;o Paulo para ser dirimido qualquer conflito judicial.</p>


            </div>
      
            <Foot/>
          </div>
        );
    }
    }


export default InfoPR;